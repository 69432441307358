import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {get, post} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    detail: [],
    detailLoading: false,
    detailError: ''
}

export const fetchCareer = createAsyncThunk("fetchCareer", (params) => {
    return get(params);
});

export const fetchCareerDetail = createAsyncThunk("fetchCareerDetail", (params) => {
    return get(params);
});

export const postCareerForm = createAsyncThunk("postCareerForm", (params) => {
    return post(params);
});


const postSlice = createSlice({
    name: 'career',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchCareer.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchCareer.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchCareer.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(fetchCareerDetail.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
        builder.addCase(fetchCareerDetail.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload
            state.detailError = ''
        })
        builder.addCase(fetchCareerDetail.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })

        //-- Media
        builder.addCase(postCareerForm.pending, (state) => {
            state.loading = true;
            state.success = [];
            state.error = "";
        });
        builder.addCase(postCareerForm.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload.message;
            state.error = "";

        });
        builder.addCase(postCareerForm.rejected, (state, action) => {
            state.loading = false;
            state.success = '';
            state.error = action.error;

        });
    }
})


export default postSlice.reducer
