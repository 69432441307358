import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Col, Container, Row} from "react-bootstrap";
import Button from "../components/Button";
import Title from "../components/Title";
import SubTitle from "../components/SubTitle";

const MyComponent = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Title</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <Container className={'pb-150 pt-150'}>
                    <Row>
                        <Col md={6}>
                            <h1>This is H1</h1>
                            <h2>This is H2</h2>
                            <h3>This is H3</h3>
                            <h4>This is H4</h4>
                            <h5>This is H5</h5>
                            <p>This is P tag</p>
                        </Col>
                        <Col md={6}>
                           <Button src={'/'} text={'Read More'}/>
                            <Title text={' This is title text'}/>
                            <SubTitle text={'This is subtitle'} borderColor={'#E9E9E9'} color={'#E9E9E9'}/>
                        </Col>
                    </Row>
                </Container>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

    h1, h2, h3, h4, h5, h6{
      padding-bottom: 20px;
      color: #18153A;
    }
  ul{
    display: flex;
    li{
      overflow: hidden;
    }
  }
`;

export default MyComponent;
