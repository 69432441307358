import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import TruncateText from "../TruncateText";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const paragraphText = "Aluminium is the primary raw material used in our production. From thin printed or lacquered aluminium for chocolate pralines starting at 9 µm thickness, through blister foils, lidding foils, foils for deep drawing into coffee capsules and containers, all the way to 200µm thick foil for pharma caps, we are proud of our sustainable and recyclable solutions used in all the industries in which we have a presence. \n " +
        "</br> </br>" +
        "Working further on minimising our environmental impact in manufacturing products based on aluminium, we choose our suppliers and partners carefully and act in accordance with worldwide sustainability standards. We are developing aluminium packaging solutions with a high recycled content, with the goal of increasing the recycled content share.                "

    return (
        <StyledComponent className={'pb-150 pt-150'}>
            <Container>
                <Row>
                    <Col md={12}>
                        <h3>{reactHtmlParser(data?.title)}</h3>
                        <TruncateText longText={reactHtmlParser(data?.description)} maxLines={3}/>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F9F9F9;
  h3{
    color: #262630;
    margin-bottom: 40px;
    width: 85%;
  }

  p{
    width: 85%;
  }

  .counters{
    display: flex;
    &__single{
      min-width: 270px;
      margin-right: 65px;
      h5{
        font-family: ${button};
        font-size: 48px !important;
        line-height: 62px;
        font-weight: 300;
        color: rgb(38 38 48 / 70%);
        margin-bottom: 20px;
        border-bottom: 1px solid rgb(121 121 151 / 60%);
      }
      p{
        color: rgb(0 0 0 / 60%);
        font-family: ${button};
        font-size: 16px !important;
        line-height: 24px;
        font-weight: 400;
      }
    }
    @media(max-width: 767px){
      display: block;
      &__single{
        margin-bottom: 65px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default MyComponent;
