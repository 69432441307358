import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import SubTitle from "../SubTitle";
import {Link} from "react-router-dom";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'pb-150'}>
            <Container>
                <SubTitle text={data?.subtitle} color={'rgb(24 21 58 / 80%)'} borderColor={'rgb(24 21 58 / 80%)'}/>
                <h3>{data?.title}</h3>
                <Row className={'box'}>
                    {
                        data?.list && data?.list?.length > 0 &&
                        data?.list?.map((e,i)=>{
                            return(
                                <Col sm={3} className={'box__single'} key={i}>
                                    <div className={'box__single__wrap'}>
                                        <Link to={e?.post_name}>
                                            <div className={'content'}>
                                                <h4>{e?.post_title}</h4>
                                                <svg id="Component_112_2" data-name="Component 112 – 2" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20" transform="translate(0 0)" fill="#fff"/>
                                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.333" cy="1.333" r="1.333" transform="translate(18.667 18.667)" fill="#f9f9f9" opacity="0"/>
                                                    <g id="Group_20" data-name="Group 20" transform="translate(11.262 23.52) rotate(-45)">
                                                        <line id="Line_11" data-name="Line 11" x2="16.512" transform="translate(0 3.691)" fill="none" stroke="#180e81" stroke-linecap="round" stroke-width="1"/>
                                                        <path id="Path_7" data-name="Path 7" d="M0,0,3.794,3.77,0,7.381" transform="translate(13.543)" fill="none" stroke="#180e81" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 120px;
  h3{
    color: #262630;
  }
  .box{
    margin-top: 40px;
    &__single{
      &__wrap{
        position: relative;
        padding-top: calc(230 / 270 * 100%);
        background-color: #180E81;
        .content{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          h4{
            position: absolute;
            top: 45px;
            left: 30px;
            color: #FFFFFF;
            width: 75%;
          }
          svg{
            position: absolute;
            bottom: 45px;
            right: 30px;
            #Group_20{
              transition: 0.2s all ease-in-out;
            }
          }
        }
        &:hover{
          svg{
            #Group_20{
              transform: translate(11px, 17px) !important;
              transition: 0.2s all ease-in-out;
            }
          }
        }
      }
      @media(max-width: 767px){
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0;
        }
        &__wrap{
          padding-top: calc(215 / 345 * 100%);
        }
      }
    }
  }
`;

export default MyComponent;
