import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Bod from "../../components/management/Bod";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";

import managementReplicaReducer, {fetchManagementReplica} from "../../api/redux/ManagementReplica";
import managementPackagingReducer, {fetchManagementPackaging} from "../../api/redux/ManagementPackaging";



const MyComponent = () => {


    const location = useLocation()


    const dispatch = useDispatch()
    const url = location.pathname.split('/')[1];
    let getPost;

    useEffect(() => {
        let api_url;

        if (url === 'kalyar-replica') {
            api_url = apiEndPoints.MANAGEMENT_REPLICA;
            dispatch(fetchManagementReplica([api_url]));
        } else {
            api_url = apiEndPoints.MANAGEMENT_PACKAGING;
            dispatch(fetchManagementPackaging([api_url]));
        }
    }, [dispatch, url]);

    getPost = useSelector(state => {
        if (url === 'kalyar-replica') {
            return state.managementReplicaReducer;
        } else {
            return state.managementPackagingReducer;
        }
    });

    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.innerBanner;
    let management_boards = data?.management_boards;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title + ' | ' + data?.innerBanner?.subtitle : ''}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>

                    <InnerBanner
                        img={innerBanner?.image}
                        title={innerBanner?.title}
                        subtitle={innerBanner?.subtitle}
                    />

                    <Bod data={management_boards}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
