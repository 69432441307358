import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Overview from "../../components/about/Overview";
import InnerBanner from "../../components/InnerBanner";
import MissionVision from "../../components/about/MissionVision";
import WhyChoose from "../../components/about/WhyChoose";
import ClientSlider from "../../components/about/ClientSlider";
import Accolades from "../../components/about/Accolades";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchHome} from "../../api/redux/Home";
import aboutReplica, {fetchAboutReplica} from "../../api/redux/AboutReplica";
import aboutPackaging, {fetchAboutpackaging} from "../../api/redux/AboutPackaging";
import {motion} from "framer-motion"
import {PageAnimation} from "../../components/PageAnimation";



const MyComponent = () => {

    const location = useLocation()


    const dispatch = useDispatch()
    const url = location.pathname.split('/')[1];
    let getPost;

    useEffect(() => {
        let api_url;

        if (url === 'kalyar-replica') {
            api_url = apiEndPoints.ABOUT_REPLICA;
            dispatch(fetchAboutReplica([api_url]));
        } else {
            api_url = apiEndPoints.ABOUT_PACKAGING;
            dispatch(fetchAboutpackaging([api_url]));
        }
    }, [dispatch, url]);

    getPost = useSelector(state => {
        if (url === 'kalyar-replica') {
            return state.aboutReplica;
        } else {
            return state.aboutPackaging;
        }
    });

    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.innerBanner;
    let overview = data?.overview;
    let mission = data?.mission;
    let vision = data?.vision;
    let why_us = data?.why_us;
    let issues = data?.issues;
    let valued_clients = data?.valued_clients;
    let accolades = data?.accolades;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title + ' | ' + data?.innerBanner?.subtitle : 'Kalyar Replica Limited'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>

                    <InnerBanner
                        img={innerBanner?.image}
                        title={innerBanner?.title}
                        subtitle={innerBanner?.subtitle}
                    />
                    <Overview data={overview}/>
                    <MissionVision mission={mission} vision={vision}/>
                    <WhyChoose data={why_us} issues={issues}/>
                    <ClientSlider data={valued_clients}/>
                    <Accolades data={accolades}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
