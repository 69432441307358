import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {ImageParallax} from "./ImageParallax";
import Title from "./Title";
import {Col, Container, Row} from "react-bootstrap";
import Button from "./Button";


const Cta = ({data}) => {



    return (

        <StyledCTA>
            <div className="image-wrapper">
                <ImageParallax dataSpeed={'0.8'} src={data?.image}/>
                <Container>
                    <Row>
                        <Col md={5}>
                            { data && <Title classname={'split-up'} data-lag={0.1} color={'#FFFFFF'} margin={'0 0 40px'}
                                    text={data?.title}/>}
                            {
                                data?.show_investor &&
                                <Button data-lag={0.1}  color={'#F9F9F9'} src={'/investor'} borderColor={'#FFFFFF'} hoverBackground={'#ffffff'} hoverColor={'#180E81'}
                                        text={'Invest today'}/>
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledCTA>

    )
};


const StyledCTA = styled.div`
  position: relative;
  overflow: hidden;

  .image-wrapper {
    padding-top: 200px;
    padding-bottom: 205px;
    //padding-top: calc(606 / 1366 * 100%);
    position: relative;

    @media(min-width: 1920px){
      padding-top: 250px;
      padding-bottom: 250px;
    }
  }


  @media (max-width: 767px) {
    .image-wrapper {
      padding-top: 191px;
      padding-bottom: 191px;
    }

  }
`;

export default React.memo(Cta);














