import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Select, {components} from "react-select";
import {button} from "../../styles/globalStyleVars";
import {Link} from "react-router-dom";
import {Img} from "../Img";

const MyComponent = ({data}) => {

    // dropdown style
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : '#262630',
            backgroundColor: state.isSelected ? '#180E81' : '#ffffff',
            margin: 0,
            cursor: 'pointer',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            fontfamily: `${button}`,
            "&:hover": {
                backgroundColor: `#ffffff`,
                color: '#25292C',
                cursor: 'pointer'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,
            zIndex: 9999,
        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer',
            zIndex: 9999,
        }),

    };
// options
    const optionsOne = [
        {value: 'homogenous-glazy-parking-tiles', label: 'Homogenous Glazy Parking Tiles '},
        {value: 'trihexagonal-pavers', label: 'Trihexagonal Pavers '},
        {value: 'ceiling-blocks', label: 'Ceiling Blocks'},
        {value: 'kerbstones', label: 'Kerbstones'},
    ];

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.732" height="7.003"
                             viewBox="0 0 11.732 7.003">
                            <g id="Group_24047" data-name="Group 24047" transform="translate(18853.867 -10435.998)">
                                <g id="Group_24046" data-name="Group 24046"
                                   transform="translate(-18852.863 10442.258) rotate(-93)">
                                    <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none"
                                          stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_12381" data-name="Line 12381" y1="5" x2="5"
                                          transform="translate(0 5)" fill="none" stroke="#08082b" stroke-linecap="round"
                                          stroke-width="1"/>
                                </g>
                            </g>
                        </svg>

                        :

                        <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
                             viewBox="0 0 11.414 6.414">
                            <g id="Group_22451" data-name="Group 22451" transform="translate(10.707 0.707) rotate(90)">
                                <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none" stroke="#08082b"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_12381" data-name="Line 12381" y1="5" x2="5" transform="translate(0 5)"
                                      fill="none" stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                    }

                </components.DropdownIndicator>
            )
        );
    };
    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                {/*<h3>Products Category</h3>*/}
               {/*<Row className={'filters'}>*/}
               {/*    <Col md={3} className={'filters__single'}>*/}
               {/*        <Select components={{DropdownIndicator}}*/}
               {/*                styles={customStyles}*/}
               {/*                classNamePrefix={'custom'} className='select-here files'*/}
               {/*                placeholder={'All Products'}*/}
               {/*                options={optionsOne}/>*/}
               {/*    </Col>*/}
               {/*    <Col md={3} className={'filters__single'}>*/}
               {/*        <Select components={{DropdownIndicator}}*/}
               {/*                styles={customStyles}*/}
               {/*                classNamePrefix={'custom'} className='select-here files'*/}
               {/*                placeholder={'All Products'}*/}
               {/*                options={optionsOne}/>*/}
               {/*    </Col>*/}
               {/*</Row>*/}
                <Row className={'products'}>
                    {
                        data?.length &&
                        data?.map((e,i)=>{
                            return(
                                <Col md={4} className={'products__single'} key={i}>
                                    <div className={'products__single__wrapper'}>
                                        <Img src={e?.thumbnails} alt={''}/>
                                    </div>
                                    <p>{e?.post_title}</p>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    h3{
      color: #180E81;
      padding-bottom: 20px;
      border-bottom: 1px solid rgb(38 38 48 / 20%);
    }

  .filters{
    margin-top: 40px;
    margin-bottom: 80px;
    &__single{
      margin-right: 40px;
      &:last-child{
        margin-right: 0;
      }
    }
    @media(max-width: 767px){
      &__single{
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
  //caret
  .custom__control {
    background-color: transparent;
    border: 1px solid #262630 !important;
    border-radius: 5px;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    //margin-bottom: 60px
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: ${button};
    padding: 12px 20px;

    .custom__placeholder, .custom__single-value {
      color: #262630;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-family: ${button} !important;
    }

    .custom__value-container {
      padding-left: 0;
    }
    .custom__dropdown-indicator{
      padding: 0;
    }
    
    &--is-focused {

    }
  }

  
  .products{
    &__single{
      margin-bottom: 80px;
      &:nth-last-child(3){
        margin-bottom: 0;
      }
      &:nth-last-child(2){
        margin-bottom: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &__wrapper{
        position: relative;
        padding-top: calc(370 / 370 * 100%);
      }
      p{
        font-size: 18px !important;
        line-height: 24px !important;
        font-weight: 500;
        font-family: ${button};
        color: #262630!important;
        text-align: center;
        margin-top: 20px;
        border-bottom: 1px solid rgb(121 121 151 / 30%);
        padding-bottom: 20px;
      }
    }
  }
`;

export default MyComponent;
