import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {BsPlus} from 'react-icons/bs';
import {Link} from "react-router-dom";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import {gsap} from "gsap";
import {button} from "../../styles/globalStyleVars";


const AboutSection = ({countDown, hoverBox, bg, pb, mission, data, hoverData, missionVision, textSection, Logo}) => {
    const [winWidth, setWinWidth] = useState(true)

    useEffect(() => {
        if (window.innerWidth > 800) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 800) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])

    useEffect(() => {
        const boxes = document.querySelectorAll('.about-section__bottom__single');
        {
            if(window.innerWidth > 767){
                boxes.forEach((box, index) => {
                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: box,
                            start: 'top center', // Adjust the starting position
                            end: 'bottom center', // Adjust the ending position
                            scrub: true,
                        }
                    });

                    if (index === 1){
                        tl.to(box,{
                            y: -10,
                            duration: 0.2,
                            ease: 'linear',
                        });
                    }
                    if (index === 2){
                        tl.to(box,{
                            y: -20,
                            duration: 0.2,
                            ease: 'linear',
                        });
                    }
                    if (index === 3){
                        tl.to(box,{
                            y: -30,
                            duration: 0.2,
                            ease: 'linear',
                        });
                    }
                });
            }
        }
    }, [winWidth, data]);


    // missing vision overflow
    const [overflow, setOverflow] = useState(0)
    useEffect(() => {
        if (document.querySelector('.mission-vision__single')) {
            setOverflow(document.querySelector('.mission-vision__single').clientHeight)
            window.addEventListener('resize', () => {
                if (window.innerWidth > 900) {
                    setOverflow(document.querySelector('.mission-vision__single').clientHeight)
                }
            })
        }

    })



    return (
        <StyledAboutSection className={`about-section pt-160 ${!pb ? '' : 'pb-160'}`}>

            <Container fluid className='about-section__bottom'>
                <Row>
                    {
                        data?.length > 0 &&
                        data?.map((e,i)=>{
                            return(
                                <Col className='about-section__bottom__single p-0' key={i}>
                                    <div className="about-section__bottom__single__inner">
                                        <Link to={`/layer/${e?.post_name}`}></Link>
                                        <div className="about-section__bottom__single__inner__img">
                                            <Img src={e?.thumb}/>
                                        </div>

                                        <img className='img-top'
                                             src={e?.icon}
                                             alt=""/>
                                        <div className="circle">
                                            <BsPlus/>
                                        </div>
                                        <p>{e?.short_desc}</p>
                                        <h6>{reactHtmlParser(e?.post_title)}</h6>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledAboutSection>
    );
};

const StyledAboutSection = styled.section`
  position: relative;
  z-index: 4;

  

  .about-section__bottom {
    margin-top: -50px;
    //padding-bottom: 50px;

    &__single {
      &__inner {
        position: relative;
        padding-top: calc(350 / 341 * 100%);
        overflow: hidden;
        border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: rgba(0, 0, 0, 0.85);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          transition: .4s ease;
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #006CB7;
            mix-blend-mode: multiply;
            opacity: 0;
            transition: .2s ease;
            transition-delay: .3s;
          }
        }

        p {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 30px;
          color: #F9F9F9;
          font-size: 14px;
          line-height: 18px;
          font-weight: 300;
          font-family: ${button};
          transform: translateY(-100%);
          transition: all .5s ease;
          opacity: 0;
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s ease;
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s ease;

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s ease;
          }
        }

        h6 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;
          font-family: ${button};
          color: #F9F9F9;
          z-index: 2;
        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 0;
          }

          p {
            transform: none;
            opacity: 1;
          }

          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

        }

      }
      &:nth-child(2){
        margin-top: 10px;
      }
      &:nth-child(3){
        margin-top: 20px;
      }
      &:nth-child(4){
        margin-top: 30px;
      }
      @media(max-width: 767px){
        &:nth-child(2){
          margin-top: 0px;
        }
        &:nth-child(3){
          margin-top: 0px;
        }
        &:nth-child(4){
          margin-top: 0px;
        }
      }
    }
  }

  .mission-vision {
    background-color: #221F1F;
    position: relative;

    .container {
      position: relative;
    }

    .row {
      //margin: -320px auto 0 auto;
      position: absolute;
      left: 0;
      right: 0;
    }

    &__single {
      padding: 43px 40px;
      background-color: #E1E4E6;
      min-height: 100%;

      h4 {
        font-size: 26px;
        font-weight: bold;
        line-height: 32px;
        margin-bottom: 35px;
      }

      p {
        font-size: 16px;
        line-height: 22px;
      }


    }

    .col-sm-4 {
      &:nth-of-type(1) {
        .mission-vision__single {
          background-color: #006CB7;

          h4, p {
            color: #ffffff;
          }
        }

      }
    }
  }

  //
  .texts-section {
    background-color: #221F1F;
    position: relative;
    padding-bottom: 100px;

    h2 {
      font-size: 32px;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 90px;

      span {
        color: inherit;
      }
    }

    &__repeat-text {
      .col-sm-3 {
        margin-bottom: 60px;
      }

      h4 {
        font-size: 26px;
        font-weight: bold;
        color: #ffffff;
        line-height: 32px;
        margin-bottom: 26px;
      }

      p {
        font-size: 16px;
        color: #ffffff;
      }
    }

  }


  @media (min-width: 951px) and (max-width: 1200px) {
    .about-section__bottom__single__inner {
      h4 {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }

  @media (max-width: 950px) {
    .about-section__bottom__single {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .texts-section__repeat-text {
      .col-sm-3 {
        min-width: 33.3333%;
      }
    }
  }

  @media (max-width: 900px) {
    .global-image img {
      height: 100% !important;
      bottom: 0 !important;
    }

    .mission-vision {
      padding-top: 160px;
      padding-bottom: 160px;
      height: auto !important;

      .row {
        position: relative !important;
        margin-top: -300px !important;
        margin-right: -15px;
        margin-left: -15px;
      }

      .col-sm-4 {
        min-width: 100%;
        margin-bottom: 30px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 768px) {
    overflow: visible;
    .mission-vision {
      margin-top: 250px !important;
      padding-top: 60px;
      padding-bottom: 70px;

      .container {
        overflow: visible;

        .row {
          margin-top: -200px !important;
        }
      }
    }

  }

  @media (max-width: 767px) {
    .about-section {
      &__title {
        margin-bottom: 60px;
      }

      &__content {
        .col-sm-3, .col-sm-8 {
          min-width: 100%;
          margin: 0;
        }

        img {
          margin-bottom: 60px;
          width: 80%;
        }

        &__count-down {
          margin-top: 60px;
        }
      }
    }

    .texts-section__repeat-text {
      .col-sm-3 {
        min-width: 50%;
        margin-bottom: 50px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }

      h4 {
        margin-bottom: 16px;
      }
    }

    .texts-section {
      h2 {
        margin-bottom: 60px;
      }
    }

  }

  @media (max-width: 650px) {
    .about-section__bottom {
      margin-top: 40px;

      &__single {
        flex: 0 0 100%;
        max-width: 100%;

        &__inner {
          border: none;

          h4 {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }

      &.mobile {
        .about-section__bottom__single {
          border-bottom: 1px solid #fff;
        }
      }
    }
  }


  @media (max-width: 550px) {
    .about-section__content__count-down__single {
      min-width: calc(100% - 50px);
    }
  }

  .me {
    //position: relative;
  }
`;
export default AboutSection;