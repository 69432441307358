import React, {useEffect, useState} from 'react';
import {Col, Container, Dropdown, DropdownButton, ListGroup, Row} from "react-bootstrap";
// Internal Component
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import {Img} from "../Img";
import {Link} from "react-router-dom";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";


const ProductSpecification = ({data, router, isIndustry}) => {

    return (
        <StyledProductSpecification className={`ProductSpecWrapper pt-150`}>
            <Container>
                <Row>
                    <VisibilitySensor>
                        {({isVisible}) =>
                            <div className={` fade-up product-specification-body`}>
                                <div className="ImageTab"><h3>{data?.title}</h3>
                                    <div className="pit-shop">
                                        <div className="pit-shop__row row">
                                            {
                                                data?.list && data?.list?.length > 0 &&
                                                data?.list?.map((e,i)=>{

                                                    // Parse the input date string
                                                    const dateObject = new Date(e?.date);

                                                    // Get day, month, and year
                                                    const day = dateObject.getDate();
                                                    const month = dateObject.toLocaleString('default', { month: 'long' });
                                                    const year = dateObject.getFullYear();

                                                    // Add the appropriate suffix to the day (e.g., 1st, 2nd, 3rd, 4th, etc.)
                                                    const dayWithSuffix = day + (
                                                        (day >= 11 && day <= 13) ? 'th' :
                                                            (day % 10 === 1) ? 'st' :
                                                                (day % 10 === 2) ? 'nd' :
                                                                    (day % 10 === 3) ? 'rd' : 'th'
                                                    );

                                                    // Assemble the formatted date string
                                                    const formattedDate = `${dayWithSuffix} ${month} ${year}`;

                                                    return(
                                                        <Col sm={3}>
                                                            <div className="pit-shop__single" key={i}>
                                                                <div className="pit-shop__single__img">
                                                                    <div className="pit-shop__single__img__inner">
                                                                        <Img src={e?.thumbnail}/>
                                                                    </div>
                                                                </div>
                                                                <div className="pit-shop__single__content"><p>{formattedDate}</p>
                                                                    <h6>{reactHtmlParser(e?.title)}</h6>
                                                                    <div className="download-icon hover">
                                                                        <a target="_blank"
                                                                              href={e?.file}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="16.5" viewBox="0 0 11.414 16.5">
                                                                                <g id="Group_13685" data-name="Group 13685" transform="translate(-407.293 -2241.5)">
                                                                                    <line id="Line_10" data-name="Line 10" x2="10" transform="translate(408 2257.5)" fill="none" stroke="#e1e4e6" stroke-linecap="round" stroke-width="1"/>
                                                                                    <g id="Group_13682" data-name="Group 13682" transform="translate(1)">
                                                                                        <line id="Line_3" data-name="Line 3" x2="10" transform="translate(412 2252) rotate(-90)" fill="none" stroke="#e1e4e6" stroke-linecap="round" stroke-width="1"/>
                                                                                        <line id="Line_3580" data-name="Line 3580" x2="5" y2="5" transform="translate(407 2247.5)" fill="none" stroke="#e1e4e6" stroke-linecap="round" stroke-width="1"/>
                                                                                        <line id="Line_3581" data-name="Line 3581" x1="5" y2="5" transform="translate(412 2247.5)" fill="none" stroke="#e1e4e6" stroke-linecap="round" stroke-width="1"/>
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </VisibilitySensor>
                </Row>
            </Container>
        </StyledProductSpecification>
    );
};

const StyledProductSpecification = styled.section`
  .product-specification-head {
    .product-specification-ul {
      border-bottom: 1px solid #221F1F;
      border-radius: 0;

      .list-group-item {
        padding: 0;
        border: 0;
        background-color: transparent;
        //border-bottom: 1px solid #221F1F;
        border-radius: 0;


        &:not(&:last-child) {
          border-right: 1px solid #221F1F;
        }

        &.active {
          background-color: #221F1F;

          a, span {
            color: #FFFFFF;
          }
        }

        a {
          font-size: 16px;
          line-height: 22px;
          font-weight: bold;
          padding: 23px 35px 20px 40px;
          display: flex;
          color: #221F1F;
          height: 100%;
          position: relative;

          &:before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            height: 0;
            background-color: #221F1F;
            width: 100%;
            transition: height .2s ease-in;
          }

          &:hover {
            span {
              z-index: 1;
              color: #ffffff
            }

            &:before {
              height: 100%;
              z-index: 1;
            }
          }
        }
      }

      &__mobile {
        display: none;
      }
    }
  }

  .product-specification-body {
    width: 100%;
    margin-right: 0 !important;

    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: #221F1F;
    }

    &__desc {
      padding-bottom: 25px;

      p {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        color: #221F1F;
      }

      ul {
        margin-top: 40px;
        margin-bottom: 60px;

        li {
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
          position: relative;
          padding: 20px 0 20px 30px;
          display: flex;
          align-items: center;

          &:not(:last-child) {
            border-bottom: 1px solid #7F7F7F;
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ED1B34;
          }
        }
      }
    }

    table {
      thead {
        th {
          border-width: 1px;
          border-top: 0;
          border-color: #7F7F7F;
          font-size: 12px;
          line-height: 18px;
          color: rgba(34, 31, 31, 0.3);
          font-weight: 600;
          padding-bottom: 20px;
        }
      }

      tbody {
        td, p {
          padding-top: 20px;
          padding-bottom: 20px;
          border-color: #7F7F7F;
          font-size: 16px;
          line-height: 22px;
          font-weight: 600;
          color: rgba(34, 31, 31, 1);
        }
      }
    }

  }


  .hover {
    position: relative;
    overflow: hidden;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #ffffff;
      transition: all .5s ease;
      border-radius: 50%;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
      svg{
        g{
          line{
            stroke: #180E81 !important;
          }
        }
      }
    }
  }


  .report-tab {
    iframe {
      border: 0;
      min-height: 550px;
    }

    .report-tab__single {
      background-color: rgb(225, 228, 230);
      padding: 30px;
      position: relative;
      height: 100%;
      min-height: 98px;

      h4 {
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        color: rgba(34, 31, 31, 0.5);
        width: calc(100% - 80px);
      }

      p {
        font-size: 12px;
        font-weight: bold;
        line-height: 18px;
        width: calc(100% - 80px);
      }

      .download-icon {
        right: 30px;
        position: absolute;
        height: 50px;
        width: 50px;
        background-color: rgb(34, 31, 31);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 50%;
        top: 22px;
        cursor: pointer;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          z-index: 3;

          &:after {
            content: "";
            position: absolute;
            height: 0px;
            width: 0px;
            inset: 0px;
            margin: auto;
            background-color: #ffffff;
            transition: all 0.5s ease 0s;
            border-radius: 19px;
          }
        }
      }
    }
  }


  .ImageTab {
    h3 {
      color: #180E81;
    }

    .pit-shop {
      width: 100%;
      padding-top: 45px;

      .pit-shop__single {
        margin-bottom: 40px;

        &__img {
          padding: 10px;
          background-color: #E1E4E6;

          &__inner {
            padding-top: calc(370 / 250 * 100%);
            position: relative;
          }

        }

        &__content {
          padding: 30px 0px 0 0px;
          position: relative;

          p {
            font-size: 14px;
            color: rgba(38, 38, 48, 0.50);
            line-height: 18px;
            margin-bottom: 3px;
            font-weight: 300;
            width: calc(100% - 80px);
          }

          h3 {
            font-size: 12px;
            font-weight: bold;
            line-height: 18px;
            width: calc(100% - 80px);
          }

          h6 {
            font-family: ${button};
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: #262630;
          }

        }
      }

      @media (max-width: 767px) {
        .pit-shop__row {
          padding: 0 7.5px;
        }

        .col-sm-3 {
          min-width: 50%;
          max-width: 50%;
          padding: 0 7.5px;
        }

        .pit-shop__single {
          margin-bottom: 60px;
        }

        .pit-shop__single__content .download-icon {
          position: relative;
          top: auto;
          bottom: auto;
          margin-top: 20px;
        }
      }
    }

    @media (max-width: 767px) {
      padding-top: 0;

    }
  }


  .download-icon {
    position: absolute;
    right: 0;
    height: 50px;
    width: 50px;
    background-color: #180E81;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 22px;
    cursor: pointer;

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;

      img {
        //z-index: 2;
      }
    }

  }

  @media (max-width: 1280px) {

  }
  @media (max-width: 1170px) {
    .product-specification-head {
      .product-specification-ul {
        .list-group-item {
          a {
            font-size: 16px;
            padding: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .product-specification-head {
      .product-specification-ul {
        .list-group-item {
          a {
            font-size: 14px;
            padding: 20px 15px;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .product-specification-head {
      .product-specification-ul {
        .list-group-item {
          a {
            font-size: 13px;
            padding: 20px 10px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding-top: 80px;
    //padding-bottom: 80px;
    .product-specification-head {
      .product-specification-ul {
        display: none;

        &__mobile {
          display: block;

          button {
            width: 100%;
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 30px;
            border-radius: 23px;
            background-color: #ED1B34;
            border: 0;
            position: relative;

            &:active {
              background-color: #ED1B34;
              border: 0;
            }


            //&:before {
            //  position: absolute;
            //  right: 30px;
            //  top: 18px;
            //  content: '';
            //  border: solid #ffffff;
            //  border-width: 0 2px 2px 0;
            //  display: inline-block;
            //  padding: 3px;
            //  transform: rotate(-135deg);
            //  margin-top: -5px;
            //}
            //
            //&:after {
            //  position: absolute;
            //  right: 30px;
            //  top: 25px;
            //  content: '';
            //  border: solid #ffffff;
            //  border-width: 0 2px 2px 0;
            //  padding: 3px;
            //  transform: rotate(45deg);
            //  margin-top: -5px;
            //  display: none;
            //}
            &:after {
              display: none;
            }

            &:before {
              content: '';
              background-image: url('/images/dynamic/drop.svg');
              position: absolute;
              right: 25px;
              height: 21px;
              width: 17px;
              background-repeat: no-repeat;
            }
          }

          &-menu {
            width: 100%;
          }

          .dropdown-item {
            &.active {
              background-color: #ED1B34;

              a {
                color: #ffffff;
              }
            }

            &:active {
              background-color: transparent;
            }

            a {
              width: 100%;
              display: block;
            }
          }

          .dropdown-menu {
            width: 100%;
          }
        }
      }
    }

    .product-specification-body {
      padding-top: 50px;
      width: 100%;
      min-height: 250px;
      padding-right: 15px;
      padding-left: 15px;

      &__desc {
        padding-bottom: 0;
      }

      table {
        min-width: 800px;
        margin-bottom: 0;
      }
    }

    .report-tab {
      .col-sm-4 {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default ProductSpecification;