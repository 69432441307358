import React, {useState} from 'react';
import styled, {css} from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import SubTitle from "../SubTitle";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {useLocation} from "react-router-dom";

const MyComponent = ({data}) => {
    const location = useLocation();
    const url = location.pathname.split('/')[1];

    const [activeTab, setActiveTab] = useState(1);
    const [selectedTab, setSelectedTab] = useState('null')
    const handleTabClick = (index) => {
        setActiveTab(index);
        setSelectedTab(data?.working_process_all[index - 1]);
    };

    const gradientColors =
        'linear-gradient(45deg, rgb(223, 223, 223), rgb(214, 213, 213), rgb(189, 187, 187), rgb(164, 164, 164), rgb(136 136 136), rgb(82 82 82))';

    const gradientColorsPack =
        'linear-gradient(45deg, rgb(223, 223, 223), rgb(214, 213, 213), rgb(189, 187, 187), rgb(164, 164, 164), rgb(136 136 136), rgb(82 82 82))';

    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <SubTitle text={data?.subtitle} color={'rgb(24 21 58 / 80%)'} borderColor={'rgb(24 21 58 / 80%)'}/>
                <Row>
                    {/*<Col md={12}>*/}
                    {/*    <div className="stage-wrapper"*/}
                    {/*         style={{background: url === 'kalyar-packaging' ? gradientColorsPack : gradientColors}}>*/}

                    {/*        {url === "kalyar-packaging" ?*/}
                    {/*            <>*/}
                    {/*                <div className="stage stage-pack-1">*/}
                    {/*                    <h4>Stage 1</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-pack-2">*/}
                    {/*                    <h4>Stage 2</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-pack-3">*/}
                    {/*                    <h4>Stage 3</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-pack-4">*/}
                    {/*                    <h4>Stage 4</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-pack-5">*/}
                    {/*                    <h4>Stage 5</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-pack-6">*/}
                    {/*                    <h4>Stage 6</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-pack-7">*/}
                    {/*                    <h4>Stage 7</h4>*/}
                    {/*                </div>*/}

                    {/*                <div className="stage stage-pack-8">*/}
                    {/*                    <h4>Stage 8</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-pack-9">*/}
                    {/*                    <h4>Stage 9</h4>*/}
                    {/*                </div>*/}
                    {/*            </>*/}
                    {/*            :*/}
                    {/*            <>*/}
                    {/*                <div className="stage stage-1">*/}
                    {/*                    <h4>Stage 1</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-2">*/}
                    {/*                    <h4>Stage 2</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-3">*/}
                    {/*                    <h4>Stage 3</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-4">*/}
                    {/*                    <h4>Stage 4</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-5">*/}
                    {/*                    <h4>Stage 5</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-6">*/}
                    {/*                    <h4>Stage 6</h4>*/}
                    {/*                </div>*/}
                    {/*                <div className="stage stage-7">*/}
                    {/*                    <h4>Stage 7</h4>*/}
                    {/*                </div>*/}
                    {/*            </>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col md={12} className={'tab-btn'}>
                        <div className={'tab'}>
                            {
                                data?.working_process_all && data?.working_process_all?.length > 0 &&
                                data?.working_process_all?.map((e, i) => {
                                    return (
                                        <div
                                            className={`tab__single-box ${activeTab === i + 1 ? 'active' : ''}`}
                                            key={i}
                                            onClick={() => handleTabClick(i + 1)}
                                        >
                                            <div>
                                                <div className="tab__single-box__inner">
                                                    {/*{reactHtmlParser(e?.logo)}*/}
                                                    <img className={'normal-image'} src={e?.logo}/>
                                                    <img className={'hover-image'} src={e?.logo_hover}/>
                                                </div>
                                            </div>
                                            <p>{e?.title}</p>
                                        </div>
                                    );
                                })
                            }

                        </div>
                    </Col>
                    <Col md={12}>
                        <div className={'tab-content'}>
                            {data?.working_process_all?.length > 0 && (
                                <>
                                    <h4>{selectedTab.title ? selectedTab.title : data?.working_process_all[0]?.title}</h4>
                                    <p>{selectedTab.description ? selectedTab.description : data?.working_process_all[0]?.description}</p>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F9F9F9;

  .stage-wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-height: 70px;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 100%;
      background: #F9F9F9;
    }

    background-image: linear-gradient(to right, #E9E9E9, #A19E9E);
    @media (max-width: 767px) {
      display: none;
      opacity: 0;
    }
  }

  .stage {
    width: 100%;
    height: 70px;
    line-height: 60px;
    text-align: center;
    font-weight: bold;
    color: #000000;
    background-color: #E9E9E9;
    clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%); /* Creates a trapezoid arrow */
    //background-image: linear-gradient(to right, #E9E9E9, #A19E9E); 
    h4 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }


  .stage-pack-1 {
    background: #e9e9e9;
  }

  .stage-pack-2 {
    background: rgb(219 215 215);
  }

  .stage-pack-3 {
    background: rgb(211 209 209);
  }

  .stage-pack-4 {
    background: rgb(195 195 195);
  }

  .stage-pack-5 {
    background: rgb(183 183 183);
  }

  .stage-pack-6 {
    background: rgb(171 170 170);
  }

  .stage-pack-7 {
    background: rgb(157, 157, 157);
    position: relative;
    z-index: 10;
  }

  .stage-pack-8 {
    background: rgb(141 141 141);
  }

  .stage-pack-9 {
    background: rgb(116 116 116);
    position: relative;
    z-index: 10;
  }


  .stage-1{
    background: #E9E9E9;
  }
  .stage-2{
    background: rgb(214 213 213);
  }
  .stage-3{
    background: rgb(201 200 200);
  }
  .stage-4{
    background: rgb(183 183 183);
  }
  .stage-5{
    background: rgb(168 168 168);
  }
  .stage-6{
    background: rgb(149 149 149);
  }
  .stage-7{
    background: rgb(125 125 125);
    position: relative;
    z-index: 10;
  }


  .tab-btn {
    width: 100%;

    .tab {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      overflow-x: scroll;
      padding-bottom: 40px;

      ::-webkit-scrollbar {
        width: 150px !important;
        height: 5px;

      }

      ::-webkit-scrollbar-track {
        background: #F9F9F9;

      }

      ::-webkit-scrollbar-thumb {
        background: #180E81;
        border-radius: 3px;
      }

      &__single-box {
        cursor: pointer;

        p {
          width: 150px;
          margin-top: 15px;
          color: #000000;
          text-align: center;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          font-family: ${button};
        }

        &__inner {
          //display: flex;
          //justify-content: center;
          //padding: 50px;
          .hover-image {
            opacity: 0;
            transition: opacity 0.5s ease;
          }

          .normal-image {
            opacity: 1;
            transition: opacity 0.5s ease;
          }

          border: 0.5px solid #180E81;
          transition: 0.3s all ease-in-out;
          position: relative;
          padding-top: calc(150 / 150 * 100%);

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &:hover {
          .normal-image {
            opacity: 0;
            transition: opacity 0.5s ease;
          }

          .hover-image {
            opacity: 1;
            transition: opacity 0.5s ease;
          }

          .tab__single-box__inner {
            transition: 0.3s all ease-in-out;
            background-color: #180E81 !important;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      .active {
        .tab__single-box__inner {
          background-color: #180E81 !important;

          .hover-image {
            opacity: 1;
            transition: opacity 0.5s ease;
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding-top: 0;
      .tab {
        overflow-x: scroll;
        padding-bottom: 30px;

        ::-webkit-scrollbar {
          width: 150px !important;
          height: 5px;

        }

        ::-webkit-scrollbar-track {
          background: #F9F9F9;

        }

        ::-webkit-scrollbar-thumb {
          background: #180E81;
          border-radius: 3px;
        }

        &__single-box {
          padding-right: 15px;

          p {
            width: 105px;
          }

          &__inner {
            padding-top: calc(105 / 105 * 100%);

            svg {
              max-height: 40px;
              max-width: 40px;
            }
          }
        }
      }
    }
  }

  .tab-content {
    padding: 30px 60px 60px;
    background-color: #FFFFFF;

    h4 {
      color: #262630;
    }

    p {
      margin-top: 40px;
      font-family: ${button};
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      width: 85%;
      color: rgb(38 38 48);
    }

    @media (max-width: 767px) {
      padding: 40px 15px 60px;
      p {
        width: 100%;
      }
    }
  }
`;

export default MyComponent;
