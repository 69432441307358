import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import SubTitle from "../SubTitle";
import {Img} from "../Img";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-120'} id={'converting'}>
            <Container>
                <h3>Converting</h3>
                {
                    data?.list?.length >0 && data?.list?.map((e,i)=>{
                        return(
                            <Row className={'textimg'} id={e?.button} key={i}>
                                <Col md={6} className={'textimg__left'}>
                                    <div className={'textimg__left__img'}>
                                        <Img src={e?.image}/>
                                    </div>
                                </Col>
                                <Col md={6} className={'textimg__right'}>
                                    <div className={'textimg__right__content'}>
                                        <h4>{reactHtmlParser(e?.title)}</h4>
                                        <p>
                                            {reactHtmlParser(e?.description)}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        );
                    })
                }
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  h3{
    color: #180E81;
    padding-bottom: 20px;
    margin-bottom: 60px;
    border-bottom: 0.5px solid rgb(121 121 151 / 30%);
  }
    .textimg{
      margin-bottom: 80px;
      
      &:nth-child(odd){
        flex-direction: row-reverse;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &__left{
        &__img{
          position: relative;
          padding-top: calc(368 / 550 * 100%);
        }
        @media(max-width: 767px){
          margin-bottom: 30px;
        }
      }
      &__right{
        &__content{
          padding-left: 10px;
          h4{
            color: #262630;
          }
          p{
            margin-top: 40px;
            color: rgb(38 38 48 / 70%);
            font-family: ${button};
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }
        }
        @media(max-width: 767px){
          &__content{
            padding-left: 0;
          }
        }
      }
    }
`;

export default MyComponent;
