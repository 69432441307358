import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import SubTitle from "../SubTitle";
import Title from "../Title";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {useLocation} from "react-router-dom";
import {Img} from "../Img";
import VideoSection from "./VideoSection";
import {ImageParallax} from "../ImageParallax";

const MyComponent = ({data}) => {

    const location = useLocation()

    const [offset, setOffset] = useState(90)

    const containerRef = useRef(null);

    useEffect(() => {
        setOffset(containerRef?.current ? containerRef?.current?.offsetLeft : 0);

        window.addEventListener('load', function () {
            setOffset(containerRef?.current ? containerRef?.current?.offsetLeft : 0);
        });

        window.addEventListener('resize', function () {
            setOffset(containerRef?.current ? containerRef?.current?.offsetLeft : 0);
        });
    }, [location.pathname]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const videoID = data?.video_id;

    return (
        <>
            <StyledComponent className={'pb-150 pt-150'} offset={offset}>
                {
                    window.innerWidth >767 ?
                        <img src={'/images/dynamic/home/bg.svg'} data-speed={0.7}/>
                        :
                        <img src={'/images/dynamic/home/bg-mob.svg'}/>
                }
                <Container className={'content'}>
                    <Row>
                        <Col md={12}>
                            <SubTitle
                                text={reactHtmlParser(data?.subtitle)}
                                color={'rgb(233 233 233 /60%)'}
                            />
                        </Col>
                        <Col md={6} className={'content__left'}>

                            {data && <Title classname={'split-up'}
                                            text={reactHtmlParser(data?.title)}
                                            color={'#FFFFFF'} margin={'0 0 40px'}
                            />}
                            {reactHtmlParser(data?.description)}
                        </Col>
                        <Col md={{span: 5, offset:1}} className={'content__right'}>
                            <div onClick={() => handleShow(true, data?.video_id)} className="image_video_wrap">
                                <ImageParallax src={data?.video_thumb}/>
                                <div className="play hover">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                                        <path id="Polygon_1" data-name="Polygon 1" d="M10,0,20,15H0Z"
                                              transform="translate(58.5 40) rotate(90)" fill="#004185"/>
                                    </svg>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className={'counter'}>
                        <Col md={3} className={'counter__single'}>
                            <h5>
                                <CountUp start={2000} end={data?.counter?.employee_no}>
                                    {({countUpRef, start}) => (
                                        <VisibilitySensor onChange={start}
                                                          partialVisibility={{top: 0, bottom: 20}}
                                                          delayedCall>
                                            <span ref={countUpRef}/>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>+
                            </h5>
                            <p>{reactHtmlParser(data?.counter?.employee_title)}</p>
                        </Col>
                        <Col md={3} className={'counter__single'}>
                            <h5>
                                <CountUp start={0} end={data?.counter?.choice_no}>
                                    {({countUpRef, start}) => (
                                        <VisibilitySensor onChange={start}
                                                          partialVisibility={{top: 0, bottom: 20}}
                                                          delayedCall>
                                            <span ref={countUpRef}/>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>+
                            </h5>
                            <p>{reactHtmlParser(data?.counter?.choice_title)}</p>
                        </Col>
                        <Col md={3} className={'counter__single'}>
                            <h5>
                                <CountUp start={0} end={data?.counter?.experience_no}>
                                    {({countUpRef, start}) => (
                                        <VisibilitySensor onChange={start}
                                                          partialVisibility={{top: 0, bottom: 20}}
                                                          delayedCall>
                                            <span ref={countUpRef}/>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>+
                            </h5>
                            <p>{reactHtmlParser(data?.counter?.experience_title)}</p>
                        </Col>
                        <Col md={3} className={'counter__single'}>
                            <h5>
                                <CountUp start={0} end={data?.counter?.mnc_no}>
                                    {({countUpRef, start}) => (
                                        <VisibilitySensor onChange={start}
                                                          partialVisibility={{top: 0, bottom: 20}}
                                                          delayedCall>
                                            <span ref={countUpRef}/>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>+
                            </h5>
                            <p>{reactHtmlParser(data?.counter?.mnc_title)}</p>
                        </Col>
                    </div>
                </Container>
            </StyledComponent>
            <Modal className={'modal-video'} show={show} onHide={handleClose}>

                <svg onClick={handleClose} className={'close-modal'} xmlns="http://www.w3.org/2000/svg" width="13.426"
                     height="13.423"
                     viewBox="0 0 13.426 13.423">
                    <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close"
                          d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z"
                          transform="translate(-11.285 -11.289)" fill="#fff"/>
                </svg>


                <Modal.Body>
                    <iframe width="560" height="315"
                            src={`https://www.youtube.com/embed/${videoID}?controls=1&autoplay=1`}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                </Modal.Body>

            </Modal>
        </>
    );
};

const StyledComponent = styled.section`
    background-color: #18153A;
  margin-top: -1px;
  position: relative;
  overflow: hidden;
  img{
    position: absolute;
    right: ${props=> props.offset}px;
    top: 84px;
    bottom: 0;
  }
  .content{
    &__left{
      .title{
        h2{
          width: 100%;
        }
      }
      
      @media(max-width: 991px){
        max-width: 100% !important;
        flex: 0 0 100% !important;
        margin-bottom: 40px;
      }
    }
    &__right{
      .image_video_wrap {
        position: relative;
        padding-top: calc(250 / 350 * 100%);
        cursor: pointer;
        overflow: hidden;

        .global-image{
          img{
            top: 0 !important;
          }
        }

        .play {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background-color: white;
          @media (max-width: 767px) {
            height: 50px;
            width: 50px;
            svg {
              height: 50px;
              width: 50px;
            }
          }

          &:before {
            content: '';
            position: absolute;
            height: calc(100% + 30px);
            width: calc(100% + 30px);
            border: none;
            background: #3CB649;
            left: -15px;
            top: -15px;
            border-radius: 50%;
            opacity: 0;
            transition: 0.6s all cubic-bezier(0.4, 0, 0, 1);
            z-index: -4;
          }

          &:after {
            content: "";
            z-index: 0;
            //background-color: rgba(30, 92, 149, 0.5);
            overflow: hidden;
            border-radius: 50%;
            transition: 0.6s all cubic-bezier(0.4, 0, 0, 1);
          }

          svg {
            height: 60px;
            width: 60px;
            #Ellipse_396, path {
              transition: 0.7s all ease;
            }
          }
          &:hover{
            svg {
              position: absolute;
              z-index: 999;
              #Ellipse_396 {
                r: 50;
              }

              path {
                fill: white;
              }
            }
          }
        }
        @media (max-width: 767px) {
          padding-top: calc(250 / 375 * 100%);
        }
      }
      @media(max-width: 991px){
        max-width: 100% !important;
        flex: 0 0 100% !important;
        margin-left: 0 !important;
      }
    }
  }
  .counter{
    margin-top: 165px;
    display: flex;
    &__single{
      //min-width: 270px;
      padding-right: 65px;
      h5{
        font-family: ${button};
        font-size: 48px;
        line-height: 62px;
        font-weight: 300;
        border-bottom: 1px solid rgb(121 121 151 / 60%);
        padding-bottom: 20px;
        margin-bottom: 20px;
        color: #9F9FBF;
      }
      p{
        font-weight: 400;
        color: #9F9FBF;
        text-transform: initial;
      }
    }
  }
  
  @media(max-width: 767px){
    .content{
      &__left{
        
      }
      &__right{
        p{
          margin-top: 60px;
        }
      }
    }
    .counter{
      margin-top: 120px;
      flex-direction: column;
      &__single{
        margin-bottom: 60px;
        padding-right: 0;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default MyComponent;
