import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get, post} from "../../network/axiosServices";
// import {loaded} from "../global";


const initialState = {
    loading: false,
    posts: [],
    error: '',
}

export const fetchHome = createAsyncThunk("fetchHome", (params, {dispatch}) => {
    return get(params);
});

export const postHomeForm = createAsyncThunk("homeForm", (params) => {
    return post(params);
});


const postSlice = createSlice({
    name: 'home',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchHome.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchHome.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''

        })
        builder.addCase(fetchHome.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        //form
        builder.addCase(postHomeForm.pending, (state) => {
            state.loading = true;
            state.success = [];
            state.error = "";
        });
        builder.addCase(postHomeForm.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload.message;
            state.error = "";

        });
        builder.addCase(postHomeForm.rejected, (state, action) => {
            state.loading = false;
            state.success = '';
            state.error = action.error;

        });
    }
})


export default postSlice.reducer
