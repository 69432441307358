import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import MediaDetails from "../../components/media/BlogDetails";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchMediaDetail} from "../../api/redux/Media";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {motion} from "framer-motion"
import {PageAnimation} from "../../components/PageAnimation";

const MediaDetailsIndex = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.mediaReducer)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIA_DETAILS
        dispatch(fetchMediaDetail([api_url + `/${slug}`]))
    }, [])

    const content = getData?.detail?.[0]



    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${content?.page_meta?.meta_title ? content?.page_meta?.meta_title + ' | ' + "Kalyar" : content?.post_title + ' | ' + "Kalyar"}`}</title>
                {
                    content?.page_meta?.meta_desc &&
                    <meta name="description" content={content?.page_meta?.meta_desc}/>

                }
                {
                    content?.page_meta?.og_title &&
                    <meta property="og:title" content={content?.page_meta?.og_title}/>

                }
                {
                    content?.page_meta?.og_desc &&
                    <meta property="og:description" content={content?.page_meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={content?.banner_image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <MediaDetails title={content?.post_title}
                              date={content?.list?.date}
                              banner={content?.banner_image}
                              shortDesc={content?.list?.short_desc}
                              desc={content?.post_content}
                              full={content?.full_image}
                              right={content?.right_image}
                              left_desc={content?.left_images_desc}
                              right_desc={content?.right_image_desc}
                              left={content?.left_image}
                              videoThumb={content?.video?.banner}
                              id={content?.video?.id}
                              category={content?.category?.[0]?.name}
                              link={content?.video?.embedded_link}
                />
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MediaDetailsIndex;
