import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SocialIconDetailsPage from "../SocialIconDetailsPage";
import VideoModal from "../VideoModal";
import {button, hover} from "../../styles/globalStyleVars";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";


const MediaDetails = ({title, date, banner, shortDesc, desc, videoThumb, category, id, link, full, right, left,right_desc,left_desc}) => {
    let [open, setOpen] = useState(false);
    const getPost = useSelector((state) => state)
    let handelOpen = (open, id) => {
        setOpen(open);
        setShow(true)
    };


    let [videoId, setVideo] = useState('');


    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
        setVideo(true)
    }
    const handleCloseModal = () => {
        setOpen(false);
        handleClose();
    };

    const next = () => {
        document.querySelector('.portfolio-details .mySwiper .swiper-button-next').click();
    }

    const prev = () => {
        document.querySelector('.portfolio-details .mySwiper .swiper-button-prev').click();
    }

    const dateObject = new Date(date);
    const getDate = dateObject?.getDate();
    const getMonth = dateObject.toLocaleString('default', {month: 'long'});
    const getYear = dateObject.getFullYear();

    // sticky social icon
    const location = useLocation();

    // sticky social icon
    gsap.registerPlugin(ScrollTrigger);


    useLayoutEffect(() => {
        const col4Element = document.querySelector('.detail-page');
        gsap.to(col4Element, {
            scrollTrigger: {
                trigger: col4Element,
                start: 'top +100',
                end: 'bottom +100',
                pin: true,
                pinSpacing: false
            }
        });

    }, [location.pathname]);


    // Define the banner for different viewports
    const largeImage = banner?.large;
    const mediumImage = banner?.medium;
    const smallImage = banner?.thumbnail;

    // const router = useRouter();

    const [shareUrl, setShareUrl] = useState('')
    useEffect(() => {
        setShareUrl(window.location.href)
    }, [])
    // Determine the viewport size and set the image accordingly
    let imgUrl;
    if (window.innerWidth >= 1366) {
        imgUrl = largeImage;
    } else if (window.innerWidth >= 768) {
        imgUrl = mediumImage;
    } else {
        imgUrl = smallImage;
    }


    //video banner
    // Define the images for different viewports
    const largeImageV = videoThumb?.large;
    const mediumImageV = videoThumb?.medium;
    const smallImageV = videoThumb?.thumbnail;

    // Determine the viewport size and set the image accordingly
    let imgUrlV;
    if (window.innerWidth >= 1366) {
        imgUrlV = largeImageV;
    } else if (window.innerWidth >= 768) {
        imgUrlV = mediumImageV;
    } else {
        imgUrlV = smallImageV;
    }


    const formattedDate = new Date(date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    });

    const [day, month, year] = formattedDate.split(' ');
    const formattedWithComma = `${day} ${month}, ${year}`;

    return (
        <StyledComponent className='portfolio-details pt-150 pb-150'>
            <Container>
                <Row>
                    <Col lg={{offset: 3, span: 9}}>
                        <ul className={'info'}>
                            <li>{category}</li>
                            <li>{formattedWithComma}</li>
                        </ul>

                        <h3 className={'split-up'}>{title}</h3>
                    </Col>
                </Row>
            </Container>
            <div className="img-wrapper-banner">
                <Img src={imgUrl ? imgUrl : ''}/>
            </div>

            <Container>
                <Row>
                    <Col lg={'3'} className={'detail-page'}>
                        <div className={'detail-page__left'}>
                            <p className={'detail-page__left__text'}>Share:</p>
                            <div>
                                <div className={'details-social'}>
                                    <div className={'social hover'}>
                                        <div>
                                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`} target="_blank">
                                            <svg id="Component_93_13" data-name="Component 93 – 13"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="40" height="40" viewBox="0 0 40 40">
                                                <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4"
                                                        transform="translate(16 16)" fill="#180e81" opacity="0"/>
                                                <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81"
                                                   stroke-width="0.5">
                                                    <circle cx="20" cy="20" r="20" stroke="none"/>
                                                    <circle cx="20" cy="20" r="19.75" fill="none"/>
                                                </g>
                                                <path id="Path_2115" data-name="Path 2115"
                                                      d="M1206.295,104.537l.416-2.715h-2.6V100.06a1.357,1.357,0,0,1,1.53-1.466h1.185V96.283a14.438,14.438,0,0,0-2.1-.184,3.314,3.314,0,0,0-3.547,3.654v2.069h-2.385v2.715h2.385V111.1h2.935v-6.562Z"
                                                      transform="translate(-1182.787 -84.1)" fill="#180e81"/>
                                            </svg>
                                            </a>
                                            {/*<FacebookShareButton url={shareUrl}/>*/}
                                        </div>
                                    </div>
                                    <div className={'social hover'}>
                                        <div>
                                            <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`} target="_blank">
                                                <svg id="Component_167_4" data-name="Component 167 – 4"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="40" height="40" viewBox="0 0 40 40">
                                                    <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4"
                                                            transform="translate(16 16)" fill="#180e81" opacity="0"/>
                                                    <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81"
                                                       stroke-width="0.5">
                                                        <circle cx="20" cy="20" r="20" stroke="none"/>
                                                        <circle cx="20" cy="20" r="19.75" fill="none"/>
                                                    </g>
                                                    <path id="Twittor-01-01"
                                                          d="M10.627,14.63a.423.423,0,0,1-.368-.18C9.09,12.875,6.51,9.417,6.51,9.417l-4.725,5.07a.387.387,0,0,1-.255.142H0l.42-.48,3.63-3.885C4.628,9.65,5.2,9.035,5.782,8.42Q3.656,5.607,1.553,2.765L.323,1.122.105.785.4.74h4.02a.389.389,0,0,1,.315.173L8.325,5.7,11.587,2.21c.412-.442.825-.877,1.237-1.32a.492.492,0,0,1,.33-.142h.87l.488.03-.2.285L9.068,6.695,15,14.585l-.292.045a.438.438,0,0,1-.112.015h-3.96Zm.435-1.335a.109.109,0,0,0,.068.03h1.448L4.08,2l-.953-.023h-.6L11.062,13.3Z"
                                                          transform="translate(13 12.26)" fill="#180e81"/>
                                                </svg>
                                            </a>
                                            {/*<TwitterShareButton url={shareUrl}/>*/}
                                        </div>

                                    </div>
                                    {/*<div className={'social hover'}>*/}
                                    {/*    <div>*/}
                                    {/*        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`} target="_blank">*/}
                                    {/*            <svg id="Component_93_11" data-name="Component 93 – 11"*/}
                                    {/*                 xmlns="http://www.w3.org/2000/svg"*/}
                                    {/*                 width="40" height="40" viewBox="0 0 40 40">*/}
                                    {/*                <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4"*/}
                                    {/*                        transform="translate(16 16)" fill="#180e81" opacity="0"/>*/}
                                    {/*                <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81"*/}
                                    {/*                   stroke-width="0.5">*/}
                                    {/*                    <circle cx="20" cy="20" r="20" stroke="none"/>*/}
                                    {/*                    <circle cx="20" cy="20" r="19.75" fill="none"/>*/}
                                    {/*                </g>*/}
                                    {/*                <g id="Group_17407" data-name="Group 17407"*/}
                                    {/*                   transform="translate(13 13)">*/}
                                    {/*                    <path id="Path_2109" data-name="Path 2109"*/}
                                    {/*                          d="M1095.8,105.945a.879.879,0,1,0,.879.88A.879.879,0,0,0,1095.8,105.945Z"*/}
                                    {/*                          transform="translate(-1084.324 -103.267)" fill="#180e81"/>*/}
                                    {/*                    <path id="Path_2110" data-name="Path 2110"*/}
                                    {/*                          d="M1082.749,108.605a3.694,3.694,0,1,0,3.694,3.694A3.7,3.7,0,0,0,1082.749,108.605Zm0,6.06a2.366,2.366,0,1,1,2.366-2.366A2.368,2.368,0,0,1,1082.749,114.665Z"*/}
                                    {/*                          transform="translate(-1075.187 -104.799)" fill="#180e81"/>*/}
                                    {/*                    <path id="Path_2111" data-name="Path 2111"*/}
                                    {/*                          d="M1080.426,114.628h-5.989a4.511,4.511,0,0,1-4.506-4.506v-5.989a4.511,4.511,0,0,1,4.506-4.505h5.989a4.51,4.51,0,0,1,4.506,4.505v5.989A4.511,4.511,0,0,1,1080.426,114.628Zm-5.989-13.589a3.1,3.1,0,0,0-3.095,3.094v5.989a3.1,3.1,0,0,0,3.095,3.094h5.989a3.1,3.1,0,0,0,3.094-3.094v-5.989a3.1,3.1,0,0,0-3.094-3.094Z"*/}
                                    {/*                          transform="translate(-1069.932 -99.628)" fill="#180e81"/>*/}
                                    {/*                </g>*/}
                                    {/*            </svg>*/}
                                    {/*        </a>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                            </div>
                        </div>
                        {/*<ButtonLongDownload text={'Download PDF'} color={'black'} icon={'black'} src={'/'}/>*/}
                    </Col>

                    <Col lg={9} className={'left-content'}>
                        <Row className={'upper-text'}>
                            <Col md={12}>


                                <h4>{shortDesc}</h4>

                                <p>{reactHtmlParser(desc)}</p>

                            </Col>

                            {
                                full &&
                               full?.length > 0 && full?.map((e,i)=>{
                                   return(
                                       <Col md={12} key={i}>
                                           <div className="img-wrapper">
                                               <Img src={e?.url}/>
                                           </div>
                                           <p>{reactHtmlParser(e?.description)}</p>
                                       </Col>
                                   );
                                })
                            }

                            {
                                left &&
                                <Col xs={6}>
                                    <div className="img-wrapper img-half">
                                        <Img src={left}/>
                                    </div>
                                    <p>{reactHtmlParser(left_desc)}</p>
                                </Col>
                            }

                            {
                                right &&
                                <Col xs={6}>
                                    <div className="img-wrapper img-half">
                                        <Img src={right}/>
                                    </div>
                                    <p>{reactHtmlParser(right_desc)}</p>
                                </Col>
                            }

                            {
                                id &&
                                <Col md={12} onClick={() => handelOpen(true, id)} key={0} className='video'>
                                    <div className='video-wrapper'>
                                        <Img src={imgUrlV}/>

                                        <div className="video-icon">
                                            <div className="icon-inner">
                                                <svg width="60" height="60" viewBox="0 0 60 60">
                                                    <g id="Group_14000" data-name="Group 14000"
                                                       transform="translate(0.49)">
                                                        <g
                                                            id="Ellipse_381"
                                                            data-name="Ellipse 381"
                                                            transform="translate(-0.49)"
                                                            fill="#FFFFFF"
                                                            stroke="none"
                                                            stroke-width="1"
                                                        >
                                                            <circle cx="30" cy="30" r="30" stroke="none"></circle>
                                                            <circle className="video-hover" cx="30" cy="30" r="30.5"
                                                                    fill="#180e81"></circle>
                                                        </g>
                                                        <path
                                                            id="Polygon_2"
                                                            data-name="Polygon 2"
                                                            d="M8.143,1.429a1,1,0,0,1,1.715,0l7.234,12.056A1,1,0,0,1,16.234,15H1.766a1,1,0,0,1-.857-1.514Z"
                                                            transform="translate(39.51 21) rotate(90)"
                                                            fill="#180e81"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            }
                            {
                                link && link?.length > 0 &&
                                link?.map((e, i) =>{
                                    return(
                                        <Col md={12} key={i}>
                                            <div className={'video-link'}>
                                                <iframe src={e?.link}
                                                        title="Video player" frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        allowFullScreen/>
                                            </div>
                                        </Col>
                                    );
                                })
                            }


                        </Row>

                    </Col>

                </Row>
            </Container>

            <VideoModal show={show} video={true} direct_video={id}
                        handleClose={handleClose}/>
        </StyledComponent>


    );
};

const StyledComponent = styled.section`

  .img-wrapper-banner {
    position: relative;
    padding-top: calc(505 / 1366 * 100%);
    margin-bottom: 60px;

    @media (max-width: 767px) {
      padding-top: calc(270 / 345 * 100%);
      margin-bottom: 0;
    }
  }

  h3 {
    color: #262630;
    margin-bottom: 40px;
  }

  .info {
    display: flex;
    margin-bottom: 20px;

    li {
      display: inline-block;
      position: relative;
      padding-right: 20px;
      margin-right: 20px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #262630;
      font-family: ${button};
      opacity: 50%;

      &:after {
        margin: 0;
        height: 5px;
        width: 5px;
        background: #1A1A1A;
        position: absolute;
        top: 10px;
        bottom: 0;
        right: 0;
        content: '';
        border-radius: 50px;
      }

      &:nth-last-child(1) {
        margin-right: 0px;

        &:after {
          content: unset;
        }
      }
    }
  }

  .left-content {
    padding-bottom: 60px;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 15px;
      background: #323232;
    }
  }

  .img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .contributors {
    position: relative;
    margin-bottom: 60px;

    &:after {
      content: '';
      height: 1px;
      width: calc (100%-30px);
      bottom: 0;
      left: 15px;
      right: 15px;
      position: absolute;
      background: #1E1E2E;
    }

    h6 {
      margin-bottom: 5px !important;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      font-family: ${button};
      margin-bottom: 30px !important;
    }
  }

  .contributors {
    .col-md-4:nth-last-child(-n+1) {
      p {
        margin-bottom: 60px !important;
      }
    }
  }

  .detail-page {
    .details-social {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .social {
      position: relative;
      width: fit-content;
      cursor: pointer;

      &:hover {
        svg {
          position: relative;
          z-index: 2;

          path {
            fill: #ffffff;
          }
        }
      }
    }
  }

  .upper-text {
    .img-wrapper{
      margin-bottom: 30px!important;
    }
    @media (max-width: 767px) {
      padding-top: 60px;
      .img-wrapper{
        margin-bottom: 20px!important;
      }
    }

    //p {
    //  padding-bottom: 40px;
    //}

    .slider_overlap {
      .single_testimonial {
        position: relative;
        padding-top: calc(520 / 870 * 100%);
      }

      .slider-deatils {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 60px;

        p {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 0;
          display: flex;
          align-items: center;
        }
      }

      .swiper-button-next, .swiper-button-prev {
        display: none;
      }

      .navigation_button {
        svg {
          width: 40px;
        }
      }

      @media (max-width: 767px) {
        .gallery-svg__section {
          padding-bottom: 0;

          ul {
            padding-top: 40px;
            justify-content: flex-start !important;
          }
        }
      }

    }

    .mb-10 {
      margin-bottom: 10px !important;
    }

    p {
      color: #262630;
      margin-bottom: 60px;
    }

    h4 {
      color: #262630;
      margin-bottom: 20px;
    }


    .img-wrapper {
      position: relative;
      padding-top: calc(432 / 870 * 100%);
      margin-bottom: 60px;

      &.img-half {
        padding-top: calc(250 / 420 * 100%);
      }
    }

    .img-wrapper-text {
      margin-bottom: 60px;
    }

    .img-wrapper-two {
      position: relative;
      padding-top: calc(520 / 870 * 100%);
      margin-bottom: 20px !important;
    }

    h5 {
      color: #262630;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 40px;
    }

    h6 {
      color: #262630;
      margin-bottom: 20px;
    }

    .opacity-50 {
      opacity: 50%;
    }

    .opacity-70 {
      opacity: 70%;
    }

    .list {
      margin-bottom: 40px;

      li {
        position: relative;
        padding: 15px 0 15px 20px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
        color: rgba(50, 50, 50, 0.8);
        border-bottom: 1px solid rgb(50 50 50 / 10%);
        counter-increment: count;

        &:after {
          content: '';
          position: absolute;
          height: 10px;
          width: 10px;
          padding: 3.4px 0px 0px 9px;
          margin: 20px 0;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          border: none;
          background-color: ${hover};
          border-radius: 50%;
          //color: #070524;
          top: 0;
          left: 0;
        }

        &:nth-child(1) {
          padding-top: 0;

          &:after {
            margin-top: 5px;
          }
        }

        &:nth-last-child(1) {
          padding-bottom: 0;
          border-bottom: 0;

        }
      }
    }

    table {
      margin-bottom: 40px;

      tr {
        border-bottom: 1px solid rgba(50, 50, 50, 0.2);


        &:nth-child(1) {
          border-bottom: 1px solid rgba(50, 50, 50, 1);
        }

        &:nth-last-child(1) {
          border-bottom: 0;

          td {
            padding-bottom: 0;
          }
        }

        th {
          font-size: 20px;
          line-height: 26px;
          font-weight: 700;
          color: #1E1E2E;
          padding: 0 0 20px 0;
        }

        td {
          font-size: 20px;
          line-height: 26px;
          font-weight: 400;
          padding: 20px 0;
          color: #1E1E2E;
        }
      }
    }

    blockquote {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      margin-bottom: 20px;
      padding-left: 20px;
      color: #323232;
      border-left: 4px solid ${hover};
    }
  }

  @media (max-width: 767px) {

    .detail-page {
      display: none;
    }

    padding-top: 160px !important;

    .portfolio-details__social {
      display: flex;
      justify-content: space-between;

      ul {
        display: flex;
        gap: 20px;
      }
    }

    table {
      th {
        width: 50%;
        padding: 0 20px 20px 0 !important;
      }
    }
  }
  @media (max-width: 991px) and (min-width: 768px) {
    .detail-page {
      padding-bottom: 60px;
    }

    .detail-page {
      display: none;
    }
  }

  .video {
    padding-top: 40px;

    .video-wrapper {
      position: relative;
      overflow: hidden;
      padding-top: calc(520 / 870 * 100%);
      margin: 0 0 60px 0;
      cursor: pointer;

      .video-icon {
        overflow: unset !important;
        position: absolute;
        z-index: 8;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: fit-content;
        height: fit-content;
        margin: auto;

        svg {
          overflow: hidden;
          border-radius: 50%;

          .video-hover {
            cx: -90px;
            opacity: 0;
            transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
          }

          path {
            transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
          }
        }
      }

      &:hover {
        .video-icon {
          svg {
            .video-hover {
              cx: 30px;
              opacity: 1;
              transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
            }

            path {
              fill: #fff;
              transition: all 0.8s cubic-bezier(0.76, 0, 0.24, 1);
            }
          }
        }
      }
    }
  }


  .detail-page__left__text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    padding-bottom: 20px;
    color: #1A1A1A;
  }


  .video-link {
    position: relative;
    height: 70vh;
    margin-top: 50px;
    iframe {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border: 0;
      box-shadow: none;
    }
  }

`;

export default MediaDetails;
