import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchPosts, fetchPostDetail} from "../../api/redux/Media";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";

import Overview from "../../components/home/Overview";
import Company from "../../components/home/Company";
import Career from "../../components/home/Career";
import KeyFeatures from "../../components/home/KeyFeatures";
import MediaSlider from "../../components/home/MediaSlider";
import Cta from "../../components/Cta";
import Form from "../../components/Form";
import HomeBanner from "../../components/home/HomeBanner";
import {Helmet, HelmetProvider} from "react-helmet-async";
import homeReducer, {fetchHome} from "../../api/redux/Home";
import {useLocation} from "react-router-dom";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import {ScrollSmoother} from "gsap/ScrollSmoother";


const Home = () => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    const location = useLocation()


    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME
        dispatch(fetchHome([api_url]))
    }, [])

    let getPost = useSelector(state => state.homeReducer);


    const data = getPost?.posts?.[0];

    const banner = getPost?.posts?.[0]?.banner;
    const overview = getPost?.posts?.[0]?.overview;
    const companies = getPost?.posts?.[0]?.companies;
    const career = getPost?.posts?.[0]?.career;
    const sustainability = getPost?.posts?.[0]?.sustainability;
    const media = getPost?.posts?.[0]?.media;
    const invest = getPost?.posts?.[0]?.invest;
    const contact = getPost?.posts?.[0]?.contact;


    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    useEffect(() => {
        if (location.hash && !getPost?.loading) {
            const targetElement = document.querySelector(location.hash);
            const topPx = targetElement.offsetTop - 20;
            if (targetElement) {
                gsap.to(window, {
                    duration: 0.8,
                    scrollTo: topPx,
                });
            }
        }
    }, [location.hash, getPost]);


    return (
        <HelmetProvider>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.post_title ? data?.post_title + ' | ' + 'Kalyar' : ''}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={'/images/static/logo.svg'}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledSection>

                    <HomeBanner data={banner}/>

                    <Overview data={overview}/>

                {
                    companies &&
                    <Company data={companies}/>
                }

                { career &&
                    <Career data={career}/>
                }


                    <KeyFeatures id={'sustainability'} data={sustainability}/>

                    <MediaSlider data={media}/>

                    <Cta data={invest}/>

                {
                    contact &&
                    <Form data={contact}/>
                }

            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
