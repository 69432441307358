import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

const MyComponent = () => {

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentYear(new Date().getFullYear());
        }, 1000); // Update the year every second for demonstration purpose, you can adjust the interval as needed
        return () => clearInterval(interval);
    }, []); // Run only once on component mount
    return (
        <StyledComponent>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className={'footer-top'}>
                            <Link to={'/'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="80" viewBox="0 0 70 80">
                                    <g id="Group_23650" data-name="Group 23650" transform="translate(-735 -6789)">
                                        <rect id="Rectangle_6105" data-name="Rectangle 6105" width="70" height="80"
                                              transform="translate(735 6789)" fill="#fff"/>
                                        <g id="Group_19630" data-name="Group 19630" transform="translate(637 894)">
                                            <path id="Path_4002" data-name="Path 4002"
                                                  d="M7.649,0S18.373,17.844,19.259,38.038c0,0-8.867,14.18-14.106,20.464,0,0,2.742-.48,14.106-12.409,0,0,.517,6.444-1.205,17.574,0,0,4.4-3.173,6.284-23.12,0,0,5.485-8.707,6.6-10.638,0,0,22.481,36.918,23.846,38.05H0V0Z"
                                                  transform="translate(98 5895)" fill="#3b3092"/>
                                            <path id="Path_4003" data-name="Path 4003"
                                                  d="M14.29,0s5.423,17.414,6.764,30.548c0,0,9.752-16.049,13.048-30.548Z"
                                                  transform="translate(101.284 5895)" fill="#3b3092"/>
                                            <path id="Path_4004" data-name="Path 4004"
                                                  d="M39.219,0S30.992,19.111,27.77,24.51c0,0,27.474,39.661,30.056,43.449h5.792V0h-24.4Z"
                                                  transform="translate(104.381 5895)" fill="#3b3092"/>
                                            <path id="Path_4005" data-name="Path 4005"
                                                  d="M4.738,59.73.815,65.707H.04l1.267-1.845L.52,59.73h.726l.627,3.3L4,59.73Z"
                                                  transform="translate(98.009 5908.727)" fill="#3b3092"/>
                                            <path id="Path_4006" data-name="Path 4006"
                                                  d="M5.534,64.242a1.69,1.69,0,0,1-1.279-.467A1.732,1.732,0,0,1,3.8,62.5a3.132,3.132,0,0,1,.676-2.029A2.12,2.12,0,0,1,6.21,59.63a1.728,1.728,0,0,1,1.267.467,1.751,1.751,0,0,1,.455,1.279,3.141,3.141,0,0,1-.689,2.029A2.125,2.125,0,0,1,5.534,64.242Zm.59-4.046a1.353,1.353,0,0,0-1.168.664A2.875,2.875,0,0,0,4.513,62.5c0,.8.357,1.193,1.07,1.193a1.353,1.353,0,0,0,1.168-.664,2.874,2.874,0,0,0,.443-1.636c0-.8-.357-1.193-1.082-1.193Z"
                                                  transform="translate(98.873 5908.705)" fill="#3b3092"/>
                                            <path id="Path_4007" data-name="Path 4007"
                                                  d="M12.037,59.732,11.028,64.1H10.34l.123-.529a2.991,2.991,0,0,1-1.586.639,1.188,1.188,0,0,1-.849-.3,1.067,1.067,0,0,1-.307-.824,2.889,2.889,0,0,1,.086-.664l.627-2.706h.689L8.544,62.2a4.05,4.05,0,0,0-.1.75c0,.43.234.639.7.639A2.734,2.734,0,0,0,10.585,63l.762-3.284h.689Z"
                                                  transform="translate(99.774 5908.723)" fill="#3b3092"/>
                                            <path id="Path_4008" data-name="Path 4008"
                                                  d="M15.167,59.767,15,60.48H14.97a2.345,2.345,0,0,0-.627-.074,2.529,2.529,0,0,0-1.439.553L12.191,64.1h-.7l1-4.366h.689l-.148.676a3.059,3.059,0,0,1,1.636-.676,2.159,2.159,0,0,1,.492.037Z"
                                                  transform="translate(100.64 5908.727)" fill="#3b3092"/>
                                            <path id="Path_4009" data-name="Path 4009"
                                                  d="M18.019,62.379v1.992H16.74V58.53h2.214A2.639,2.639,0,0,1,20.675,59a1.652,1.652,0,0,1,.59,1.353,1.859,1.859,0,0,1-.652,1.488,2.517,2.517,0,0,1-1.7.553h-.9Zm0-2.927v2h.344a2.067,2.067,0,0,0,1.279-.271,1.032,1.032,0,0,0,.307-.824.842.842,0,0,0-.3-.689,2.064,2.064,0,0,0-1.193-.234h-.43Z"
                                                  transform="translate(101.847 5908.453)" fill="#3b3092"/>
                                            <path id="Path_4010" data-name="Path 4010"
                                                  d="M26.189,64.371H24.836L24.394,63.1h-2.3l-.443,1.267H20.36l2.214-5.842h1.414Zm-2.115-2.177-.824-2.361-.824,2.361Z"
                                                  transform="translate(102.679 5908.453)" fill="#3b3092"/>
                                            <path id="Path_4011" data-name="Path 4011"
                                                  d="M30.224,64a6.282,6.282,0,0,1-1.168.418,4.955,4.955,0,0,1-.836.086,2.818,2.818,0,0,1-2.115-.8,3.1,3.1,0,0,1-.775-2.238,3.065,3.065,0,0,1,.775-2.226,2.751,2.751,0,0,1,2.1-.824,4.384,4.384,0,0,1,2,.492v1.205h-.135a2.72,2.72,0,0,0-1.722-.775,1.481,1.481,0,0,0-1.242.566,2.4,2.4,0,0,0-.455,1.574,2.367,2.367,0,0,0,.467,1.562,1.514,1.514,0,0,0,1.23.553,2.178,2.178,0,0,0,.787-.148,3.334,3.334,0,0,0,.947-.627h.123V64Z"
                                                  transform="translate(103.821 5908.426)" fill="#3b3092"/>
                                            <path id="Path_4012" data-name="Path 4012"
                                                  d="M35.282,64.371H33.708l-1.931-2.423-.43.492v1.931H30.08V58.53h1.279V61.3l2.324-2.767h1.488l-2.386,2.706Z"
                                                  transform="translate(104.912 5908.453)" fill="#3b3092"/>
                                            <path id="Path_4013" data-name="Path 4013"
                                                  d="M40.625,61.979H37.391c0,.123-.025.234-.025.332a1.3,1.3,0,0,0,.394,1,1.6,1.6,0,0,0,1.095.344,2.493,2.493,0,0,0,1.476-.443h.037l-.172.738a5.11,5.11,0,0,1-1.427.271,2.244,2.244,0,0,1-1.525-.48,1.709,1.709,0,0,1-.553-1.365,2.889,2.889,0,0,1,.676-1.955,2.175,2.175,0,0,1,1.722-.787,1.745,1.745,0,0,1,1.217.394,1.55,1.55,0,0,1,.43,1.168,2.769,2.769,0,0,1-.049.541l-.049.246Zm-3.148-.48h2.57a1.565,1.565,0,0,0,.025-.344c0-.652-.357-.971-1.07-.971a1.457,1.457,0,0,0-.972.357,1.712,1.712,0,0,0-.553.972Z"
                                                  transform="translate(106.431 5908.7)" fill="#3b3092"/>
                                            <path id="Path_4014" data-name="Path 4014"
                                                  d="M44.935,59.73l-2.14,2.189L43.926,64.1h-.775L42.3,62.4l-1.6,1.7H39.88l2.152-2.214L40.913,59.73h.775l.849,1.685,1.586-1.685Z"
                                                  transform="translate(107.164 5908.727)" fill="#3b3092"/>
                                            <path id="Path_4015" data-name="Path 4015"
                                                  d="M44.669,63.85l-.43,1.882H43.55l1.377-5.989h.689l-.111.48a3.948,3.948,0,0,1,.861-.492,1.962,1.962,0,0,1,.652-.111,1.187,1.187,0,0,1,.972.43,1.712,1.712,0,0,1,.357,1.156,3.31,3.31,0,0,1-.689,2.115,2.1,2.1,0,0,1-1.709.861,2.435,2.435,0,0,1-1.267-.357Zm.7-3.074-.59,2.558a2.475,2.475,0,0,0,1.119.283,1.423,1.423,0,0,0,1.23-.664,2.9,2.9,0,0,0,.467-1.685c0-.676-.307-1.045-.922-1.045a2.514,2.514,0,0,0-1.316.553Z"
                                                  transform="translate(108.007 5908.701)" fill="#3b3092"/>
                                            <path id="Path_4016" data-name="Path 4016"
                                                  d="M51.818,61.979H48.583c0,.123-.012.234-.012.332a1.3,1.3,0,0,0,.394,1,1.567,1.567,0,0,0,1.082.344,2.493,2.493,0,0,0,1.476-.443h.037l-.184.738a5.035,5.035,0,0,1-1.427.271,2.266,2.266,0,0,1-1.525-.48,1.709,1.709,0,0,1-.553-1.365,2.9,2.9,0,0,1,.689-1.955,2.175,2.175,0,0,1,1.722-.787,1.745,1.745,0,0,1,1.218.394,1.5,1.5,0,0,1,.43,1.168,2.767,2.767,0,0,1-.049.541l-.049.246Zm-3.161-.48h2.57a3.023,3.023,0,0,0,.025-.344c0-.652-.357-.971-1.07-.971a1.435,1.435,0,0,0-.972.357,1.712,1.712,0,0,0-.553.972Z"
                                                  transform="translate(109 5908.7)" fill="#3b3092"/>
                                            <path id="Path_4017" data-name="Path 4017"
                                                  d="M55.152,59.767l-.16.713h-.025a2.346,2.346,0,0,0-.627-.074,2.529,2.529,0,0,0-1.439.553L52.188,64.1H51.5l1-4.366h.689l-.148.676a3.055,3.055,0,0,1,1.648-.676,2.213,2.213,0,0,1,.492.037Z"
                                                  transform="translate(109.835 5908.727)" fill="#3b3092"/>
                                            <path id="Path_4018" data-name="Path 4018"
                                                  d="M57.464,59.967l-.123.529H55.816l-.48,2.066a3.243,3.243,0,0,0-.111.738.487.487,0,0,0,.172.43.9.9,0,0,0,.541.123,2.139,2.139,0,0,0,.627-.135h.025l-.135.59a3.928,3.928,0,0,1-.861.111c-.726,0-1.095-.32-1.095-.972a2.822,2.822,0,0,1,.062-.553l.553-2.4h-.5l.123-.529h.517l.283-1.267h.689l-.3,1.267h1.537Z"
                                                  transform="translate(110.524 5908.487)" fill="#3b3092"/>
                                        </g>
                                    </g>
                                </svg>
                            </Link>

                        </div>
                    </Col>
                    <Col>
                        <div className={'footer-down'}>
                            {window.innerWidth < 767 ?
                                <div className={'footer-social'}>
                                    <div className={'social hover'}>
                                        <a href={'https://www.facebook.com/kalyarreplica/'} target={"_blank"}>
                                            <svg id="Component_93_13" data-name="Component 93 – 13" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4" transform="translate(16 16)" fill="#180e81" opacity="0"/>
                                                <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81" stroke-width="0.5">
                                                    <circle cx="20" cy="20" r="20" stroke="none"/>
                                                    <circle cx="20" cy="20" r="19.75" fill="none"/>
                                                </g>
                                                <path id="Path_2115" data-name="Path 2115" d="M1206.295,104.537l.416-2.715h-2.6V100.06a1.357,1.357,0,0,1,1.53-1.466h1.185V96.283a14.438,14.438,0,0,0-2.1-.184,3.314,3.314,0,0,0-3.547,3.654v2.069h-2.385v2.715h2.385V111.1h2.935v-6.562Z" transform="translate(-1182.787 -84.1)" fill="#180e81"/>
                                            </svg>
                                        </a>

                                    </div>
                                    <div className={'social hover'}>
                                        <a href={'https://bd.linkedin.com/company/kalyar-replica-ltd-kalyar-packaging-ltd'} target={'_blank'}>
                                            <svg id="Component_167_4" data-name="Component 167 – 4" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4" transform="translate(16 16)" fill="#180e81" opacity="0"/>
                                                <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81" stroke-width="0.5">
                                                    <circle cx="20" cy="20" r="20" stroke="none"/>
                                                    <circle cx="20" cy="20" r="19.75" fill="none"/>
                                                </g>
                                                <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M2.686,12H.2V3.989H2.686ZM1.441,2.9A1.448,1.448,0,1,1,2.882,1.442,1.453,1.453,0,0,1,1.441,2.9ZM12,12H9.515V8.1c0-.929-.019-2.121-1.293-2.121-1.293,0-1.492,1.01-1.492,2.054V12H4.244V3.989H6.631V5.082h.035A2.614,2.614,0,0,1,9.019,3.788C11.537,3.788,12,5.446,12,7.6V12Z" transform="translate(14 13)" fill="#180e81"/>
                                            </svg>
                                        </a>

                                    </div>
                                    <div className={'social hover'}>
                                        <a href={'https://www.youtube.com/@KalyarReplicaPackagingLt-eb3wh'} target={"_blank"}>
                                            <svg id="Component_93_10" data-name="Component 93 – 10" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4" transform="translate(16 16)" fill="#180e81" opacity="0"/>
                                                <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81" stroke-width="0.5">
                                                    <circle cx="20" cy="20" r="20" stroke="none"/>
                                                    <circle cx="20" cy="20" r="19.75" fill="none"/>
                                                </g>
                                                <path id="Path_7836" data-name="Path 7836" d="M1149.075,113.609a3.292,3.292,0,0,0-3.292-3.292h-8.417a3.291,3.291,0,0,0-3.291,3.292v3.917a3.291,3.291,0,0,0,3.291,3.292h8.417a3.292,3.292,0,0,0,3.292-3.292Zm-4.95,2.252-3.775,1.867c-.148.08-.65-.027-.65-.2V113.7c0-.17.507-.277.655-.193l3.613,1.966C1144.119,115.559,1144.278,115.778,1144.125,115.861Z" transform="translate(-1121.075 -95.317)" fill="#180e81"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                :
                                ''
                            }
                            <p>© {currentYear} Kalyar All Rights Reserved.</p>
                            {window.innerWidth > 767 ?
                                <div className={'footer-social'}>
                                    <div className={'social hover'}>
                                        <a href={'https://www.facebook.com/kalyarreplica/'} target={'_blank'}>
                                            <svg id="Component_93_13" data-name="Component 93 – 13" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4" transform="translate(16 16)" fill="#180e81" opacity="0"/>
                                                <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81" stroke-width="0.5">
                                                    <circle cx="20" cy="20" r="20" stroke="none"/>
                                                    <circle cx="20" cy="20" r="19.75" fill="none"/>
                                                </g>
                                                <path id="Path_2115" data-name="Path 2115" d="M1206.295,104.537l.416-2.715h-2.6V100.06a1.357,1.357,0,0,1,1.53-1.466h1.185V96.283a14.438,14.438,0,0,0-2.1-.184,3.314,3.314,0,0,0-3.547,3.654v2.069h-2.385v2.715h2.385V111.1h2.935v-6.562Z" transform="translate(-1182.787 -84.1)" fill="#180e81"/>
                                            </svg>
                                        </a>

                                    </div>
                                    <div className={'social hover'}>
                                        <a href={'https://bd.linkedin.com/company/kalyar-replica-ltd-kalyar-packaging-ltd'} target={"_blank"}>
                                            <svg id="Component_167_4" data-name="Component 167 – 4" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4" transform="translate(16 16)" fill="#180e81" opacity="0"/>
                                                <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81" stroke-width="0.5">
                                                    <circle cx="20" cy="20" r="20" stroke="none"/>
                                                    <circle cx="20" cy="20" r="19.75" fill="none"/>
                                                </g>
                                                <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M2.686,12H.2V3.989H2.686ZM1.441,2.9A1.448,1.448,0,1,1,2.882,1.442,1.453,1.453,0,0,1,1.441,2.9ZM12,12H9.515V8.1c0-.929-.019-2.121-1.293-2.121-1.293,0-1.492,1.01-1.492,2.054V12H4.244V3.989H6.631V5.082h.035A2.614,2.614,0,0,1,9.019,3.788C11.537,3.788,12,5.446,12,7.6V12Z" transform="translate(14 13)" fill="#180e81"/>
                                            </svg>

                                        </a>
                                    </div>
                                    <div className={'social hover'}>
                                        <a href={'https://www.youtube.com/@KalyarReplicaPackagingLt-eb3wh'} target={'_blank'}>
                                            <svg id="Component_93_10" data-name="Component 93 – 10" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4" transform="translate(16 16)" fill="#180e81" opacity="0"/>
                                                <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81" stroke-width="0.5">
                                                    <circle cx="20" cy="20" r="20" stroke="none"/>
                                                    <circle cx="20" cy="20" r="19.75" fill="none"/>
                                                </g>
                                                <path id="Path_7836" data-name="Path 7836" d="M1149.075,113.609a3.292,3.292,0,0,0-3.292-3.292h-8.417a3.291,3.291,0,0,0-3.291,3.292v3.917a3.291,3.291,0,0,0,3.291,3.292h8.417a3.292,3.292,0,0,0,3.292-3.292Zm-4.95,2.252-3.775,1.867c-.148.08-.65-.027-.65-.2V113.7c0-.17.507-.277.655-.193l3.613,1.966C1144.119,115.559,1144.278,115.778,1144.125,115.861Z" transform="translate(-1121.075 -95.317)" fill="#180e81"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            :
                                ''
                            }
                            <div className={'footer-down-right'}>
                                <p>Designed & Developed by <a href={'https://dcastalia.com/'} target={'_blank'}>Dcastalia</a></p>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding: 60px 0 50px 0;

  .footer-top {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(121, 121, 151, 0.5);
    margin-bottom: 20px;
  }

  .footer-down {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: rgb(24 21 58 / 80%);
      
      a{
        color: rgba(38, 38, 48, 0.5);
      }
    }

    .footer-social {
      display: flex;
      gap: 15px;
    }
    .footer-down-right{
      p{
        &:hover{
          a{
            color:rgb(24, 21, 58) !important;
          }
        }
      }
    }
    
    .social{
      cursor: pointer;
      &:hover{
        svg{
          position: relative;
          z-index: 2;
          path{
            fill: #ffffff;
          }
        }
      }
    }
  }
  @media(max-width: 767px){
    .footer-down{
      flex-direction: column;
      gap:10px;
    }
  }
`;

export default MyComponent;
