import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/sustainability/Overview";
import TextWithImage from "../../components/sustainability/TextWithImage";
import List from "../../components/List";
import SubTitle from "../../components/SubTitle";
import {Container} from "react-bootstrap";
import RelatedSus from "../../components/sustainability/RelatedSus";
import {motion} from "framer-motion"
import {PageAnimation} from "../../components/PageAnimation";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchSustainabilityDetail} from "../../api/redux/Sustainability";

const MyComponent = () => {

    const location = useLocation();
    const dispatch = useDispatch()
    const getData = useSelector(store => store.sustainReducer)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SUSTAIN_DETAILS
        dispatch(fetchSustainabilityDetail([api_url + `/${slug}`]))
    }, [location])


    //refactor
    const getPost = getData?.detail?.[0];
    const banner = getPost?.innerBanner;
    const overview = getPost?.overview;
    const sustain = getPost?.sustain;
    const moving = getPost?.moving;
    const recommended = getPost?.recomemneded;


    return (



        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${getPost?.meta?.meta_title ? getPost?.meta?.meta_title  : banner?.title + " | " + "Sustainability"}`}</title>
                {
                    getPost?.meta?.meta_desc &&
                    <meta name="description" content={getPost?.meta?.meta_desc}/>

                }
                {
                    getPost?.meta?.og_title &&
                    <meta property="og:title" content={getPost?.meta?.og_title}/>

                }
                {
                    getPost?.meta?.og_desc &&
                    <meta property="og:description" content={getPost?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={banner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    title={banner?.title}
                    img={banner?.image}
                    subtitle={banner?.subtitle}
                />
                <Overview data={overview}/>
                <TextWithImage data={ sustain}/>
                <div  className={'pt-150 moving-forward'}>
                   <Container>
                       <SubTitle text={moving?.subtitle} color={'rgb(24 21 58 / 80%)'} borderColor={'rgb(24 21 58 / 80%)'}/>
                       <List  title={moving?.title} data={moving?.list}/>
                   </Container>
                </div>
                <RelatedSus data={recommended}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
//.moving-forward{
//  background: #F9F9F9;
//}
`;

export default MyComponent;
