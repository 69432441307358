import React, {useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {button} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {

    const tabData =  data?.feature;


    const [activeTab, setActiveTab] = useState(1);
    const [selectedTab, setSelectedTab] = useState('null')
    const handleTabClick = (index) => {
        setActiveTab(index);
        setSelectedTab(tabData[index - 1])
    };

    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <Container>
                <h3>{reactHtmlParser(data?.title)}</h3>
                <Row>
                    <Col md={12} className={'tab-btn'}>
                        <div className={'tab'}>
                            {
                                tabData?.map((e, i)=>{
                                    return(
                                        <div
                                            className={`tab__single-box ${activeTab === i+1 ? 'active' : ''}`}
                                            key={i}
                                            onClick={() => handleTabClick(i+1)}
                                        >
                                            <div>
                                                <div className="tab__single-box__inner">
                                                    {/*{reactHtmlParser(e?.icon)}*/}
                                                    <img className={'normal-image'} src={e?.icon}/>
                                                    <img className={'hover-image'} src={e?.icon_hover}/>
                                                </div>
                                            </div>
                                            <p>{e?.title}</p>
                                        </div>
                                    );
                                })
                            }

                        </div>
                    </Col>
                    <Col md={12}>
                        <div className={'tab-content'}>

                                    <h4>{selectedTab?.title ? selectedTab?.title : tabData?.[0]?.title}</h4>
                                    <p>{selectedTab?.description ? selectedTab?.description : tabData?.[0]?.description}</p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F9F9F9;
  h3{
    color: #180E81;
    margin-bottom: 60px;
  }

  .tab-btn{
    width: 100%;
    padding-top: 30px;
    .tab{
      display: flex;
      gap: 30px;
      justify-content: flex-start;
      overflow-x: scroll;
      padding-bottom: 50px;
      ::-webkit-scrollbar {
        width: 150px !important;
        height: 5px;

      }
      ::-webkit-scrollbar-track {
        background: #F9F9F9;

      }
      ::-webkit-scrollbar-thumb {
        background: #180E81;
        border-radius:3px;
      }
      &__single-box{
        p{
          width: 150px;
          margin-top: 15px;
          color: #000000;
          text-align: center;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          font-family: ${button};
        }
        &__inner{
          //display: flex;
          //justify-content: center;
          //padding: 50px;

          .hover-image {
            opacity: 0;
            transition: opacity 0.5s ease;
          }

          .normal-image {
            opacity: 1;
            transition: opacity 0.5s ease;
          }

          border: 0.5px solid #180E81;
          transition: 0.3s all ease-in-out;
          position: relative;
          padding-top: calc(150 / 150 * 100%);
          img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &:hover{
          .normal-image {
            opacity: 0;
            transition: opacity 0.5s ease;
          }

          .hover-image {
            opacity: 1;
            transition: opacity 0.5s ease;
          }
          
          .tab__single-box__inner{
            transition: 0.3s all ease-in-out;
            background-color: #180E81 !important;
            svg{
              path{
                fill: #ffffff;
              }
            }

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      
      .active {
        .tab__single-box__inner {
          background-color: #180E81 !important;

          .hover-image {
            opacity: 1;
            transition: opacity 0.5s ease;
          }
        }
      }
    }

    @media(max-width: 767px){
      padding-top: 0;
      .tab{
        overflow-x: scroll;
        padding-bottom: 30px;
        ::-webkit-scrollbar {
          width: 150px !important;
          height: 5px;

        }
        ::-webkit-scrollbar-track {
          background: #F9F9F9;

        }
        ::-webkit-scrollbar-thumb {
          background: #180E81;
          border-radius:3px;
        }

        &__single-box{
          padding-right: 15px;
          p{
            width: 105px;
          }
          &__inner{
            padding-top: calc(105 / 105 * 100%);
            svg{
              max-height: 40px;
              max-width: 40px;
            }
          }
        }
      }
    }
  }

  .tab-content{
    padding: 30px 60px 60px;
    background-color: #FFFFFF;
    h4{
      color: #262630;
    }
    p{
      margin-top: 40px;
      font-family: ${button};
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      width: 85%;
      color: rgb(38 38 48);
    }
    @media(max-width: 767px){
      padding: 40px 15px 60px;
      p{
        width: 100%;
      }
    }
  }
`;

export default MyComponent;
