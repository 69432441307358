import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import SubTitle from "../SubTitle";
import {Img} from "../Img";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-150 pb-120'}>
            <Container className={'machine'}>
                <SubTitle text={reactHtmlParser(data?.subtitle)} color={'rgb(24 21 58 / 80%)'} borderColor={'rgb(24 21 58 / 80%)'}/>
                <h3>{reactHtmlParser(data?.title)}</h3>
                <Row>
                    {
                        data?.machineries_all &&
                        data?.machineries_all?.length >0 &&
                        data?.machineries_all?.map((e,i)=>{
                            return(
                                <Col md={4} className={'machine__single'} key={i}>
                                    <div className={'machine__single__img'}>
                                        <Img src={e?.image}/>
                                    </div>
                                    <p>{reactHtmlParser(e?.name)}</p>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .machine {
    h3 {
      color: #262630;
      margin-bottom: 60px;
    }

    &__single {
      margin-bottom: 40px;

      &__img {
        position: relative;
        padding-top: calc(370 / 370 * 100%);
        margin-bottom: 20px;
        overflow: hidden;
        img{
          transition: all 0.5s ease;
        }

        &:hover {
          img {
            transform: scale(1.05);
            transition: all 0.5s ease;
          }
        }
      }

      p {
        font-family: ${button};
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color: #262630;
      }
    }
  }
`;

export default MyComponent;
