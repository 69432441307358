import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import TruncateText from "../TruncateText";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const paragraphText = "We focus on niche segments of the packaging industry, defined by markets and products. We offer premium products and complete solutions for our customers. Our product range is constantly expanding: together with our customers we develop new packaging that not only meets but also exceeds the needs and requirements of the end consumer. We focus on niche segments of the packaging industry, defined by markets and products. We offer premium products and complete solutions for our customers. Our product range is constantly expanding: together with our customers we develop new packaging that not only meets but also exceeds the needs and requirements of the end consumer. "

    return (
        <StyledComponent className={'pb-150 pt-150 reveal-up'}>
            <Container>
                <Row>
                    <Col md={12}>
                        <h3>{reactHtmlParser(data?.title)}</h3>
                        <TruncateText longText={reactHtmlParser(data?.description)} maxLines={3}/>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #FFFFFF;
  h3{
    color: #262630;
    margin-bottom: 40px;
    width: 85%;
  }

  p{
    width: 85%;
  }

  .counters{
    display: flex;
    &__single{
      min-width: 270px;
      margin-right: 65px;
      h5{
        font-family: ${button};
        font-size: 48px !important;
        line-height: 62px;
        font-weight: 300;
        color: rgb(38 38 48 / 70%);
        margin-bottom: 20px;
        border-bottom: 1px solid rgb(121 121 151 / 60%);
      }
      p{
        color: rgb(0 0 0 / 60%);
        font-family: ${button};
        font-size: 16px !important;
        line-height: 24px;
        font-weight: 400;
      }
    }
    @media(max-width: 767px){
      display: block;
      &__single{
        margin-bottom: 65px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default MyComponent;
