import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import gsap from 'gsap';
import reactHtmlParser from 'react-html-parser';

const TruncateText = ({ longText, color }) => {
    const [showFullText, setShowFullText] = useState(false);
    const [readMoreAnimation, setReadMoreAnimation] = useState(null);
    const [readLessAnimation, setReadLessAnimation] = useState(null);

    const textRef = useRef(null);
    const btnRef = useRef(null);

    const toggleTextDisplay = () => {
        setShowFullText(!showFullText);
    };

    const toggleReadMoreAnimation = () => {
        if (readMoreAnimation) {
            readMoreAnimation.kill();
        }

        const timeline = gsap.timeline();

        timeline.to(btnRef.current, {
            duration: 0.3,
            rotate: 180,
        }).add('rotate');

        const textHeight = textRef.current.clientHeight;
        const duration = showFullText ? 0.3 : 0.5;

        timeline.from(
            textRef.current,
            {
                height: showFullText ? 'auto' : textHeight,
                duration: duration,
                ease: 'power2.out',
            },
            'rotate'
        ).to(
            textRef.current,
            {
                height: showFullText ? textHeight : 'auto',
                duration: duration,
                ease: 'power2.out',
            },
            'rotate'
        );

        setReadMoreAnimation(timeline);
    };

    const toggleReadLessAnimation = () => {
        if (readLessAnimation) {
            readLessAnimation.kill();
        }

        const timeline = gsap.timeline();

        const textHeight = textRef.current.clientHeight;
        const duration = showFullText ? 0.5 : 0.7;

        timeline.from(
            textRef.current,
            {
                height: showFullText ? textHeight : 'auto',
                duration: duration,
                ease: 'power2.in',
            },
            'rotate'
        ).to(
            textRef.current,
            {
                height: showFullText ? 'auto' : textHeight,
                duration: duration,
                ease: 'power2.out',
            },
            'rotate'
        );

        timeline.from(
            btnRef.current,
            {
                duration: 0.7,
                rotate: 0,
            },
            'rotate'
        );

        setReadLessAnimation(timeline);
    };

    const handleToggle = () => {
        toggleTextDisplay();

        if (showFullText) {
            toggleReadLessAnimation();
        } else {
            toggleReadMoreAnimation();
        }
    };

    const truncatedText = longText.slice(0, 750) + '...';
    const displayText = showFullText ? longText : truncatedText;

    return (
        <StyledComponent>
            <div className="fade-up" color={color}>
                <p
                    className={` fade-up ${showFullText ? 'show-full-text' : 'truncate-text'}`}
                    onClick={toggleTextDisplay}
                    ref={textRef}
                >
                    {reactHtmlParser(displayText)}
                </p>
                {longText.length > 750 && (
                    <span className={'display'} onClick={handleToggle}>
            {showFullText ? (
                <div className="video-box">
                    <div className="hover-btn hover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="2" viewBox="0 0 12 2">
                            <g id="Group_19000" data-name="Group 19000" transform="translate(-645.5 -6889.5)">
                                <line
                                    id="Line_12372"
                                    data-name="Line 12372"
                                    y2="10"
                                    transform="translate(656.5 6890.5) rotate(90)"
                                    fill="none"
                                    stroke="#2A2627"
                                    stroke-linecap="round"
                                    stroke-width="2"
                                />
                                <line
                                    id="Line_12373"
                                    data-name="Line 12373"
                                    y2="10"
                                    transform="translate(656.5 6890.5) rotate(90)"
                                    fill="none"
                                    stroke="#2A2627"
                                    stroke-linecap="round"
                                    stroke-width="2"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
            ) : (
                <div className="video-box">
                    <div className="hover-btn hover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                            <g id="Group_19016" data-name="Group 19016" transform="translate(-645.5 -6884.5)">
                                <line
                                    id="Line_12372"
                                    data-name="Line 12372"
                                    y2="10"
                                    transform="translate(651.5 6885.5)"
                                    fill="none"
                                    stroke="#2A2627"
                                    stroke-linecap="round"
                                    stroke-width="2"
                                />
                                <line
                                    id="Line_12373"
                                    data-name="Line 12373"
                                    y2="10"
                                    transform="translate(656.5 6890.5) rotate(90)"
                                    fill="none"
                                    stroke="#2A2627"
                                    stroke-linecap="round"
                                    stroke-width="2"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
            )}
          </span>
                )}
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  p {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: ${(props) => (props.color ? props.color : 'rgb(38 38 48)')};
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  .video-box {
    position: relative;
    cursor: pointer;
    top: 30px;
    width: fit-content;

    .hover-btn {
      height: 40px;
      width: 40px;
      background-color: #e9e9e9;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;

      &.hover:after {
        border-radius: 50%;
      }

      svg {
        position: relative;
        z-index: 2;
      }

      path {
        transition: all 0.3s ease;
      }

      g {
        line {
          stroke: #2a2627;
        }
      }
    }

    &:hover {
      .hover-btn {
        &:after {
          height: 100%;
          width: 100%;
        }

        path {
          fill: #fff;
        }
        g {
          line {
            stroke: #ffffff;
          }
        }
      }
    }
    @media (max-width: 768px) {
      top: 0 !important;
      padding-bottom: 60px;
    }
  }

  // Hide SVG buttons when text length is not greater than 750
  ${(props) => props.textLength <= 750 && '.video-box { display: none; }'};
`;

export default TruncateText;
