import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {button} from "../../styles/globalStyleVars";
import {Link, useLocation} from "react-router-dom";

const MyComponent = ({data}) => {
    const location = useLocation()

    const url = location.pathname.split('/')[1];

    return (
        <StyledComponent className={'reveal-up'}>
            <Container>
                <Row className={'nav'}>
                    <Col md={url==='kalyar-replica' ? 5 : 7} className={'nav__single p-0'}>
                        <Link to={`#converting`}>
                            <h4>
                                Converting
                            </h4>
                        </Link>
                    </Col>
                    <Col md={2} className={'nav__single p-0'}>
                        <Link to={`#print`}>
                            <h4>
                                Printing
                            </h4>
                        </Link>
                    </Col>
                    <Col md={url==='kalyar-replica' ? 5 : 3} className={'nav__single p-0'}>
                        <Link to={`#finishing`}>
                            <h4>
                                Finishing
                            </h4>
                        </Link>
                    </Col>
                    <Col md={12} className={'box'}>
                        <div className={'nav-box'}>
                            <div className={'nav-box__single'}>
                                <Link to={`#${data?.converting?.list[0]?.button}`}>
                                    <div className={'nav-box__single__wrap'}>
                                        <p>{data?.converting?.list[0]?.title}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className={'nav-box__single'}>
                                <Link to={`#${data?.converting?.list[1]?.button}`}>
                                    <div className={'nav-box__single__wrap'}>
                                        <p>{data?.converting?.list[1]?.title}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className={'nav-box__single'}>
                                <Link to={`#${data?.converting?.list[2]?.button}`}>
                                    <div className={'nav-box__single__wrap'}>
                                        <p>{data?.converting?.list[2]?.title}</p>
                                    </div>
                                </Link>
                            </div>

                            {
                                url == 'kalyar-packaging' &&
                                <div className={'nav-box__single'}>
                                    <Link to={`#${data?.converting?.list[3]?.button}`}>
                                        <div className={'nav-box__single__wrap'}>
                                            <p>{data?.converting?.list[3]?.title}</p>
                                        </div>
                                    </Link>
                                </div>
                            }


                            <div className={'nav-box__single'}>
                                <Link to={`#${data?.printing?.list[0]?.button}`}>
                                    <div className={'nav-box__single__wrap'}>
                                        <p>{data?.printing?.list[0]?.title}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className={'nav-box__single'}>
                                <Link to={`#${data?.finishing?.list[0]?.button}`}>
                                    <div className={'nav-box__single__wrap'}>
                                        <p>{data?.finishing?.list[0]?.title}</p>
                                    </div>
                                </Link>
                            </div>
                            <div className={'nav-box__single'}>
                                <Link to={`#${data?.finishing?.list[1]?.button}`}>
                                    <div className={'nav-box__single__wrap'}>
                                        <p>{data?.finishing?.list[1]?.title}</p>
                                    </div>
                                </Link>
                            </div>
                            {
                                url == 'kalyar-replica' &&
                                <div className={'nav-box__single'}>
                                    <Link to={`#${data?.finishing?.list[2]?.button}`}>
                                        <div className={'nav-box__single__wrap'}>
                                            <p>{data?.finishing?.list[2]?.title}</p>
                                        </div>
                                    </Link>
                                </div>
                            }

                        </div>
                    </Col>
                </Row>


            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 80px;
  position: relative;

  .nav {
    
    &__single {
      background-color: #EBEBEB;
      text-align: center;
      min-height: 70px;

      &:nth-child(2) {
        background-color: #DBDBDB;
      }

      &:nth-child(3) {
        background-color: #C8C8C8 !important;
        clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%) !important;
      }

      h4 {
        color: #262630;
        padding-top: 25px;
        padding-bottom: 21px;
      }
    }
    @media(max-width: 767px){
      padding: 0 15px;
      &__single{
        margin-bottom: 10px;
        &:nth-child(3){
          margin-bottom: 0;
          clip-path: unset !important;
        }
      }
    }
  }

  .box {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 60px;
    @media(max-width: 767px){
      display: none;
      opacity: 0;
    }
  }

  .nav-box {
    width: 100%;
    display: flex;
    gap: 30px;

    &__single {
      text-align: center;
      position: relative;
      width: 100%;

      &__wrap {
        position: absolute;
        padding-top: calc(75 / 141 * 100%);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0.5px solid #707070;

        p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: ${button};
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #262630;
        }
      }

      &:hover{
        .nav-box__single__wrap {
          background-color: #180E81;
          p{
            color: #FFFFFF;
          }
        }
      }
    }
  }
`;

export default MyComponent;
