import React, {useEffect} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import ReactHtmlParser from "react-html-parser";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import List from "../List";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
const MyComponent = ({id, padding,data}) => {


const Listdata = data?.audits;
    const NewsPrevGalleryAward = () => {
        document.querySelector('.award_section .swiper-button-prev').click();
    };

    const NewsNextGalleryAward = () => {
        document.querySelector('.award_section .swiper-button-next').click();
    };


    useEffect(() => {
        const handlePrint = (event) => {
            if ((event.ctrlKey || event.metaKey) && (event.key === 'p' || event.key === 'P')) {
                event.preventDefault();
                // Optionally, you can display a message or take another action
            }
        };

        document.body.addEventListener('keydown', handlePrint);

        return () => {
            document.body.removeEventListener('keydown', handlePrint);
        };
    }, []);


    const renderCertificateContent = (certificate) => (
        <div className="content">
            <LightgalleryProvider
                lightgallerySettings={{
                    download: false,
                    thumbnail: false,
                    fullScreen: true,
                    share: false
                }}
            >
                <LightgalleryItem
                    group={'any'}
                    src={certificate?.certificate}>
                    <img src={certificate?.svg} alt="Certificate"/>
                </LightgalleryItem>
            </LightgalleryProvider>
            <p className={'fade-up'}>
                {reactHtmlParser(certificate?.text)}
            </p>
        </div>
    );

    return (
        <StyledComponent className={`award_section pt-150 pb-150 ${padding ? padding : ''}`} id={`${id ? id : 'Award'}`}>
            <Container className={'awards'}>
                {
                    data?.subtitle &&
                    <Row>
                        <Col md={{span:8, offset:0}} className={'awards__title'}>
                            <div className="content">

                                <div className={'d-flex navigation_wrapper'}>
                                    {
                                        data?.subtitle &&
                                        <h3>{reactHtmlParser(data?.subtitle)}</h3>
                                    }
                                </div>

                            </div>
                        </Col>

                    </Row>
                }

                {/*{*/}
                {/*    data?.all_certificate &&*/}
                {/*    <Col>*/}
                {/*        <Row>*/}
                {/*            {*/}
                {/*                data?.all_certificate &&*/}
                {/*                <Swiper*/}
                {/*                    spaceBetween={0}*/}
                {/*                    slidesPerView={slidesPerViewDesktop}*/}
                {/*                    slideNextClass={'next'}*/}
                {/*                    allowSlideNext={true}*/}
                {/*                    slidePrevClass={'prev'}*/}
                {/*                    allowSlidePrev={true}*/}
                {/*                    allowTouchMove={true}*/}
                {/*                    longSwipesMs={900}*/}
                {/*                    speed={900}*/}
                {/*                    loop={true}*/}
                {/*                    breakpoints={{*/}
                {/*                        320: {*/}
                {/*                            slidesPerView: 1,*/}
                {/*                        },*/}
                {/*                        768: {*/}
                {/*                            slidesPerView: 2,*/}
                {/*                        },*/}
                {/*                        1024: {*/}
                {/*                            slidesPerView: slidesPerViewDesktop,*/}
                {/*                        },*/}
                {/*                    }}*/}
                {/*                    navigation={true} modules={[Navigation]}*/}

                {/*                    // onSwiper={(swiper) => console.log(swiper)}*/}

                {/*                >*/}
                {/*                    {*/}
                {/*                        data?.all_certificate?.length > 0  &&*/}
                {/*                        data?.all_certificate?.map((e,i)=>{*/}
                {/*                            return(*/}
                {/*                                <SwiperSlide key={i} className={'single_testimonial'}>*/}
                {/*                                    <Col md={6} className={'awards__certificates p-0'}>*/}
                {/*                                        <div className="content">*/}
                {/*                                            <LightgalleryProvider*/}
                {/*                                                lightgallerySettings={*/}
                {/*                                                    {*/}
                {/*                                                        download: false,*/}
                {/*                                                        thumbnail: false,*/}
                {/*                                                        fullScreen: true,*/}
                {/*                                                        share: false*/}
                {/*                                                    }*/}
                {/*                                                }*/}
                {/*                                            >*/}
                {/*                                                <LightgalleryItem*/}
                {/*                                                    group={'any'}*/}
                {/*                                                    src={e?.certificate}>*/}
                {/*                                                    <img  src={e?.svg}/>*/}


                {/*                                                </LightgalleryItem>*/}
                {/*                                            </LightgalleryProvider>*/}

                {/*                                            <p className={'fade-up'}>*/}
                {/*                                                {reactHtmlParser(e?.text)}*/}
                {/*                                            </p>*/}
                {/*                                        </div>*/}
                {/*                                    </Col>*/}

                {/*                                </SwiperSlide>*/}
                {/*                            );*/}
                {/*                        })*/}
                {/*                    }*/}
                {/*                </Swiper>*/}
                {/*            }*/}

                {/*        </Row>*/}
                {/*    </Col>*/}
                {/*}*/}
                {data?.all_certificate && (
                    <Row>
                        {data?.all_certificate.length === 1 ? (
                            <Col md={6} className={'awards__certificates'}>
                                {renderCertificateContent(data.all_certificate[0])}
                            </Col>
                        ) : (
                            <Col xs={12}>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={2}
                                    slideNextClass={'next'}
                                    allowSlideNext={true}
                                    slidePrevClass={'prev'}
                                    allowSlidePrev={true}
                                    allowTouchMove={true}
                                    longSwipesMs={900}
                                    speed={900}
                                    loop={true}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        1024: {
                                            slidesPerView: 2,
                                        },
                                    }}
                                    navigation={true}
                                    modules={[Navigation]}
                                >
                                    {data.all_certificate.map((certificate, index) => (
                                        <SwiperSlide key={index} className={'single_testimonial'}>
                                            <Col md={6} className={'awards__certificates p-0'}>
                                                {renderCertificateContent(certificate)}
                                            </Col>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Col>
                        )}
                    </Row>
                )}

                <List data={data?.audits} title={reactHtmlParser(data?.audit_title)} marginTop={'40px'}/>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .minus_padding{
    padding: 0 15px;
  }

  //.swiper-initialized{
  //  border-top: 1px solid rgb(38 38 48 / 70%);
  //  border-bottom: 1px solid rgb(38 38 48 / 70%);
  //  border-right: 1px solid rgb(38 38 48 / 70%);
  //  border-left: 1px solid rgb(38 38 48 / 70%);
  //}
  .swiper-slide-active{
    .awards__certificates{
      border-right: none;
      @media(max-width: 767px){
        //border-right: 1px solid #4F616B !important;

      }
      .content{
        border-right: 1px solid rgb(38 38 48 / 70%);
      }
    }
  }
  .awards{
    &__top{
      .content{
        padding-bottom: 70px;

      }
    }
    &__title{
      max-width: 100%;
      flex: 0 0 100%;
      .content{
        width: 100%;
        padding-bottom: 70px;
       
      }
    }
    &__certificates{
        border-top: 1px solid rgb(38 38 48 / 70%);
        border-bottom: 1px solid rgb(38 38 48 / 70%);
        border-right: 1px solid rgb(38 38 48 / 70%);
        border-left: 1px solid rgb(38 38 48 / 70%);
      width: 100%;
      max-width: 100%;
      height: 100%;
      //border-top: 1px solid #4F616B;
      //border-bottom: 1px solid #4F616B;
      //border-right: 1px solid #4F616B;
      //border-left: 1px solid #4F616B;
      .content {
        height: 100%;
        padding: 40px;
        transition: 0.7s all ease;
        position: relative;
        .react-tooltip{
          top: 40px !important;
          opacity: 0.4;
          font-size: 14px;
        }
        @media (min-width: 1550px) {
          min-height: 384px;
        }
        @media (max-width: 767px) {
          padding: 20px;
        }

        .image_wrapper {
          position: relative;
          min-height: 200px;

          img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            object-fit: contain;
          }
        }


        p {
          font-family: ${button};
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #4F616B;
        }

        img {
          height: 160px;
          padding-bottom: 40px;
          cursor: pointer;
        }
      }

      //&:nth-child(2n+1) {
      //  .content{
      //
      //    border-left: 1px solid #4F616B;
      //    border-right: none;
      //  }
      //}
      //&:nth-child(2n){
      //  .content{
      //
      //    border-left: 1px solid #4F616B;
      //  }
      //}
      @media(max-width: 767px){
        //.content{
        //  padding: 40px 15px;
        //  border-left: 1px solid #4F616B !important;
        //  border-right: 1px solid #4F616B !important;
        //  
        //}
        //&:nth-child(2n+1) {
        //  .content{
        //
        //    border-bottom: none;
        //  }
        //}
      }
        &:nth-child(2){
            border-left: none;
        }
    }
    @media(max-width: 767px){
      &__top{
        .content{

          padding-bottom: 40px;
        }
      }
      &__title{
        .content{

          padding-bottom: 40px;
        }
      }
    }
  }
  
 
  
  
  @media(max-width: 767px){
    .awards__top .content{
      padding-bottom: 0;
    }
    .title{
      h2{
        font-size: 32px;
        font-weight: 500;
      }
    }
  }



  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 0px;
    
    h3{
      color: #262630;
    }

    @media (max-width: 767px) {
      margin-bottom: 40px;
      flex-direction: column;
    }


    .d-flex {
      justify-content: space-between;
    }

    .navigation {
      display: flex;

      li {
        cursor: pointer;

        &:first-child {
          margin-right: 30px;
        }

        position: relative;
        border-radius: 50%;
        height: 52px;
        width: 52px;

        svg {
          position: relative;

          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 26px;
            }
          }
        }
      }
    }
  }
  
  
  
  
`;

export default MyComponent;
