import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import JobList from "../../components/career/JobList";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchMediaDetail, fetchMediaList} from "../../api/redux/Media";
import {fetchCareer, fetchCareerDetail} from "../../api/redux/Career";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion"

const MyComponent = () => {
    const dispatch = useDispatch()
    const [selectDepartment, setSelectDepartment] = useState('')
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CAREER;
        let api_params = {
            [ApiParam.DEPARTMENT]: selectDepartment?.value,
        };
        dispatch(fetchCareer([api_url, api_params]));
    }, [selectDepartment]);

    // api call
    useEffect(() => {
        let api_url2 = apiEndPoints.CAREER_DETAILS;
        dispatch(fetchCareerDetail([api_url2]));
    }, []);


    let getPost = useSelector(state => state.careerReducer);
    const jobsList = getPost?.posts?.[0]?.list
    const filter = getPost?.posts?.[0]?.filter
    let data = getPost?.posts?.[0];
    let innerBanner = data?.banner;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.page_meta?.meta_title ? data?.page_meta?.meta_title + ' | ' + 'Kalyar Replica Limited' : ''}`}</title>
                {
                    data?.page_meta?.meta_desc &&
                    <meta name="description" content={data?.page_meta?.meta_desc}/>

                }
                {
                    data?.page_meta?.og_title &&
                    <meta property="og:title" content={data?.page_meta?.og_title}/>

                }
                {
                    data?.page_meta?.og_desc &&
                    <meta property="og:description" content={data?.page_meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>

                    <InnerBanner
                        img={innerBanner?.image}
                        title={innerBanner?.title}
                        subtitle={innerBanner?.subtitle}
                    />
                <JobList data={jobsList} filter={filter} setSelectDepartment={setSelectDepartment} />
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
