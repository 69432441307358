import React from 'react';
import styled from "styled-components";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {BsChevronDown} from "react-icons/bs";
import VisibilitySensor from "react-visibility-sensor";
import {Img} from "../Img";
import {button} from "../../styles/globalStyleVars";

const Address = ({data, callContactForm, loading, formData, formDataFailed, clearContact}) => {

    return (
        <StyledAddress className='address reveal-up pt-150 pb-150'>
            <Container>
                <Row className='address__row'>
                    <VisibilitySensor offset={{top: -150}}>
                        {({isVisible}) =>
                            <Col className={` fade-up address__accordion`} sm={12}>
                                <Accordion defaultActiveKey={0}>
                                    {data && data?.length>0 &&
                                        data?.map((e,i)=>{
                                            const phoneNumbers = e?.phone; // Get the phone property from e
                                            const email = e?.email; // Get the email property from e
                                            const fax = e?.fax;
                                            const arrayOfNumbers = phoneNumbers ? phoneNumbers?.split(', ') : '';
                                            const arrayOfEmails = email ? email?.split(', ') : '';
                                            const arrayOfFax = fax ? fax?.split(', ') : '';


                                            // Define the images for different viewports
                                            const largeImage = e?.image?.large;
                                            const mediumImage = e?.image?.medium;
                                            const smallImage = e?.image?.thumbnail;

                                            // Determine the viewport size and set the image accordingly
                                            let imgUrl;
                                            if (window.innerWidth >= 1366) {
                                                imgUrl = largeImage;
                                            } else if (window.innerWidth >= 768) {
                                                imgUrl = mediumImage;
                                            } else {
                                                imgUrl = smallImage;
                                            }

                                            return(
                                                <Accordion.Item key={i} eventKey={i}>
                                                    <Accordion.Header>
                                                        {reactHtmlParser(e?.name)}
                                                        <span><BsChevronDown/></span>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="contact-numbers d-flex">

                                                            <Col className="pl-0" sm={3}><h4>Address</h4>
                                                                <ul>
                                                                    <li><a href={e?.url}>{reactHtmlParser(e?.address)}</a></li>

                                                                </ul>
                                                            </Col>

                                                            <Col className="pl-0" sm={{offset:1,span:2}}><h4>Phone</h4>
                                                                <ul>
                                                                    {
                                                                        arrayOfNumbers && arrayOfNumbers?.length > 0 &&
                                                                        arrayOfNumbers?.map((e) => {
                                                                            return(
                                                                                <li><a className={''} href={`tel:${e}`}>{e}</a></li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </Col>
                                                            <Col className={'pl-0'} sm={{offset:1,span:2}}><h4>Fax</h4>
                                                                <ul>
                                                                    {
                                                                        arrayOfFax && arrayOfFax?.length > 0 &&
                                                                        arrayOfFax?.map((e) => {
                                                                            return(
                                                                                <li><a className={''} href={`fax:${e}`}>{e}</a></li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </Col>
                                                            <Col className={'pl-0'} sm={{offset:1,span:2}}><h4>Email</h4>
                                                                <ul>
                                                                    {
                                                                        arrayOfEmails && arrayOfEmails?.length > 0 &&
                                                                        arrayOfEmails?.map((e) => {
                                                                            return(
                                                                                <li><a className={''} href={`mailto:${e}`}>{e}</a></li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </Col>
                                                        </div>
                                                        <div className="map">
                                                            <a href={e?.url} target={'_blank'}>
                                                                <div className={'map-body'}>
                                                                    <Img src={imgUrl} alt={'map'}/>
                                                                </div>
                                                            </a>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            );
                                        })
                                    }
                                </Accordion>
                            </Col>
                        }
                    </VisibilitySensor>
                </Row>
            </Container>
        </StyledAddress>
    );
};
const StyledAddress = styled.section`
  .accordion-item {
    margin-bottom: 60px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: #180E81;
      font-size: 24px;
      font-weight: 300;
      line-height: 32px;
      text-transform: capitalize;
      padding-bottom: 15px;
      border-bottom: 1px solid #180E81;
      width: 100%;
      text-align: left;
      //margin-bottom: 50px;
      transition: all .4s ease;

      &.collapsed {
        color: #1A1818;
        border-color: #1A1818;

        span {
          background-color: black;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: #180E81 !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #ffffff;
      background-color: #180E81;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: #180E81;
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: #180E81;
        border-color: #180E81;
      }
    }
  }

  .accordion-body {
    padding-top: 40px;

    p {
      width: 70%;
    }

    p, a {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
    }

    .contact-numbers {

      h4 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: rgba(34, 31, 31, 0.2);
        margin: 0 0 10px 0;
        font-family: ${button};
      }

      ul {
        li {
          a {
            cursor: pointer;
            margin-bottom: 13px;
            display: flex;
            color: #262630;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;

            span {
              font-size: 26px;
              font-weight: bold;
              line-height: 32px;
              transition: .3s ease;
            }

            &:hover {
              color: #180E81;
            }
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
    }

    .map {
      margin-top: 45px;
      
      .map-body{
        position: relative;
        padding-top: calc(450 / 1170 * 100%);
        
        @media(max-width: 767px){
          padding-top: calc(210 / 345 * 100%);
        }
      }

      iframe {
        width: 100%;
        height: 350px;
      }
    }
  }


  .address__form {

    .form-control {
      font-size: 16px;
      line-height: 22px;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid black;
      border-radius: 0;
      padding-left: 0;
      padding-bottom: 20px;
      margin-bottom: 40px;

      &::placeholder {
        color: rgba(34, 31, 31, 0.5) !important;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
      }
    }

    textarea {
      min-height: 90px;
      max-height: 90px;
      min-width: 100%;
      max-width: 100%;
      margin-bottom: 60px;
    }

    .divider {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .form-control {
        width: calc(50% - 15px);
      }
    }

    &__title {
      margin-bottom: 60px;

      h5 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
        font-weight: 600;
      }

      p {
        font-size: 26px;
        font-weight: bold;
        line-height: 32px;
      }
    }
  }

  @media (max-width: 768px) {
    .address__accordion {
      min-width: 100%;
    }

    .address__form {
      min-width: 100%;
      margin-top: 60px;
    }
  }

  @media (max-width: 767px) {

    .address__row {
      flex-direction: column-reverse;
    }

    .address__form {
      margin-top: 0;
      margin-bottom: 60px;
    }

    .contact-numbers {
      flex-wrap: wrap;

      .col-sm-4 {
        min-width: 50%;

        &:nth-last-child(1) {
          margin-top: 30px;
          padding-left: 0;
        }
      }
    }
  }
  
  a{
    &:hover{
      color: #180E81;
    }
  }

`;


export default Address;