import {ScrollTrigger} from "gsap/ScrollTrigger";
import {CSSPlugin, gsap} from "gsap";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

export const SectionReveal = () => {
    gsap.registerPlugin(ScrollTrigger, CSSPlugin);
    const location = useLocation();
    let getPost = useSelector((state) => state);
    const isDesktop = window.innerWidth > 991;
    // ScrollTrigger.refresh();


        useEffect(() => {
            // Set initial properties
            if (isDesktop) {
            // Set initial properties
            gsap.set('.reveal-up-banner', {y: '30%'});

            // Animation
            gsap.fromTo(
                '.reveal-up-banner',
                {y: '30%'},
                {
                    delay: 1.4,
                    y: '0%',
                    duration: 1.4, // Adjust the duration as needed
                    ease: 'power3.out', // Experiment with different easing functions
                }
            );


            gsap.set('.reveal-up', {y: '50%'});

            // Animation
            gsap.fromTo(
                '.reveal-up',
                {y: '50%'},
                {
                    delay: 1.4,
                    y: '0%',
                    duration: 1.5, // Adjust the duration as needed
                    ease: 'power3.out', // Experiment with different easing functions
                }
            );

            gsap.set('.reveal-up-inner', {y: '50%'});

            // Animation
            gsap.fromTo(
                '.reveal-up-inner',
                {y: '50%'},
                {
                    delay: 1.4,
                    y: '0',
                    duration: 1.5, // Adjust the duration as needed
                    ease: 'power3.out', // Experiment with different easing functions
                }
            );


            // Set initial properties
            gsap.set('.reveal-down', {y: '-50%'});

            // Animation
            gsap.fromTo(
                '.reveal-down',
                {y: '-120%'},
                {
                    delay: 2.5,
                    y: '0%',
                    duration: 1.5, // Adjust the duration as needed
                    ease: 'power3.out', // Experiment with different easing functions
                }
            );
            }
        }, [location.pathname])


}

/*
how to use:
- add 'split-up' class on the tag like(h1,h2,h3,p ... etc)
*/