import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/Media'
import globalReducer from '../redux/global'
import aboutPackaging from '../redux/AboutPackaging'
import aboutReplica from '../redux/AboutReplica'
import homeReducer from '../redux/Home'
import careerReducer from '../redux/Career'
import contactReducer from '../redux/ContactUs'
import endmarketPackagingReducer from '../redux/EndMarketPackaging'
import endmarketReplicaReducer from '../redux/EndMarketReplica'
import investorReducer from '../redux/Investor'
import keypersonalPackagingReducer from '../redux/KeyPersonnelPackaging'
import keypersonalReplicaReducer from '../redux/KeyPersonnelReplica'
import managementPackagingReducer from '../redux/ManagementPackaging'
import managementReplicaReducer from '../redux/ManagementReplica'
import mediaReducer from '../redux/Media'
import productsPackagingReducer from '../redux/ProductsPackaging'
import productsReplicaReducer from '../redux/ProductsReplica'
import technologyPackagingReducer from '../redux/TechnologyPackaging'
import technologyReplicaReducer from '../redux/TechnologyReplica'
import workingprocessPackagingReducer from '../redux/WorkingProcessPackaging'
import workingprocessReplicaReducer from '../redux/WorkingProcessReplica'
import layerReducer from '../redux/Layer'
import sustainReducer from '../redux/Sustainability'

const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,
        aboutPackaging,
        aboutReplica,
        homeReducer,
        careerReducer,
        contactReducer,
        endmarketPackagingReducer,
        endmarketReplicaReducer,
        investorReducer,
        keypersonalPackagingReducer,
        keypersonalReplicaReducer,
        managementPackagingReducer,
        managementReplicaReducer,
        mediaReducer,
        productsPackagingReducer,
        productsReplicaReducer,
        technologyPackagingReducer,
        technologyReplicaReducer,
        workingprocessPackagingReducer,
        workingprocessReplicaReducer,
        layerReducer,
        sustainReducer










    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

