import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/process/Overview";
import WorkingProcess from "../../components/process/WorkingProcess";
import Machineries from "../../components/process/Machineries";
import QualityAssurance from "../../components/process/QualityAssurance";
import Satisfaction from "../../components/Satisfaction";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion"
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import workingprocessReplicaReducer, {fetchWorkingProcessReplica} from "../../api/redux/WorkingProcessReplica";
import workingprocessPackagingReducer, {fetchWorkingProcessPackaging} from "../../api/redux/WorkingProcessPackaging";

const MyComponent = () => {
    const location = useLocation();
    const url = location.pathname.split('/')[1];


    const dispatch = useDispatch()

    let getPost;

    useEffect(() => {
        let api_url;

        if (url === 'kalyar-replica') {
            api_url = apiEndPoints.WORKING_PROCESS_REPLICA;
            dispatch(fetchWorkingProcessReplica([api_url]));
        } else {
            api_url = apiEndPoints.WORKING_PROCESS_PACKAGING;
            dispatch(fetchWorkingProcessPackaging([api_url]));
        }
    }, [dispatch, url]);

    getPost = useSelector(state => {
        if (url === 'kalyar-replica') {
            return state.workingprocessReplicaReducer;
        } else {
            return state.workingprocessPackagingReducer;
        }
    });

    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let banner = data?.innerBanner;
    let overview = data?.overview;
    let process = data?.working_process;
    let machine = data?.machineries;
    let quality = data?.quality_assurance;
    let client = data?.client_satisfaction;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title + ' | ' + banner?.subtitle : banner?.title + ' | ' + banner?.subtitle}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
            </Helmet>

            <StyledComponent>

                    <InnerBanner
                        title={banner?.title}
                        img={banner?.image}
                        subtitle={banner?.subtitle}
                    />

                    <Overview data={overview}/>

                    <WorkingProcess data={process}/>

                    <Machineries data={machine}/>

                    <QualityAssurance data={quality}/>

                {client && <Satisfaction img={client?.image} title={client?.title} text={client?.desc}/>}
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
