import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Img} from "../Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImgInnerBanner} from "../ImgInnerBanner";
import Button from "../ButtonInnBanner";
import {button} from "../../styles/globalStyleVars";
import ButtonInnBanner from "../ButtonInnBanner";

const InnerBanner = ({img, text, title, imgMob, uppercase, desc, src}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Update the window width when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // Define the images for different viewports
    const largeImage = img?.large;
    const mediumImage = img?.medium;
    const smallImage = img?.thumbnail;

    // Determine the viewport size and set the image accordingly
    let imgUrl;
    if (window.innerWidth >= 1366) {
        imgUrl = largeImage;
    } else if (window.innerWidth >= 768) {
        imgUrl = mediumImage;
    } else {
        imgUrl = smallImage;
    }
    return (
        <StyledInnerBanner uppercase={uppercase} className='InnerBanner reveal-up-inner'>
            <ImgInnerBanner src={imgUrl ? imgUrl : '/images/dynamic/about/about.jpg'}/>
            <Container>
               <Row>
                   <Col md={6} className={''}>
                       <p>{text ? text : 'Kalyar Replica Limited/'}</p>
                       <h1>{reactHtmlParser(title)}</h1>
                       <h6>{reactHtmlParser(desc)}</h6>
                       <ButtonInnBanner src={src} target={'_blank'} text={'Dhaka Stock Exchange'} margin={'20px 0 0'} background={'#F9F9F9'} border={'none'}/>
                   </Col>
               </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(500 / 1366 * 100%);
  position: relative;
  .container {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 80px;
    z-index: 2;
  }

  p{
    font-size: 16px;
    line-height: 24px;
    font-weight: 350;
    margin-bottom: 20px;
  }
  h1 {
    color: #FFFFFF;
    font-size: 40px;
    line-height: 52px;
    font-weight: 400;
    z-index: 2;
    text-transform: uppercase;
  }
  
  h6{
    margin-top: 40px;
    font-family: ${button};
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #FFFFFF;
  }
  @media (min-width: 767px) {
    .title {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    //padding-top: 0;
    padding-top: calc(550 / 375 * 100%);
    .container {
      bottom: 40px;
    }
    

    h1 {
      font-size: 34px;
      line-height: 44px;
    }
  }
`;

export default React.memo(InnerBanner);
