import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

const MyComponent = ({hide_youtube}) => {
    return (
        <StyledComponent>
            <div className={'details-social'}>
                <div className={'social hover'}>
                    <svg id="Component_93_13" data-name="Component 93 – 13" xmlns="http://www.w3.org/2000/svg"
                             width="40" height="40" viewBox="0 0 40 40">
                            <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4"
                                    transform="translate(16 16)" fill="#180e81" opacity="0"/>
                            <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81" stroke-width="0.5">
                                <circle cx="20" cy="20" r="20" stroke="none"/>
                                <circle cx="20" cy="20" r="19.75" fill="none"/>
                            </g>
                            <path id="Path_2115" data-name="Path 2115"
                                  d="M1206.295,104.537l.416-2.715h-2.6V100.06a1.357,1.357,0,0,1,1.53-1.466h1.185V96.283a14.438,14.438,0,0,0-2.1-.184,3.314,3.314,0,0,0-3.547,3.654v2.069h-2.385v2.715h2.385V111.1h2.935v-6.562Z"
                                  transform="translate(-1182.787 -84.1)" fill="#180e81"/>
                        </svg>

                </div>
                <div className={'social hover'}>
                    <svg id="Component_167_4" data-name="Component 167 – 4" xmlns="http://www.w3.org/2000/svg"
                         width="40" height="40" viewBox="0 0 40 40">
                        <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4"
                                transform="translate(16 16)" fill="#180e81" opacity="0"/>
                        <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81" stroke-width="0.5">
                            <circle cx="20" cy="20" r="20" stroke="none"/>
                            <circle cx="20" cy="20" r="19.75" fill="none"/>
                        </g>
                        <path id="Twittor-01-01"
                              d="M10.627,14.63a.423.423,0,0,1-.368-.18C9.09,12.875,6.51,9.417,6.51,9.417l-4.725,5.07a.387.387,0,0,1-.255.142H0l.42-.48,3.63-3.885C4.628,9.65,5.2,9.035,5.782,8.42Q3.656,5.607,1.553,2.765L.323,1.122.105.785.4.74h4.02a.389.389,0,0,1,.315.173L8.325,5.7,11.587,2.21c.412-.442.825-.877,1.237-1.32a.492.492,0,0,1,.33-.142h.87l.488.03-.2.285L9.068,6.695,15,14.585l-.292.045a.438.438,0,0,1-.112.015h-3.96Zm.435-1.335a.109.109,0,0,0,.068.03h1.448L4.08,2l-.953-.023h-.6L11.062,13.3Z"
                              transform="translate(13 12.26)" fill="#180e81"/>
                    </svg>

                </div>
                <div className={'social hover'}>
                    <svg id="Component_93_11" data-name="Component 93 – 11" xmlns="http://www.w3.org/2000/svg"
                         width="40" height="40" viewBox="0 0 40 40">
                        <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4"
                                transform="translate(16 16)" fill="#180e81" opacity="0"/>
                        <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81" stroke-width="0.5">
                            <circle cx="20" cy="20" r="20" stroke="none"/>
                            <circle cx="20" cy="20" r="19.75" fill="none"/>
                        </g>
                        <g id="Group_17407" data-name="Group 17407" transform="translate(13 13)">
                            <path id="Path_2109" data-name="Path 2109"
                                  d="M1095.8,105.945a.879.879,0,1,0,.879.88A.879.879,0,0,0,1095.8,105.945Z"
                                  transform="translate(-1084.324 -103.267)" fill="#180e81"/>
                            <path id="Path_2110" data-name="Path 2110"
                                  d="M1082.749,108.605a3.694,3.694,0,1,0,3.694,3.694A3.7,3.7,0,0,0,1082.749,108.605Zm0,6.06a2.366,2.366,0,1,1,2.366-2.366A2.368,2.368,0,0,1,1082.749,114.665Z"
                                  transform="translate(-1075.187 -104.799)" fill="#180e81"/>
                            <path id="Path_2111" data-name="Path 2111"
                                  d="M1080.426,114.628h-5.989a4.511,4.511,0,0,1-4.506-4.506v-5.989a4.511,4.511,0,0,1,4.506-4.505h5.989a4.51,4.51,0,0,1,4.506,4.505v5.989A4.511,4.511,0,0,1,1080.426,114.628Zm-5.989-13.589a3.1,3.1,0,0,0-3.095,3.094v5.989a3.1,3.1,0,0,0,3.095,3.094h5.989a3.1,3.1,0,0,0,3.094-3.094v-5.989a3.1,3.1,0,0,0-3.094-3.094Z"
                                  transform="translate(-1069.932 -99.628)" fill="#180e81"/>
                        </g>
                    </svg>

                </div>

                {hide_youtube ? ''
                    :
                    <div className={'social hover'}>
                        <svg id="Component_93_10" data-name="Component 93 – 10" xmlns="http://www.w3.org/2000/svg"
                             width="40" height="40" viewBox="0 0 40 40">
                            <circle id="Ellipse_612" data-name="Ellipse 612" cx="4" cy="4" r="4"
                                    transform="translate(16 16)" fill="#180e81" opacity="0"/>
                            <g id="Ellipse_447" data-name="Ellipse 447" fill="none" stroke="#180e81"
                               stroke-width="0.5">
                                <circle cx="20" cy="20" r="20" stroke="none"/>
                                <circle cx="20" cy="20" r="19.75" fill="none"/>
                            </g>
                            <path id="Path_7836" data-name="Path 7836"
                                  d="M1149.075,113.609a3.292,3.292,0,0,0-3.292-3.292h-8.417a3.291,3.291,0,0,0-3.291,3.292v3.917a3.291,3.291,0,0,0,3.291,3.292h8.417a3.292,3.292,0,0,0,3.292-3.292Zm-4.95,2.252-3.775,1.867c-.148.08-.65-.027-.65-.2V113.7c0-.17.507-.277.655-.193l3.613,1.966C1144.119,115.559,1144.278,115.778,1144.125,115.861Z"
                                  transform="translate(-1121.075 -95.317)" fill="#180e81"/>
                        </svg>
                    </div>
                }

            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .details-social {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .social {
    position: relative;
    width: fit-content;
    cursor: pointer;

    &:hover {
      svg {
        position: relative;
        z-index: 2;

        path {
          fill: #ffffff;
        }
      }
    }
  }
`;

export default MyComponent;
