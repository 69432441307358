import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Address from "../../components/contact/Address";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import contactReducer, {fetchContact} from "../../api/redux/ContactUs";
import Form from "../../components/contact/Form";

const ContactIndex = () => {

    const location = useLocation()


    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CONTACT_US
        dispatch(fetchContact([api_url]))
    }, [])

    let getPost = useSelector(state => state.contactReducer);

    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.innerBanner;
    let office = data?.contacts;
    let form = data?.contact_form;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title + ' | ' + 'Kalyar' : ''}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                    <InnerBanner
                        img={innerBanner?.image}
                        title={innerBanner?.title}
                        subtitle={innerBanner?.subtitle}
                    />
                <Address data={office}/>
                <Form data={form}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default ContactIndex;
