import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "./Img";
import {book} from "../styles/globalStyleVars";

const MyComponent = ({img, title, date, category, link, shortDesc, getDate, getMonth, getYear}) => {
    return (
        <StyledComponent className={'..media-single '}>
            <Link to={`/media-center/${link}`}>
                <div className='blog-single fade-up'>
                    <div className="blog-single__inner">
                        <Img src={img} objectFit={'cover'} layout={'fill'}/>
                        <div className="blog-single__inner__content">
                            <div className="blog-single__inner__content__top">
                                <div className={'blog-single__inner__content__top__read-more'}>
                                    <p>{shortDesc}</p>
                                    <p>Read More <svg xmlns="http://www.w3.org/2000/svg" width="6.41" height="13.41"
                                                      viewBox="0 0 6.41 13.41">
                                        <g id="Group_19580" data-name="Group 19580"
                                           transform="translate(-659.295 -1482.008)">
                                            <path id="Path_4021" data-name="Path 4021" d="M0,0,5.871,5"
                                                  transform="translate(660 1494.712) rotate(-90)" fill="none"
                                                  stroke="#9f9fbf" stroke-linecap="round" stroke-width="1"/>
                                            <path id="Path_4022" data-name="Path 4022" d="M5.872,0,0,5"
                                                  transform="translate(660 1488.584) rotate(-90)" fill="none"
                                                  stroke="#9f9fbf" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </svg></p>
                                </div>
                                <h4>{title}</h4>

                            </div>
                            <div className="blog-single__inner__content__bottom">
                                {
                                    getDate &&
                                    <h2>{getDate}</h2>
                                }
                                <p>
                                    {getMonth && <>{getMonth}</>}
                                    <span>{getYear && <>{getYear}</>}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  .blog-single__inner {
    padding-top: calc(460 / 370 * 100%);
    position: relative;

    &:after {
      content: '';
      height: 0;
      width: 100%;
      background-color: #18153A;
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
      transition: height .4s ease;
    }

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 3;
    }

    &__content {
      //&:after {
      //  content: '';
      //  height: 100%;
      //  width: 100%;
      //  background-color: #E1E4E6;
      //  top: 0;
      //  left: 0;
      //  right: 0;
      //  position: absolute;
      //  transition: height .4s ease;
      //}

      &__top {
        &__read-more {
          cursor: pointer;
          position: absolute;
          top: 40px;
          left: 40px;
          right: 40px;
          z-index: 30;

          p {
            svg {
              margin-left: 15px;
            }
          }

          p:nth-child(2) {
            padding-top: 30px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #9F9FBF;
          //position: absolute;
          //left: 40px;
          //top: 40px;
          z-index: 2;
          //right: 40px;
          margin: 0;
          transform: translateY(-30px);
          opacity: 0;
          transition: all .6s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        h4 {
          position: absolute;
          top: 40px;
          left: 40px;
          right: 40px;
          z-index: 2;
          //font-size: 26px;
          //font-weight: bold;
          //line-height: 32px;
          color: #E9E9E9;
          margin: 0;
          transition: all .3s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }

      &__bottom {
        position: absolute;
        margin: 0;
        left: 40px;
        right: 40px;
        bottom: 24px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgb(255 255 255 / 50%);
        padding-top: 20px;
        z-index: 2;

        h2 {
          font-size: 48px;
          font-weight: 600;
          color: #E9E9E9;
          line-height: 62px;
          //transition: color .3s ease;
        }

        p {
          font-size: 14px;
          color: #E9E9E9;
          text-align: right;
          font-weight: ${book};
          line-height: 20px;
          transition: color .3s ease;

          span {
            display: block;
            color: #E9E9E9;
          }
        }
      }
    }

    &:hover {
      .blog-single__inner__content:after {
        height: 0;
      }

      .blog-single__inner__content__top {
        &__read-more {

        }

        h4 {
          opacity: 0;
          transform: translateY(-20px);
        }

        p {
          transform: none;
          opacity: 1;
        }
      }

      .blog-single__inner__content__bottom {
        border-color: rgb(255 255 255 / 50%);

        h4 {
          color: #E9E9E9;
        }

        p {
          color: #E9E9E9;

          span {
            color: #E9E9E9;
          }
        }
      }
    }

    &:hover {
      &:after {
        content: '';
        height: 100%;
        width: 100%;
        background-color: #18153A;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        transition: height .4s ease;
      }
    }

  }
`;

export default MyComponent;
