import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/investor/InnerBanner";
import List from "../../components/investor/List";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchInvestorData} from "../../api/redux/Investor";

const MyComponent = () => {

    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.INVESTOR
        dispatch(fetchInvestorData([api_url]))
    }, [])

    let getPost = useSelector(state => state.investorReducer);

//refactor
    let data = getPost?.posts?.[0];
    let banner = data?.banner;
    let list = data?.list;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title  : 'Investor'}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }

                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={banner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    text={banner?.subtitle}
                    title={banner?.title}
                    img={banner?.image}
                    desc={banner?.description}
                    src={banner?.button}
                />
                <List data={list}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
