import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, useLocation
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/layer/Overview";
import Specification from "../../components/layer/Specification";
import RecommendedPage from "../../components/layer/RecommendedPage";
import {fetchLayerList, fetchLayerDetail} from "../../api/redux/Layer";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion"



const MyComponent = () => {

    const dispatch = useDispatch()
    const location = useLocation();
    const getData = useSelector(store => store.layerReducer)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.LAYER_DETAILS
        dispatch(fetchLayerDetail([api_url + `/${slug}`]))
    }, [location])


    //refactor
    let data = getData?.detail?.[0];
    let banner = data?.banner;
    let overview = data?.overview;
    let spec = data?.specification;
    let related = data?.related;

    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Layer Details</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>

                    <InnerBanner
                        img={banner?.image}
                        title={banner?.title}
                        subtitle={banner?.subtitle}
                    />

                    <Overview data={overview}/>

                    <Specification data={spec}/>

                    <RecommendedPage data={related}/>

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
