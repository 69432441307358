import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import SubTitle from "../SubTitle";
import {Link} from "react-router-dom";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Row>
                    <Col md={6} className={'left'}>
                        {data && <Title classname={'split-up'} data-lag={0.1} text={data?.subtitle} color={'#262630'}/>}
                        <div className={'left__content'}>
                            <Col md={{offset:3, span: 6}}>
                                <p className={''}>
                                    {reactHtmlParser(data?.title)}
                                </p>
                            </Col>
                        </div>
                    </Col>
                    <Col md={6} className={'right'}>
                        <div className={'img-wrapper'}>
                            <Img src={data?.image}/>
                            <p className={'hover'} data-speed={1.2}>
                                <Link to={'/career'}>
                                    {reactHtmlParser(data?.bold_text)}
                                </Link>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #F2F2F2;
  .left{
    &__content{
      display: flex;
      margin-top: 80px;
      p{
        color: rgb(0 0 0 / 60%);
      }
    }
  }
  .right{
    .sub-title{
      margin-bottom: 40px;
    }
    .img-wrapper{
      position: relative;
      padding-top: calc(380 / 570 * 100%);
      border-top: 1px solid rgb(24 21 58 / 30%);
      img{
        padding-top: 40px;
      }
      p{
        height: 114px;
        width: 114px;
        background-color: #FFFFFF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: -57px;
        bottom: 45px;
        a{
          text-align: center;
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          color: #180E81;
          margin: 0!important;
        }
        &:hover{
          a{
            position: relative;
            z-index: 2;
            color: #fff;
            &:hover{
              color: #ffffff !important;
            }
          }
        }
      }
    }
  }
  
  @media(max-width: 767px){
    .left{
      &__content{
        margin-top: 60px;
        .col-md-6{
          padding: 0;
          margin-bottom: 40px;
        }
      }
    }
    .right{
      .img-wrapper{
        padding-top: calc(200 / 300 * 100%);
        img{
          padding-left: 45px;
        }
        p{
          height: 90px;
          width: 90px;
          left: 0;
        }
      }
    }
  }
`;

export default MyComponent;
