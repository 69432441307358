export const apiEndPoints = {
    PAGE_DATA:'page-data',
    POSTS: 'posts',

    HOME: 'page/home',
    ABOUT_REPLICA: 'page/about-us-replica',
    ABOUT_PACKAGING: 'page/about-us-packaging',
    CAREER: 'page/career',
    CAREER_DETAILS : 'career',
    CONTACT_US: 'page/contact-us',
    END_MARKET_PACKAGING: 'page/end-market-packaging',
    END_MARKET_REPLICA: 'page/end-market-replica',
    INVESTOR: 'page/investor',
    KEY_PERSONNEL_PACKAGING: 'page/key-personnel-packaging',
    KEY_PERSONNEL_REPLICA: 'page/key-personnel-replica',
    MANAGEMENT_PACKAGING: 'page/management-packaging',
    MANAGEMENT_REPLICA: 'page/management-replica',
    MEDIA: 'page/media',
    MEDIA_DETAILS: 'media',
    INVESTOR_DETAILS: 'investor',
    SUSTAIN_DETAILS: 'sustainability',
    LAYER_DETAILS: 'layer',
    PRODUCT_PACKAGING: 'page/products-packaging',
    PRODUCT_REPLICA: 'page/products-replica',
    TECHNOLOGY_PACKAGING: 'page/technology-packaging',
    TECHNOLOGY_REPLICA: 'page/technology-replica',
    WORKING_PROCESS_PACKAGING: 'page/working-process-packaging',
    WORKING_PROCESS_REPLICA: 'page/working-process-replica',
    HOME_FORM: 'contact-form-7/v1/contact-forms/16/feedback',
    CONTACT_FORM: 'contact-form-7/v1/contact-forms/259/feedback',
    CAREER_FORM: 'contact-form-7/v1/contact-forms/260/feedback',
};
