import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImgInnerBanner} from "./ImgInnerBanner";
import {ImageParallax} from "./ImageParallax";

const InnerBanner = ({img, text, title, imgMob, uppercase}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Update the window width when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <StyledInnerBanner className='InnerBanner'>
            <ImageParallax src={img ? img : '/images/dynamic/satisfaction.jpg'}/>
            <Container className={'pb-120'}>
                <Row>
                    <Col md={10} className={'split-up'}>
                        <h3>{title ? reactHtmlParser(title) : 'Client Satisfaction'}</h3>
                        <p>
                            {text ?  reactHtmlParser(text) : 'Our entire process of business activities revolves around the sole motive of satisfying our clients\n' +
                                '                            with our range of quality products. Reinforced and aided by 8 years of experience and a team of\n' +
                                '                            highly qualified and experienced professionals, we are able to comprehend the ever growing and changing\n' +
                                '                            requirements of our clients.'}
                        </p>
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(500 / 1366 * 100%);
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    content: '';
    opacity: 15%;
  }

  .container {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 40px;
    color: #f9f9f9;
  }

  h3 {
    color: #FFFFFF;
    z-index: 2;
  }


  @media (max-width: 767px) {
    //padding-top: 0;
    padding-top: calc(550 / 375 * 100%);
    .container {
      bottom: 40px;
    }

  }
`;

export default React.memo(InnerBanner);
