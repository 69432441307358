import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({mission,vision}) => {

    const rows = [
        {
            title: reactHtmlParser(mission?.title),
            content: mission?.description,
            imageSrc: mission?.image?.large,
        },
        {
            title:  reactHtmlParser(vision?.title),
            content:  vision?.description,
            imageSrc: vision?.image?.large,
        },
        // Add more rows if needed
    ];

    return (
    <StyledComponent className={'pt-120'}>
        <Container>
            {rows.map((row, index) => (
                <Row key={index + 1} className={`values pb-120 ${index % 2 === 1 ? 'even-row' : 'odd-row'}`}>
                    <Col md={index % 2 === 1 ? {span:5, offset:1} : 5} className={'values__content'}>
                        <div className={'content-wrapper'}>
                            <Title text={row.title}/>
                            {reactHtmlParser(row.content)}
                            {/*<p className={'split-up'}></p>*/}
                        </div>
                    </Col>
                    <Col
                        md={index % 2 === 1 ? 6 : {span:6, offset:1}}
                        className={'values__image'}
                    >
                        <div className={'img-wrapper reveal'}>
                            <Img src={row.imageSrc} />
                        </div>
                    </Col>
                </Row>
            ))}
        </Container>
    </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #18153A;
    .values{
      &:nth-child(even){
        flex-direction: row-reverse;
      }
      &__content{
        .content-wrapper{
          margin-top: 60px;
          height: 100%;
          h2{
            margin-bottom: 40px;
            color: #ffffff;
          }
          p{
            margin-bottom: 40px;
            color: #B1B1C0;
            font-weight: 400;
          }
          @media(max-width: 767px){
            margin-top: 0;
          }
        }
      }
      &__image{
        .img-wrapper{
          position: relative;
          padding-top: calc(500 / 500 * 100%);
        }
      }
    }
`;

export default MyComponent;
