import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import TruncateText from "../TruncateText";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const paragraphText = "KALYAR PACKAGING LIMITED, a well reputed and one of the leading manufacturer and supplier of Corrugated Packaging Solution came into physical existence in the year 1999 under the Leadership of Mr. Mizanur Rahman Sinha and Ms. Tasneem Sinha. The Chairman of Kalyar Packaging Limited Mr. Mizanur Rahman Sinha is very well known industrialist in Bangladesh. \n" +
        "<br/> <br/>" +
        "Within a short span of time since its inception it has been famed and widely entrusted as one of the leading Manufacturer and Supplier of corrugated packaging solutions which are extensively used for packaging delicate and heavy products. KPL successfully catered to the ever growing packaging demands of the market. KPL’s range of\n" +
        "environmental friendly corrugated packaging products is manufactured from quality proven raw material which  ndergoes stringent quality analysis. The motto of KALYAR is not only to deliver the products ahead of schedule but also to maintain the highest-grade product quality that has gained trust and respect in customers."

    return (
        <StyledComponent className={'reveal-up pt-150 pb-150 '}>
            <Container>
                <Row>
                    <Col md={12}>
                        <h3>{reactHtmlParser(data?.title)}</h3>
                        <TruncateText longText={reactHtmlParser(data?.desc)} maxLines={3}/>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #FFFFFF;
    h3{
      color: #262630;
      margin-bottom: 40px;
    }
  
  
  .counters{
    display: flex;
    &__single{
      min-width: 270px;
      margin-right: 65px;
      h5{
        font-family: ${button};
        font-size: 48px !important;
        line-height: 62px;
        font-weight: 300;
        color: rgb(38 38 48 / 70%);
        margin-bottom: 20px;
        border-bottom: 1px solid rgb(121 121 151 / 60%);
      }
      p{
        color: rgb(0 0 0 / 60%);
        font-family: ${button};
        font-size: 16px !important;
        line-height: 24px;
        font-weight: 400;
      }
    }
    @media(max-width: 767px){
      display: block;
      &__single{
        margin-bottom: 65px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default MyComponent;
