import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover, title} from '../../styles/globalStyleVars';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination} from "swiper";
import {Col, Container, Row} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {Img} from '../Img';
import LeftSvg from "../svg/LeftSvg";
import RightSvg from "../svg/RightSvg";
import reactHtmlParser from "react-html-parser";
import VideoBanner from "./VideoBanner";


// for button

// Function to handle button animation


const Banner = ({data}) => {


    // const slider_data = data?.posts?.list?.[0]?.find(f => f?.images?.banner === 'on') ;

    const interleaveOffset = 0.5;
    // const sliderData = data?.data?.images?.list;
    const swiperRef = useRef(null);
    const swiperRefTwo = useRef(null);
    const AutoplayDelay = 5000;
    const [offset, setOffset] = useState(90)
    const location = useLocation();
    const buttonRef = useRef(null);


    function isMobile() {
        return window.innerWidth < 767;
    }



    // navigation
    const prev = () => {
        document.querySelector('.home-banner .swiper-button-prev').click();
    };

    const next = () => {
        document.querySelector('.home-banner .swiper-button-next').click();
    };

    useEffect(() => {


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    // handle slider progress
    const handleProgress = (swiper, event) => {
        var interleaveOffset = 0.5;
        for (let i = 0; i < swiper.slides.length; i++) {
            var slideProgress = swiper.slides[i].progress;
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }

    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };


    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".global-image")
                .style.transition = `${speed}ms`;
        }
    };


    // slider-speed-control for mobile and laotop
    const [swiperSpeed, setSwiperSpeed] = useState('3000');

    // Check if the device is a mobile
    useEffect(() => {
        const isMobileDevice = /Mobi/i.test(navigator.userAgent);

        // If it's a mobile device, set the speed to '1500'
        if (isMobileDevice) {
            setSwiperSpeed('1500');
        }
    }, []);


    // handle pagination
    var pagination_title = ['Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities', 'Financial freedom at the palm of your hands', 'Investing in growth to open new big opportunities']
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '"><span>' + pagination_title[index] + "</span></div>";
        },
    };
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);


    const handleSlideChange = (event) => {

        const newActiveSlideIndex = event.realIndex;
        setActiveSlideIndex(newActiveSlideIndex);
        if (swiperRefTwo.current) {
            swiperRefTwo.current.swiper.slideTo(newActiveSlideIndex);
        }


    };

    const handleButtonClickPagination = (index) => {
        if (swiperRef.current) {
            setActiveSlideIndex(index);
            swiperRef.current.swiper.slideToLoop(index);
        }
    };


    // Move this split text animation code to a separate function to reuse it


    return (
        <StyledBanner offset={offset} className={'home-banner reveal-up-banner'}>
            {data &&
                <Swiper
                    ref={swiperRef}
                    spaceBetween={0}
                    loop={true}
                    autoplay={isMobile() ? false : {delay: AutoplayDelay, disableOnInteraction: false}}
                    /*{isMobile() ? false : { delay: AutoplayDelay, disableOnInteraction: false }}*/
                    speed={swiperSpeed}
                    // swiperSpeed
                    onSlideChange={handleSlideChange}
                    onProgress={handleProgress}
                    touchStart={handleTouchStart}
                    onSetTransition={handleSetTransition}
                    grabCursor={false}
                    watchSlidesProgress={true}
                    mousewheelControl={true}
                    keyboardControl={true}
                    navigation={{
                        prevEl: '#banner-prev',
                        nextEl: '#banner-next',
                    }}
                    pagination={pagination}
                    modules={[Autoplay, Pagination, Navigation]}
                    sName="mySwiper main-swiper"
                >
                    {
                        data?.length > 0 && data?.map((i, index) => (
                            <SwiperSlide>
                                <div className="slide-inner">
                                    {
                                        window.innerWidth > 767 ?

                                            <div className="desktop">
                                                {
                                                    i?.video ?
                                                        <VideoBanner src={i?.video}/> :
                                                        <Img src={i?.image}/>
                                                }
                                            </div>

                                            :

                                            <div className="mobile">
                                                {
                                                    i?.video ?
                                                        <VideoBanner src={i?.video}/> :
                                                        <Img src={i?.image}/>
                                                }
                                            </div>

                                    }


                                    <Container>
                                        <Row>
                                            <Col md={8}>
                                                <div className="slide-inner__info">
                                                    <h5>{reactHtmlParser(i?.subtitle)}</h5>
                                                    <h1>{reactHtmlParser(i?.title)}</h1>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={'description'}>
                                            <Col md={{offset: 7, span: 4}} className={'desc'}>
                                                <p>
                                                    {reactHtmlParser(i?.short_desc)}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
            }


            <div className="navigation">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <div id={'banner-prev'}>
                                    <li>
                                        <LeftSvg/>
                                    </li>
                                </div>
                                <div id={'banner-next'}>
                                    <li>
                                        <RightSvg/>
                                    </li>
                                </div>

                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  height: 100vh;
  overflow: hidden;
  position: relative;

  .swiper-button-prev, .swiper-button-next, .swiper-pagination, .mobile {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-button-disabled {
    opacity: 0.5 !important;
  }


  .global-image {
    transition: clipPath .8s cubic-bezier(.29, .73, .45, 1), border-color 3.6s linear, -webkit-clipPath .8s cubic-bezier(.29, .73, .45, 1);
    will-change: clipPath;
    overflow: hidden;
    -webkit-clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .swiper-slide-active, .swiper-slide-visible {
    .global-image {
      clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .slide-inner {
      &__info {
        h1 {
          font-size: 64px;
          line-height: 80px;
          font-weight: 400;
          margin-top: 30px;
          transition-delay: 0.8s;
          transform: translateY(0px);
          //width: 80% !important;
          @media (max-width: 767px) {
            //width: 100% !important;
          }
        }

        h5 {
          transition-delay: 0.8s;
          transform: translateY(0px);
          width: 65%;
        }
      }


      img {
        height: 100vh !important;
      }
    }

    .description {
      .desc {
        p {
          padding-left: 30px;
          transition-delay: 1s;
          transform: translateY(0px);
        }
      }
    }
  }


  .slide-inner {
    position: relative;
    height: 100vh;

    .container {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(50% - 15vh);
      //transform: translateY(-50%);
      z-index: 2;
      overflow: hidden;

    }

    .global-image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      }
    }

    &__info {
      position: relative;
      overflow: hidden;

      h1 {
        font-size: 64px;
        line-height: 80px;
        font-weight: 600;
        color: #FFFFFF;
        transform: translateY(400px);
        margin: 0;
        transition-delay: 1.2s;
        transition: 2s all cubic-bezier(0.4, 0, 0, 1);
        //width: 65%;
      }

      h5 {
        margin-top: 40px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 350;
        color: #FFFFFF;
        transform: translateY(400px);
        transition-delay: 1.2s;
        transition: 2s all cubic-bezier(0.4, 0, 0, 1);
      }

      p {
        color: #FAFAFA;
      }

      .banner-button {
        opacity: 0;
        overflow: hidden;

        .dc-btn {
          position: relative;
          overflow: hidden;
        }
      }

      @media (min-width: 1024px) {
        //width: 70%;
      }

      h1 {
        .line {
          overflow: hidden;
        }
      }

    }

    .description {
      margin-top: 30px;
      margin-left: 0;
      margin-right: 0;
      border-top: 1px solid white;

      .desc {
        padding-left: 0;
        padding-right: 0;
      }

      p {
        margin-top: 40px;
        font-weight: 300;
        transform: translateY(400px);
        transition-delay: 1.2s;
        transition: 2s all cubic-bezier(0.4, 0, 0, 1);
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .slide-inner__info {
      margin-right: 50px;

      h1 {
        //width: 60% !important;
      }
    }
  }


  //navigation
  .navigation {
    position: absolute;
    right: ${props => props.offset ? props.offset + 'px' : '140px'};
    top: calc(50% + 30px);
    transform: translateY(-50%);
    display: inline-block;
    z-index: 1;

    @media (min-width: 1550px) {
      top: calc(50%) !important;
    }

    ul {
      display: flex;
      //flex-direction: column-reverse;
      gap: 20px;
      align-items: center;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid #F9F5F2;

        &:last-child {
          //margin-top: 20px;
        }

        //&.hover:after {
        //  background-color: #3CB649;
        //}

        position: relative;
        border-radius: 50%;
        height: 40px;
        width: 40px;

        svg {
          position: relative;

          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 26px;
            }
          }
        }

        &:hover {
          //border: 1px solid #67A66D;
          svg {
            g {
              line {
                stroke: #FCFEFF;
              }
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      left: 0;
      top: 30%;
      ul {
        flex-direction: unset;

        li {
          &:last-child {
            margin-top: 0;
          }
        }
      }
    }
  }


  //responsive
  @media (min-width: 1550px) {
    //.slide-inner {
    //  &__info {
    //    position: relative;
    //    overflow: hidden;
    //
    //    h2 {
    //      font-size: 80px !important;
    //      line-height: 80px;
    //    }
    //
    //    //.dc-btn {
    //    //  a {
    //    //    font-size: 22px;
    //    //    line-height: 33px;
    //    //  }
    //    //}
    //  }
    //}
    .feature-box-container {
      bottom: -150px;
    }

    .feature-box-container {
      .single-item-wrapper {
        min-height: 300px;

        .content {
          min-height: 250px;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .swipper-navigation-slider-custom {
      bottom: 250px;

    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    height: 100vh;
    .custom-navigation {
      bottom: 0;
    }

    .swipper-navigation-slider-custom {
      bottom: 60px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: 45%;
      }
    }

    .feature-box-container {
      display: none;
      position: relative;
      left: 0px;
      right: 0px;
      bottom: 200px;
      z-index: 999;
      width: calc(100% - 30px);
      margin: 0px auto;


      .single-item-wrapper a .content {
        align-items: flex-start;
      }

      .feature-box-container_item:last-child {
        order: 1;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .feature-box-container_item:first-child {
        order: 2;
        flex: 0 0 100%;
        max-width: 100%;

        .single-item-wrapper {
          border-right: none;
          border-bottom: 1px solid rgba(198, 198, 198, 0.5);
        }

      }

      .feature-box-container_item:nth-of-type(2) {
        order: 3;
        flex: 0 0 50%;
        max-width: 50%;
      }

      .feature-box-container_item:nth-of-type(3) {
        order: 4;
        flex: 0 0 50%;
        max-width: 50%;

        .single-item-wrapper {
          border-right: none;

        }

      }
    }


  }
  @media (max-width: 767px) {
    height: 100vh;
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: 35%;
        transform: unset;
        //bottom: 220px;
      }

      &__info {
        position: relative;
        overflow: hidden;


        //h2 {
        //  font-size: 40px !important;
        //  line-height: 48px;
        //  width: 60%;
        //}
        //
        //h1{
        //  line-height: 55px;
        //}
        h1 {
          font-size: 48px !important;
          line-height: 60px !important;
        }

        .dc-btn {
          margin-top: 30px;
        }
      }
    }

    .swipper-navigation-slider-custom {
      padding-left: 15px !important;
      padding-right: 15px !important;
      bottom: 0px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }


    .scrollTo {
      left: 15px;
      right: unset;
      display: inline-block;
      bottom: 20px;
      transform: translateX(0);
    }


    .feature-box-container {

      display: none;
      position: relative;
      bottom: 150px;

      .feature-box-container_item {
        &:first-child {
          order: 2;
        }

        &:nth-of-type(2) {
          order: 3;
        }

        &:nth-of-type(3) {
          order: 4;
        }

        &:last-child {
          order: 1;

          .content {
          }
        }
      }

      .single-item-wrapper {
        border-right: none;
        border-bottom: 1px solid rgba(198, 198, 198, 0.5);

        .content {
          align-items: flex-start !important;
        }

        &.last {
          border: none;

          .content {
            align-items: center !important;
          }
        }
      }

      .container {
        padding: 0;
      }
    }

    .swiper {
      .description {
        .desc {
          p {
            padding-left: 0;
          }
        }
      }
    }

  }
`;

export default Banner;