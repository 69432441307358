import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {button, hover} from "../../styles/globalStyleVars";
import {Swiper, SwiperSlide} from 'swiper/react';
import swiper, {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import 'swiper/css/scrollbar';
import {Img} from "../Img";
import SubTitle from "../SubTitle";
import LeftSvg from "../svg/LeftSvg";
import RightSvg from "../svg/RightSvg";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";
import {useSelector} from "react-redux";

const MyComponent = ({background, data, id, top, bottom, bg}) => {

    const slide = data;

    const nextRef = useRef()
    const prevRef = useRef()
    const [current, setCurrent] = useState('1')
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const getPost = useSelector((state)=>state)
    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [getPost])


    const handleSliderCurrent = () => {
        setTimeout(() => {
            if (document.querySelector('.sustain .swiper-pagination-current')) {
                setCurrent(document.querySelector('.sustain .swiper-pagination-current').innerHTML)
            }
        }, 200)
    }
    return (
        <StyledComponent id={`${id ? id : 'sustainability'}`}  offset={offset} bg={bg} background={background} top={top} bottom={bottom}>
            <ul offset={offset}>
                <li className={'prev_swipper hover'} ref={prevRef}>
                    <LeftSvg color={'#FFFFFF'}/>
                </li>
                <li className={'next_swipper hover'} ref={nextRef}>
                    <RightSvg color={'#FFFFFF'}/>
                </li>
            </ul>
            <Container fluid className={'sustain'}>
                <div className={'sustain__top'} style={{paddingLeft: offset + 15 + 'px'}}>
                {/*<div className={'sustain__top'} offset={offset}>*/}
                    <SubTitle text={'Sustainability'}/>
                    <p className={'length'}><span>{current}</span>/<span>{slide?.length > 0 ? slide?.length : 5}</span></p>
                </div>
                <Row className={'sustainability'}>
                    <Col md={6} className={'p-0 '}>
                        { slide &&
                        <Swiper
                            modules={[Autoplay, Pagination, Navigation, EffectFade]}
                            allowTouchMove={true}
                            longSwipesMs={900}
                            slidesPerView={1}
                            pagination={{
                                type: "fraction",
                            }}
                            effect={"mask"}
                            navigation={{
                                prevEl: prevRef.current,
                                nextEl: nextRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                            }}
                            shouldSwiperUpdate={(prev, next) => {
                                if (prev.activeIndex === next.slides.length - 1) {
                                    setButtonDisabled(true);
                                } else {
                                    setButtonDisabled(false);
                                }
                            }}
                            loop={true}
                            speed={2000}
                            onSlideChange={(s) => handleSliderCurrent()}
                        >

                            {data?.length > 0 && data?.map((i,index) => (
                            <SwiperSlide key={index}>
                                <div className="sustainability__right">
                                    <Img src={i?.thumbnail} alt={'esrs'}/>
                                    <h2>{i?.post_title}</h2>
                                </div>
                            </SwiperSlide>
                            ))}
                        </Swiper>
                        }
                    </Col>
                    <Col md={6} className={'sustainability__left'}>
                        {data &&
                        <Swiper
                            modules={[Autoplay, Pagination, Navigation, EffectFade]}
                            allowTouchMove={true}
                            longSwipesMs={900}
                            slidesPerView={1}
                            pagination={{
                                type: "fraction",
                            }}
                            effect={"mask"}
                            navigation={{
                                prevEl: prevRef.current,
                                nextEl: nextRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                            }}
                            shouldSwiperUpdate={(prev, next) => {
                                if (prev.activeIndex === next.slides.length - 1) {
                                    setButtonDisabled(true);
                                } else {
                                    setButtonDisabled(false);
                                }
                            }}
                            loop={true}
                            speed={2000}
                            onSlideChange={(s) => handleSliderCurrent()}
                        >
                            {data?.length > 0 && data?.map((i,index) => (
                            <SwiperSlide key={index}>
                                <div className={'sustainability__left__data'}>
                                    {/*<h2>{'Landscaping'}</h2>*/}
                                    <p className="text">
                                        {reactHtmlParser(i?.short_desc)}
                                    </p>
                                    <Button src={`/sustainability/${i?.post_name}`} text={'Explore'}  color={'#F9F9F9'} borderColor={'#F9F9F9'}  hoverBackground={'#FFFFFF'} hoverColor={'#000000'}/>
                                </div>
                            </SwiperSlide>
                            ))}
                        </Swiper>
                        }
                    </Col>

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color:  ${props => props.background ? props.background : '#f2f2f2'};
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    height: 50%;
    width: 100%;
    background-color: ${props => props.bg ? props.bg : '#ffffff'};;
    left: 0;
    bottom: ${props => props.bottom ? props.bottom : '0'};
    top: ${props => props.top ? props.top : ''};
  }

  padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
  @media (max-width: 767px) {
    padding-right: 0 !important;
    margin-top: 0;
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .title {
    h2 {
      line-height: 60px;
    }
  }

  ul {
    position: absolute;
    bottom: 80px;
    left: ${props => props.offset ? props.offset + 15 + 'px' : '180px'};
    //right: 115px;
    z-index: 150;
    display: flex;
    gap: 30px;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid rgb(252 254 255 / 50%);

      &.hover:after {
        background-color: #ffffff;
      }

      &:hover{
        svg{
          position: relative;
          z-index: 9;
          g {
            line {
              stroke: #180E81;
            }
          }
        }
      }

      position: relative;
      border-radius: 50%;
      height: 40px;
      width: 40px;

      svg {
        position: relative;

        #Ellipse_4378 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          r: 0;
        }

        &:hover {
          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 26px;
          }
        }
      }
    }
  }

  .sustain {
    padding-left: 0;
    padding-right: 0;

    &__top {
      position: absolute;
      top: 60px;
      z-index: 150;
      width: calc(100% - ${props => props.offset ? props.offset + 70 +'px' : '180px'});
      display: flex;
      padding-left: 100px;
      flex-direction: column-reverse;
      @media(max-width: 1415px){
        width: calc(100% - ${props => props.offset ? props.offset + 90 + 'px' : '100px'});
      }
      


      p {
        color: #ffffff;
        position: relative;
       // border-bottom: 1px solid #FFF;
      }
      .length{
        position: absolute;
        top: 0;
        right: 0;
        color: #ffffff;
      }
    }

    .sustainability {
      margin: 0;

      &__left {
        background-color: #18153A;
        margin-left: 0;

        &__data {
          position: relative;
          padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
          padding-left: 60px;
          margin-top: 275px;
          padding-bottom: 100px;

          h2 {
            margin-bottom: 60px;
            width: 100%;
            //margin-left: 190px;
            //font-weight: 500;
            z-index: 5;
            color: #FFFFFF;
            position: relative;
          }

           p {
            //margin-bottom: 40px;
            color: #FAFAFA;
             font-family: ${button};
             font-size: 16px;
             line-height: 24px;
            //font-size: 16px;
            //line-height: 24px;
             margin-bottom: 32px;
          }
        }
      }

      .swiper{
        height: 100%;
      }
      &__right {
        position: relative;
        padding-top: calc(600 / 634 * 100%);
        height: 100%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        
        h2{
          position: absolute;
          z-index: 99999;
          top: 140px;
          color: #FFFFFF;
          left: ${props => props.offset ? props.offset + 15 +'px' : '180px'};
        }
      }
    }
  }


  @media (max-width: 767px) {
    .sustain {
      &__top {
        top: 40px;
        padding: 0 15px;
        width: 95%;
        //width: calc(100% - ${props => props.offset ? props.offset + 175 + 'px' : '120px'});
        padding-right: 0;
        p {
          height: 36px;
        }
      }

      .sustainability {
        //flex-direction: column-reverse;
        .swiper{
          overflow: visible;
          width: 100%;
        }
        margin: 0;
        &__left {
          .swiper{
            overflow: hidden;
          }
          &__data {
            margin-top: 105px;
            margin-bottom: 105px;
            padding: 0;

            h2 {
              margin-left: 0;
              margin-bottom: 40px;
              font-size: 50px;
              line-height: 68px;
              //font-weight: 400;
              margin-top: 25px;
              width: initial;
            }
            p{
              width: 95%;
              margin-bottom: 32px;
            }
          }
        }

        &__right {
          position: relative;
          padding-top: calc(375 / 375 * 100%);

          img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          h2{
            left: 15px;
            top: unset;
            bottom: -20px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    ul {
      position: absolute;
      bottom: 55%;
      left: 15px;
      z-index: 150;
    }
  }


  .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }
  
  .swiper-slide{
    width: 100%;
  }
  
  
`;

export default React.memo(MyComponent);
