import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Accordion, Row, Col, Modal, Form} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {hover, text, title, whites} from "../styles/globalStyleVars";
import Button from "./Button";
import {gsap, TimelineLite} from "gsap";
import {useDispatch, useSelector} from "react-redux";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Title from "./Title";

const MyComponent = ({updatedMenu}) => {


    const store = useSelector(store => store.homeReducer)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const location = useLocation()

    const url = location.pathname.split('/')[1];

    const [offset, setOffset] = useState(100)

    const containerRef = useRef(null);

// offset
    useEffect(() => {
        ScrollTrigger.refresh();
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container').offsetLeft + 15)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container').offsetLeft + 15)
            }
        })
    }, [location.pathname,store])


    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains('scroll-down')) {
            document.body.classList.remove('scroll-down');
        }
    });

    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll;

        if (window.screen.width < 991) {
            howMuchScroll = 150;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                body.classList.remove(scrollDown);
                return;
            }

            if (currentScroll > lastScroll && currentScroll > howMuchScroll) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && currentScroll > howMuchScroll) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }

            lastScroll = currentScroll;
        });
    }, []);


    const [isSubMenuVisible, setSubMenuVisible] = useState(false);
    const [isSubSubMenuVisible, setSubSubMenuVisible] = useState(false);

    const toggleSubMenu = () => {
        setSubMenuVisible(true);
    };


    const toggleSubSubMenu = () => {
        setSubSubMenuVisible(true);
    };


    //----- mobile menu action
    useEffect(() => {
        let getMobileMenuBar = document.querySelector('.main-menu-mobile');
        let getItemsParent = document.querySelector('.main-menu-mobile__items');

        let getItems = document.querySelectorAll('.main-item');

        let getBacks = document.querySelectorAll('.sub-menu-back');
        let getHamburgerClick = document.querySelector('#open-click')
        let getHamburgerCloseClick = document.querySelector('#close-click')
        let tl = new TimelineLite();
        let tl2 = new TimelineLite();

        // menu open toggle
        getHamburgerClick.addEventListener('click', () => {
            getMobileMenuBar.classList.add('menu-open')
            document.body.classList.add('stop-scroll')
            tl2.to(getItemsParent, {
                duration: .2,
                display: 'block',
            }).to(getItemsParent, {
                duration: .2,
                x: 0
            }, '-=.2')


        })

        getHamburgerCloseClick.addEventListener('click', () => {
            getMobileMenuBar.classList.remove('menu-open')
            document.body.classList.remove('stop-scroll')
            if (document.querySelector('.main-item.active')) {
                getItemsParent.classList.remove('active')
                document.querySelector('.main-item.active').classList.remove('active')
            }

            tl2.to(getItemsParent, {
                duration: .2,
                x: '100%'
            }).to(getItemsParent, {
                duration: .2,
                display: 'none'
            })
        });


        // sub menu toggle
        getItems.forEach(i => {
            i.addEventListener('click', () => {
                getItemsParent.classList.add('active')
                i.classList.add('active')
            })
        })

        getBacks.forEach(i => {
            i.addEventListener('click', (e) => {

                getItemsParent.classList.remove('active')
                i.parentNode.parentElement.classList.remove('active')
                e.stopPropagation()
            })
        })

        // on click a tag menu hide
        let getAlla = document.querySelectorAll('.main-menu-mobile a');
        getAlla.forEach(i => {
            i.addEventListener('click', (e) => {
                // e.stopPropagation();
                getMobileMenuBar.classList.remove('menu-open');
                document.body.classList.remove('stop-scroll');
                // console.log('have or not', document.querySelector('.main-item.active'))
                setTimeout(() => {
                    if (document.querySelector('.main-item.active')) {

                        getItemsParent.classList.remove('active')
                        document.querySelector('.main-item.active').classList.remove('active')
                    }
                }, 300)

                tl2.to(getItemsParent, {
                    duration: .3,
                    x: '100%'
                }).to(getItemsParent, {
                    duration: .3,
                    display: 'none'
                })
            })
        })

    }, [])


    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    // const {register, handleSubmit, formState: {isSubmitSuccessful}, reset} = useForm({mode: 'all'});
    // const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset} = useForm({
    //     mode: "all",
    // })

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });


    // on click hide menu
    useEffect(() => {
        const getAList = document.querySelectorAll('.main-menu__right li');
        getAList.forEach(e => {
            e.addEventListener('click', function () {
                if (e.querySelector('ul')) {
                    e.querySelector('ul').style.display = 'none'
                }
                setTimeout(() => {
                    const getUl = document.querySelectorAll('.main-menu__right li ul')
                    getUl.forEach(e => {
                        e.removeAttribute('style')
                    })
                }, 300)
            });
        })
    }, [])

    return (

        <>
            {/*desktop menu */}
            <StyledComponent className={'main-menu'} offset={offset}>
                <Container fluid className={'reveal-down'}>
                    {updatedMenu === 'updated-menu' ? (
                        // If updatedMenu is 'updated-menu', render an empty string
                        ''
                    ) : (
                        // If updatedMenu is not 'updated-menu', render the following JSX
                        <div className="menu-top">
                            <div className="menu-top__number">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.923" height="20" viewBox="0 0 19.923 20">
                                    <path id="telephone-02" d="M4.447,0A2.954,2.954,0,0,1,5.981.974c.772.792,1.572,1.56,2.324,2.37a1.822,1.822,0,0,1,.008,2.562c-.482.536-1.008,1.036-1.522,1.542a.227.227,0,0,0-.062.312,11.557,11.557,0,0,0,2.064,2.85,14.3,14.3,0,0,0,2.75,2.328c.278.178.578.322.864.49a.161.161,0,0,0,.232-.038c.434-.442.86-.89,1.314-1.308a3.11,3.11,0,0,1,.82-.582,1.784,1.784,0,0,1,2.08.426c.86.834,1.694,1.694,2.548,2.534a1.974,1.974,0,0,1,.138,2.906c-.606.654-1.222,1.3-1.882,1.9a3.2,3.2,0,0,1-2.516.722,11.142,11.142,0,0,1-4.086-1.278,20.41,20.41,0,0,1-5.192-3.666A20.239,20.239,0,0,1,.646,7.154,7.521,7.521,0,0,1,.192,4.5,3.2,3.2,0,0,1,1.1,2.256q.792-.81,1.6-1.6A2.431,2.431,0,0,1,3.941,0h.508ZM15.483,18.991a2.159,2.159,0,0,0,1.616-.566q.876-.849,1.7-1.748a.912.912,0,0,0-.018-1.378c-.876-.9-1.768-1.786-2.664-2.666a.866.866,0,0,0-1.19-.06,2.309,2.309,0,0,0-.276.246c-.484.482-.966.968-1.452,1.448a.832.832,0,0,1-1.066.174c-.284-.144-.568-.29-.84-.454A15.026,15.026,0,0,1,8.013,11.27a12.084,12.084,0,0,1-2.27-3.224.9.9,0,0,1,.234-1.222c.478-.46.956-.922,1.42-1.4a.97.97,0,0,0,.01-1.576.37.37,0,0,0-.028-.028c-.8-.8-1.6-1.6-2.4-2.4A1.664,1.664,0,0,0,4.539,1.1a.953.953,0,0,0-1.13.282c-.514.5-1.028,1-1.514,1.524a2.787,2.787,0,0,0-.516.792,3.37,3.37,0,0,0-.064,2A12.2,12.2,0,0,0,2.7,9.284a20.512,20.512,0,0,0,9.706,8.932,8.356,8.356,0,0,0,3.082.774Z" transform="translate(-0.189 0)" fill="#fff"/>
                                </svg>
                                <p><a href="tel:+88011777707017">+(880) 1777707017</a></p>
                            </div>
                            <div className="menu-top__email">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15.973" viewBox="0 0 20 14.973">
                                    <path id="telephone-01" d="M20.008,14.167V25.941s-.022.018-.024.028a1.88,1.88,0,0,1-2.038,1.57H3.284c-.476,0-.95.006-1.426,0A1.877,1.877,0,0,1,.1,26.255c-.034-.1-.064-.208-.094-.312V14.169c.008-.01.022-.018.024-.028a1.884,1.884,0,0,1,2.04-1.57h14.37c.58,0,1.158-.006,1.738,0a1.872,1.872,0,0,1,1.74,1.3c.03.1.058.2.088.294Zm-1.25,1.024c-.08.066-.13.106-.178.148q-4.044,3.5-8.088,6.994a.639.639,0,0,1-.97,0q-3.786-3.273-7.57-6.548l-.692-.6V25.531c0,.542.226.764.774.764H17.99c.548,0,.77-.222.77-.768V15.193Zm-8.75,5.91,8.354-7.228c-.248-.088-16.526-.074-16.7.01,2.778,2.4,5.55,4.8,8.342,7.216Z" transform="translate(-0.01 -12.569)" fill="#fff"/>
                                </svg>
                                <p><a href="mailto:info@kalyar.com">info@kalyar.com</a></p>
                            </div>
                        </div>
                    )}

                </Container>
                <Container fluid className={'d-flex reveal-down justify-content-between menu-container'} style={{background:updatedMenu === 'updated-menu' ? '#18153A':''}}>

                    <div className="main-menu__left">
                        <div className="main-menu__left__logo">
                            <Link to={'/'}><img src="/images/static/logo.svg" alt=""/></Link>
                        </div>
                    </div>

                    <div className="main-menu__right">
                        <div>
                            <ul>
                                <li><Link
                                    className={url === 'kalyar-replica' || url === 'kalyar-packaging' ? 'active' : ''}>Companies</Link>
                                    <ul>
                                        <li><Link>Kalyar Replica Limited</Link>
                                            <ul className={'sub-sub-menu__ul'}>
                                                <li><Link to={'/kalyar-replica/about'}>About Us</Link></li>
                                                <li><Link to={'/kalyar-replica/management-board'}>Management Board</Link></li>
                                                <li><Link to={'/kalyar-replica/key-personels'}>Key Personnel</Link></li>
                                                <li><Link to={'/kalyar-replica/working-process'}>Working Process</Link></li>
                                                <li><Link to={'/kalyar-replica/end-market'}>End Market</Link></li>
                                                <li><Link to={'/kalyar-replica/product'}>Products</Link></li>
                                                <li><Link to={'/kalyar-replica/technology'}>Technology</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link>Kalyar Packaging Limited</Link>
                                            <ul className={'sub-sub-menu__ul'}>
                                                <li><Link to={'/kalyar-packaging/about'}>About Us</Link></li>
                                                <li><Link to={'/kalyar-packaging/management-board'}>Management Board</Link></li>
                                                <li><Link to={'/kalyar-packaging/key-personels'}>Key Personnel</Link></li>
                                                <li><Link to={'/kalyar-packaging/working-process'}>Working Process</Link></li>
                                                <li><Link to={'/kalyar-packaging/end-market'}>End Market</Link></li>
                                                <li><Link to={'/kalyar-packaging/product'}>Products</Link></li>
                                                <li><Link to={'/kalyar-packaging/technology'}>Technology</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                {/*<li><Link*/}
                                {/*    className={location.pathname === "/investor" ? 'active' : ''}*/}
                                {/*    to={'/investor'}>Investor</Link>*/}
                                {/*</li>*/}
                                {
                                    store?.posts?.[0]?.invest?.show_investor &&
                                    <li><Link
                                        className={location.pathname === "investor" ? 'active' : ''}
                                        to={'/investor'}>Investor</Link>
                                    </li>
                                }

                                <li><Link
                                    className={location.pathname === "/#sustainability" ? 'active' : ''}
                                    to={'/#sustainability'}>Sustainability</Link>
                                </li>
                                <li><Link
                                    className={location.pathname === '/career' ? 'active' : ''}
                                    to={'/career'}>Career</Link>
                                </li>
                                {/*<li><Link to={'/find-us'}>invest</Link></li>*/}
                                <li><Link className={location.pathname === '/media-center' ? 'active' : ''}
                                          to={'/media-center'}>Media</Link></li>
                                {updatedMenu === 'updated-menu' ? (
                                        <li><Link className={location.pathname === '/contact-us' ? 'active' : ''}
                                                  to={'/contact-us'}>Contact Us</Link></li>
                                    )
                                    :
                                    (
                                        ''
                                    )
                                }
                            </ul>

                        </div>
                        {updatedMenu === 'updated-menu' ? (
                            ''
                            )
                            :
                            (
                            <Button src={'/contact-us'} color={'#F9F9F9'}
                                    hoverBackground={'rgba(249,249,249,0.19)'}
                                    background={'transparent'}
                                    borderColor={'#F9F9F9'}
                                    text={'Contact Us'}/>
                            )
                        }
                    </div>
                </Container>
            </StyledComponent>

            {/*mobile menu*/}
            <StyledMobileMenu className='main-menu-mobile'>
                <div className="main-menu-mobile__bar" style={{background:updatedMenu === 'updated-menu' ? '#18153A':''}}>
                    <div className="main-menu-mobile__bar__logo">
                        <Link to={'/'}><img alt='' src={'/images/static/logo.svg'}/></Link>
                    </div>

                    <div className="main-menu-mobile__bar__hamburger">
                        <ul>

                            <li className='hover-here'>

                                <svg id='open-click' xmlns="http://www.w3.org/2000/svg" width="32" height="18"
                                     viewBox="0 0 32 18">
                                    <g id="Group_19820" data-name="Group 19820" transform="translate(-329 -31)">
                                        <line id="Line_3" data-name="Line 3" x2="30" transform="translate(330 32)"
                                              fill="none" stroke="#f2f2f2" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_4" data-name="Line 4" x2="30" transform="translate(330 40)"
                                              fill="none" stroke="#f2f2f2" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_5" data-name="Line 5" x2="30" transform="translate(330 48)"
                                              fill="none" stroke="#f2f2f2" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>


                                <svg id='close-click' xmlns="http://www.w3.org/2000/svg" width="24.042"
                                     height="24.042"
                                     viewBox="0 0 24.042 24.042">
                                    <g id="Group_24004" data-name="Group 24004"
                                       transform="translate(-337.372 -27.979)">
                                        <line id="Line_4" data-name="Line 4" x2="30"
                                              transform="translate(338.787 29.393) rotate(45)" fill="none"
                                              stroke="#f2f2f2" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_12435" data-name="Line 12435" x2="30"
                                              transform="translate(360 29.393) rotate(135)" fill="none"
                                              stroke="#f2f2f2"
                                              stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>


                            </li>
                        </ul>
                    </div>
                </div>

                <div className="main-menu-mobile__items">
                    <ul>
                        <Accordion>
                            {/*<li className='main-item'>*/}
                            {/*    <Link to={'/'}> Companies</Link>*/}
                            {/*    <span/>*/}
                            {/*</li>*/}

                            <div className={'accordion-head-body'}>
                                <Accordion.Header><span>Companies</span> <img
                                    src="/images/static/caret-down.svg"
                                    alt=""/></Accordion.Header>

                                <Accordion.Body>
                                    <li className='main-item'>
                                        Kalyar Replica Limited
                                        <span><img src="/images/static/mb-arrow.svg" alt=""/></span>
                                        <ul>
                                            <p className='sub-menu-back'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28.151" height="23.302" viewBox="0 0 28.151 23.302">
                                                    <g id="Group_24986" data-name="Group 24986" transform="translate(-3788.349 7368.651)">
                                                        <path id="Path_14821" data-name="Path 14821" d="M3779-7364.362l-10.237,10.237L3779-7343.888" transform="translate(21 -2.875)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_12502" data-name="Line 12502" x2="25" transform="translate(3790.5 -7357)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                </svg>


                                                <strong>About Us</strong>
                                            </p>
                                            {/*<h3>AboutPackaging Us</h3>*/}

                                            <li><Link to={'/kalyar-replica/about'}>About Us</Link></li>
                                            <li><Link to={'/kalyar-replica/management-board'}>Management Board</Link></li>
                                            <li><Link to={'/kalyar-replica/key-personels'}>Key Personnel</Link></li>
                                            <li><Link to={'/kalyar-replica/working-process'}>Working Process</Link></li>
                                            <li><Link to={'/kalyar-replica/end-market'}>End Market</Link></li>
                                            <li><Link to={'/kalyar-replica/product'}>Products</Link></li>
                                            <li><Link to={'/kalyar-replica/technology'}>Technology</Link></li>

                                        </ul>
                                    </li>
                                    <li className='main-item'>
                                        Kalyar Packaging Limited
                                        <span><img src="/images/static/mb-arrow.svg" alt=""/></span>
                                        <ul>
                                            <p className='sub-menu-back'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28.151" height="23.302" viewBox="0 0 28.151 23.302">
                                                    <g id="Group_24986" data-name="Group 24986" transform="translate(-3788.349 7368.651)">
                                                        <path id="Path_14821" data-name="Path 14821" d="M3779-7364.362l-10.237,10.237L3779-7343.888" transform="translate(21 -2.875)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                        <line id="Line_12502" data-name="Line 12502" x2="25" transform="translate(3790.5 -7357)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                                    </g>
                                                </svg>


                                                <strong>About Us</strong>
                                            </p>
                                            {/*<h3>AboutPackaging Us</h3>*/}

                                            <li><Link to={'/kalyar-packaging/about'}>About Us</Link></li>
                                            <li><Link to={'/kalyar-packaging/management-board'}>Management Board</Link></li>
                                            <li><Link to={'/kalyar-packaging/key-personels'}>Key Personnel</Link></li>
                                            <li><Link to={'/kalyar-packaging/working-process'}>Working Process</Link></li>
                                            <li><Link to={'/kalyar-packaging/end-market'}>End Market</Link></li>
                                            <li><Link to={'/kalyar-packaging/product'}>Products</Link></li>
                                            <li><Link to={'/kalyar-packaging/technology'}>Technology</Link></li>

                                        </ul>
                                    </li>
                                </Accordion.Body>
                            </div>

                            {/*<li className='main-item'><Link to={'/investor'}>Investor</Link></li>*/}
                            {
                                store?.posts?.[0]?.invest?.show_investor &&
                                <li className='main-item'><Link to={'/investor'}>Investor</Link></li>
                            }
                            <li className='main-item'><Link to={'/career'}>Career</Link></li>
                            <li className='main-item'><Link to={'/media-center'}>Media</Link></li>
                            <li className='main-item'><Link to={'/contact-us'}>Contact Us</Link></li>
                        </Accordion>
                    </ul>
                </div>

            </StyledMobileMenu>


            {/*bootstrap modal */}
        </>

    );
};

// desktop menu
const StyledComponent = styled.section`
  height: 80px;
  //background-color: #fff;
  //backdrop-filter: blur(5px);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: .5s ease;


  .container-fluid {
    padding: unset;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(255, 255, 255, 0));
  }


  .menu-top {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    gap: 45px;
    background: #18153A;
    padding-right: ${props => props.offset || '100'}px !important;

    &__number {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        margin-bottom: 0;
      }

      a {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 0;
        &:hover{
          color: rgba(255, 255, 255, 0.5) !important;
        }
      }
    }

    &__email {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        margin-bottom: 0;
      }

      a {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.8);
        &:hover{
          color: rgba(255, 255, 255, 0.5) !important;
        }
      }
    }
  }

  @media (max-width: 767px) {
    display: none !important;
  }

  .main-menu__left {
    padding-left: ${props => props.offset || '100'}px !important;
    display: flex;
    align-items: center;

    &__logo {
      margin-right: 60px;
    }
  }

  .main-menu__right {
    padding-right: ${props => props.offset || '100'}px !important;
    display: flex;
    align-items: center;

    ul {
      &:not(li ul) {
        display: flex;
      }

      li {
        transition: transform 0.4s ease;

        a {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          position: relative;
          width: max-content;
          color: white;
          height: 80px;
          display: flex;
          align-items: center;
          text-transform: capitalize;

          &.active {
            color: rgba(255, 255, 255, 0.7);
          }
        }

        a {
          margin-right: 40px;
        }

        ul {
          position: absolute;
          background-color: #120F2A;
          min-width: 280px;
          visibility: hidden;
          opacity: 0;
          transition: opacity .6s ease;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
          padding: 15px 0;
          border: 0.25px solid rgb(255 255 255 / 10%);

          li {
            position: relative;
            padding: 0 15px;


            a {
              width: 100%;
              font-weight: 500;
              color: ${text};
              height: fit-content;
              margin-right: 0 !important;
              opacity: 70%;
              padding: 12px;
              &:hover {
                opacity: 100%;
                color: white !important;
              }
            }

            .sub-sub-menu__ul {
              position: absolute;
              background-color: #120F2A;
              min-width: 280px;
              visibility: hidden;
              opacity: 0;
              transition: opacity .6s ease;
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
              top: -18px;
              left: 100%;
              padding: 15px 0;

              li {
                a {
                  width: 100%;
                  font-weight: 500;
                  color: ${text};
                  height: fit-content;
                  margin-right: 0 !important;

                  &:hover {
                    color: white !important;
                  }
                }

                &:not(:nth-last-child(1)) a {
                  border-bottom: 1px solid rgba(60, 60, 59, 0.30);
                  padding-bottom: 12px;
                  margin-bottom: 17px;
                }
              }
            }

            &:hover {
              .sub-sub-menu {
                &__ul {
                  visibility: visible;
                  opacity: 1;
                  transition: opacity .6s ease;
                }
              }
            }

            &:not(:nth-last-child(1)) a {
              border-bottom: 1px solid rgba(60, 60, 59, 0.30);
              
            }
          }
        }

        &:hover {
          ul {
            visibility: visible;
            opacity: 1;
            transition: opacity .6s ease;

            a {
              &:after {
                content: unset;
              }
            }
          }
        }

        &:hover {
          transform: translateY(3px);
          transition: transform 0.4s ease;

          a {
            color: rgba(255, 255, 255, 0.7) !important;

            &:after {
              content: '';
              position: absolute;
              bottom: 25%;
              left: 50%;
              width: 4px;
              height: 4px;
              background-color: #ffffff;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1150px) {
    .main-menu__left__logo {
      margin-right: 30px;

      img {
        height: 30px;
      }
    }

    .main-menu__right ul li {
      a {
        font-size: 15px;
      }

      &:not(:nth-last-child(1)) a {
        margin-right: 25px;
      }
    }

    .dc-btn {


      a {
        padding-right: 15px;
        padding-left: 15px;
        font-size: 14px;
      }
    }

  }

  @media (max-width: 950px) {
    display: none;
  }

`;

// mobile menu

const StyledMobileMenu = styled.section`
  position: fixed;
  width: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
  //height: 100vh;
  //overflow-x: hidden;
  //overflow-y: auto;
  //background-color: #fff;
  //display: none;
  //transition: all .4s ease;

  @media (min-width: 991px) {
    display: none;
  }

  .main-menu-mobile__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(242, 242, 242, 0.5);
    padding: 0 15px;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background-color: rgba(34, 34, 34, 0.40);
    z-index: 999;
    transition: all .4s ease;
    height: 80px;

    &__hamburger {
      ul {
        display: flex;
        height: 90px;
        align-items: center;

        li {
          //&:nth-of-type(1) {
          //  margin-right: 15px;
          //}

          svg {
            &:nth-of-type(2) {
              display: none;
            }
          }
        }
      }
    }


  }


  .main-menu-mobile__items {
    padding: 0 15px;
    position: relative;
    transform-origin: top left;
    transition: all .3s ease-out;
    //display: none;
    height: calc(100vh);
    //margin-top: 170px;
    //overflow-x: hidden;
    //overflow-y: auto;
    overflow-y: auto;
    padding-bottom: 10px;
    padding-top: 120px;
    overflow-x: hidden;
    background-color: #18153A;
    width: 200vw;
    transform: translateX(100%);
    display: none;

    ul {
      width: 100%;

      .accordion {
        padding-bottom: 30px;
        .accordion-head-body{
          width: 48%;
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(198, 198, 198, 0.5);
          
        }

        .accordion-header {
          button {
            background: unset;
            border: unset;
            display: flex;
            align-items: center;
            padding: 0 0 0 0;
            justify-content: space-between;
            width: 100%;

            &.collapsed {
              img {
                transform: rotate(180deg);
              }
            }

            span {
              font-size: 24px;
              line-height: 32px;
              font-weight: 300;
              color: #fff;
            }
          }
        }

        .accordion-collapse {
          .accordion-body {
            padding:40px 0 20px 0;
            .main-item {
              color:#9F9FBF;
              ul {
                display: none;
              }
            }

            .active {
              ul {
                display: block;
              }
            }
          }
        }
      }

      li {
        text-transform: capitalize;
        display: block;
        width: 100%;
        color: #f2f2f2;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        //position: relative;


        &.main-item {
          //border-bottom: 1px solid rgba(198, 198, 198, 0.5);
          //padding-bottom: 15px;


          span {
            content: '';
            width: calc(100vw - 30px);
            background-color: rgba(198, 198, 198, 0.5);
            //position: absolute;
            //bottom: 0;
            //left: 0;
            height: 1px;
            display: block;
            margin-top: 10px;
            position: relative;

            img {
              position: absolute;
              right: 0;
              bottom: 15px;
            }
          }

          &:nth-last-child(1) span {
            background-color: transparent;
          }
        }

        // &:hover {
        //     // color: ${hover};
        //   opacity: 70%;
        // }

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 20px;

        }

        a {
          color: #f2f2f2;
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
          font-family: "Blacker Pro Display";
        }

        //sub menus
        ul {
          left: 0;
          list-style: none;
          margin: 0;
          position: absolute;
          top: 130px;
          padding: 0 15px;
          //height: 100vh;
          opacity: 0;
          transform: translate3d(100vw, 0, 0);
          transition: all .3s ease;
          //padding-bottom: 40px;
          width: 100vw;
          padding-bottom: 25px;

          p {
            margin-bottom: 35px;

            svg {
              margin-top: -5px;
            }

            strong {
              border-color: transparent !important;
              font-size: 20px;
              font-weight: 500;
              color: white;
              padding-left: 20px;
              line-height: 28px;
            }

          }

          h3 {
            font-size: 30px;
            line-height: 40px;
            font-weight: 400;
            font-family: ${title};
            margin: 0;
            text-transform: capitalize;
            padding-bottom: 20px;
            border-bottom: 1px solid ${hover};
            margin-bottom: 40px;
          }

          li {
            &:not(:nth-last-of-type(1)) {
              //margin-bottom: 30px;
              margin-bottom: 20px;

              a {
                border-bottom: 1px solid rgba(198, 198, 198, 0.5);
              }
            }

            a {
              font-size: 20px;
              line-height: 28px;
              text-transform: capitalize;
              display: block;
              width: 100%;

              padding-bottom: 15px;
              color: #9F9FBF;
            }

          }
        }

        &.active {
          ul {
            opacity: 1;
            z-index: 2;
          }
        }

      }
    }

    &.active {
      transform: translate3d(-100vw, 0, 0) !important;
    }
  }

  //toggle action
  &.menu-open {
    .main-menu-mobile__bar {
      background-color: #18153A;

      &__logo {
        img {
            // content: url(${"LogoBlack"});
        }
      }

      &__hamburger {
        li:nth-of-type(1) {
          svg {
            g {
              stroke: ${text};
            }

            path {
              fill: ${text};
            }
          }
        }

        #open-click {
          display: none;
        }

        #close-click {
          display: block;
        }
      }
    }
  }
`;

export default React.memo(MyComponent);
