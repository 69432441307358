import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import BlogList from "../../components/media/BlogList";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchMediaDetail, fetchMediaList} from "../../api/redux/Media";
import {ApiParam} from "../../api/network/apiParams";
import {motion} from "framer-motion"
import {PageAnimation} from "../../components/PageAnimation";
import {useLocation} from "react-router-dom";

const MediaCenterListing = () => {
    const [selectedCategory, setSelectedCategory] = useState('All')
    const dispatch = useDispatch();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIA;
        let api_params = {
            [ApiParam.CATEGORY]: selectedCategory === 'All' ? '' : selectedCategory.value || selectedCategory,
        };
        dispatch(fetchMediaList([api_url, api_params]));
    }, [selectedCategory, location]);



    let getPost = useSelector(state => state.mediaReducer);


    let data = getPost?.posts?.[0];
    let innerBanner = data?.banner;
    const list = getPost?.posts?.[0]?.list;
    const category = data?.filter?.category;


    const handleCategoryItem = (value) => {
        setSelectedCategory(value)
    }

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.page_meta?.meta_title ? data?.page_meta?.meta_title + ' | ' + "Kalyar" : innerBanner?.title + ' | ' + "Kalyar"}`}</title>
                {
                    data?.page_meta?.meta_desc &&
                    <meta name="description" content={data?.page_meta?.meta_desc}/>

                }
                {
                    data?.page_meta?.og_title &&
                    <meta property="og:title" content={data?.page_meta?.og_title}/>

                }
                {
                    data?.page_meta?.og_desc &&
                    <meta property="og:description" content={data?.page_meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>

                    <InnerBanner
                        img={innerBanner?.image}
                        title={innerBanner?.title}
                        subtitle={innerBanner?.subtitle}
                    />
                <BlogList
                    data={list}
                    setSelectedCategory={setSelectedCategory}
                    handleCategoryItem={handleCategoryItem}
                    cat={category}
                    loading={isLoading}
                />
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MediaCenterListing;
