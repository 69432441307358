import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/ContactUs";

const MyComponent = ({data}) => {

    //Form Validation & Submit
    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contactReducer);

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };


    const onSubmit = (e) => {

        let api_services = apiEndPoints.CONTACT_FORM;

        var formData = new FormData();
        formData.append('first-name', e?.firstname);
        formData.append('last-name', e?.lastname);
        formData.append('your-email', e?.email);
        formData.append('your-number', e?.phone);
        formData.append('your-message', e?.text);

        if (e.email !== '' && e.firstname !== '' && e.lastname !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            // success('Successfully Submitted')
            reset();
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error?.message)
        }
        if (responseData && responseData?.success !== '') {
            success(responseData?.success)

        }
        reset();

    }, [responseData])


    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Title text={data?.title ? data?.title : ''} color={'#262630'} margin={'0 0 60px'}/>
                <Row>
                    <Col md={4} className={'left'}>
                        <p className={'split-up'}>
                            {reactHtmlParser(data?.desc ? data?.desc : '')}
                        </p>
                    </Col>
                    <Col md={8}>
                        <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>
                            <input name={'spam_protector'} type='hidden'/>

                            <Col sm={6}>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className={formState?.errors?.firstname?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("firstname", {
                                                      required: 'First Name is required',
                                                  })}
                                                   type="text" placeholder="First Name*"/>
                                </Form.Group>

                            </Col>

                            <Col sm={6}>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className={formState?.errors?.lastname?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("lastname", {
                                                      required: 'Last Name is required',
                                                  })}
                                                  type="text" placeholder="Last Name*"/>
                                </Form.Group>

                            </Col>

                            <Col sm={6}>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("phone", {
                                                      required: {
                                                          value: true, message: 'please enter your phone first'
                                                      }, pattern: {
                                                          value: /^01[0-9]{9}$/,
                                                          message: 'please enter a valid 11 digit phone number'
                                                      }
                                                  })}
                                                  name='phone' type={'number'} placeholder="Phone*"/>
                                </Form.Group>

                            </Col>

                            <Col sm={6}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("email", {
                                                      required: {
                                                          value: true, message: 'please enter your email'
                                                      }, pattern: {
                                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                          message: 'please enter a valid email address'
                                                      }
                                                  })}
                                                  type="email"
                                                   placeholder="Email*"/>
                                </Form.Group>
                            </Col>


                            <Col sm={12}>
                                {/*<textarea name="" id="" placeholder={'Message*'}/>*/}
                                <Form.Control  className={formState?.errors?.text?.message ? ' form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
                                               {...register('text', {
                                                   // required: {
                                                   //     // value:true,
                                                   //     message: 'please enter your Message',
                                                   // },
                                               })}
                                              as="textarea"
                                              placeholder={'Message'}/>
                            </Col>

                            <Col sm={6} className={'sub-btn'}>
                                <div onClick={handleSubmit(onSubmit, onError)}>
                                    <Button width={'300'} borderColor={'#180E81'} color={'#180E81'} text={'Submit'}/>
                                </div>
                            </Col>

                        </Form>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #F9F9F9;
 
  .left{
    p{
      width: 85%;
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
      color: rgb(24 21 58 / 70%);
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    .form-control {
      border: none;
      border-bottom: 1px solid rgb(38 38 48 / 20%);
      background-color: transparent;
      border-radius: 0px;
      font-family: ${button};
      font-size: 16px;
      line-height: 24px;
      //color: rgb(38 38 48 / 20%);
      padding: 0 0 10px !important;
      margin-bottom: 40px;
    }

    ::placeholder {
      color: rgb(38 38 48 / 20%) !important;
      font-size: 16px;
      font-weight: 300;
    }

    textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgb(38 38 48 / 20%);
      background-color: transparent;
      border-radius: 0px;
      font-size: 16px;
      max-height: 110px;
      min-height: 110px;
      min-width: 100%;
      max-width: 100%;
      outline: none;
      //color: rgb(38 38 48 / 20%);
      margin-top: 10px;
      margin-bottom: 40px;
    }

    .css-yk16xz-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid rgb(38 38 48 / 20%) !important;
    }

    .filter {
      &__placeholder {
        color: rgb(38 38 48 / 20%) !important;
        font-size: 16px;
        font-weight: 300;
      }

      &__control {
        padding-left: 0;
        padding-right: 0;

        &--is-focused {
          background-color: transparent !important;
          border: none;
          border-bottom: 1px solid rgb(38 38 48 / 20%);
        }
      }


      &__indicator {
        margin-right: 0;
        padding: 0;
      }

      &__value-container {
        padding-left: 0;
        padding-right: 0;
        cursor: pointer !important;
      }
    }

    .css-1uccc91-singleValue {
      color: rgb(38 38 48 / 20%) !important;
    }
    
    .sub-btn{
      padding: 0;
    }
    
    .dc-btn{
      a{
        padding: 12px 176px!important;
        @media(max-width: 767px){
          padding: 12px 155px!important;
        }
      }
    }

    @media (max-width: 991px) {
      padding: 0 !important;
    }

    @media (max-width: 575px) {
      .filter__control {
        margin-bottom: 25px !important;
      }
    }
    @media(max-width: 767px){
      padding-top: 40px !important;
    }
  }

  .form-divide {
    display: flex;
    width: 100%;
  }
`;

export default MyComponent;
