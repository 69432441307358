import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Button from "../Button";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import {button} from "../../styles/globalStyleVars";
import {useLocation, useParams} from "react-router-dom";

const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    return (
        <StyledComponent>
            <Container fluid className={"packaging"} offset={offset}>
                {
                    data && data?.length >0 && data?.map((e, i)=>{
                        return(
                            <Row key={i} id={e?.button} className={i % 2 === 1 ? 'even-row' : 'odd-row'} >
                                <Col md={6}  className={'packaging__left'} style={{paddingLeft: i % 2 === 0 ? offset + 'px' : '85px', paddingRight: i % 2 === 1 ? offset +  'px' : '85px'}}>
                                    <div className={'packaging__left__wrapper'}>
                                        <img src={e?.svg}/>
                                        <h4 className={'split-up'}>{e?.title}</h4>
                                        <p>{reactHtmlParser(e?.description)}</p>
                                    </div>
                                </Col>
                                <Col md={6} className={'packaging__right'} style={{paddingRight: i % 2 === 0 ? offset + 'px' : '0', paddingLeft: i % 2 === 1 ? offset +  'px' : '0'}}>
                                    <div className={'packaging__right__wrapper'}>
                                        <Img src={e?.image}/>
                                    </div>
                                </Col>
                            </Row>
                        );
                    })
                }

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .packaging{
      .row{
        &:nth-child(even){
          flex-direction: row-reverse;
        }
      }
      &__left{
        padding-top: 60px;
        background-color: #F9F9F9;
        &__wrapper{
          
          img{}
          h4{
            margin-top: 30px;
            margin-bottom: 40px;
            color: #262630;
            
          }
          p{
            font-family: ${button};
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: rgb(38 38 48);
          }
        }
      }
      &__right{
        &__wrapper{
          position: relative;
          padding-top: calc(600 / 570 * 100%);
        }
      }
      @media(max-width: 767px){
        &__left{
          padding-left: 15px !important;
          padding-right: 15px !important;
          &__wrapper{
            padding-bottom: 40px;
          }
        }
        &__right{
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }
    }
`;

export default MyComponent;
