import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import Button from "../career/Button";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postCareerForm} from "../../api/redux/Career";
import ButtonCareer from "../ButtonCareer";

const MyComponent = ({data}) => {
    const [cv, setCv] = useState(null);
    const [photo, setPhoto] = useState(null);
    const cvRef = useRef();
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedPhotoName, setSelectedPhotoName] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setCv(file);
        setSelectedFileName(file.name);
    };

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        setPhoto(file);
        setSelectedPhotoName(file.name);
    };


    const dispatch = useDispatch();
    const responseData = useSelector((state) => state.careerReducer);

    const { register, handleSubmit, formState, reset } = useForm({ mode: 'all' });

    const success = (msg) => {
        toast.success(msg, {
            position: 'top-right',
            autoClose: 4000,
            closeOnClick: true,
            progress: undefined,
        });
    };

    const error = (msg) => {
        toast.error(msg, {
            position: 'top-right',
            autoClose: 4000,
            closeOnClick: true,
            progress: undefined,
        });
    };

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };

    const onSubmit = (e) => {
        let api_services = apiEndPoints.CAREER_FORM;

        var formData = new FormData();
        formData.append('first-name', e?.firstname);
        formData.append('last-name', e?.lastname);
        formData.append('your-email', e?.email);
        formData.append('your-number', e?.phone);
        formData.append('your-message', e?.text);
        formData.append('file-795', cv);
        formData.append('file-576', photo);

        if (e.email !== '' && e.firstname !== '' && e.lastname !== '' && e.phone !== '') {
            dispatch(postCareerForm([api_services, formData]));
            reset();
            // Reset file names to default values
            setSelectedFileName('');
            setSelectedPhotoName('');
        }
    };

    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs with Photo and CV');
        }
        count = 0;
    };

    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error?.message);
        }
        if (responseData && responseData?.success !== '') {
            success(responseData?.success);
        }
        reset();
    }, [responseData]);
    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Title text={data?.title} color={'#262630'} margin={'0 0 60px'}/>
                <Row>
                    <Col md={4} className={'left'}>
                        <p>
                            {reactHtmlParser(data?.desc)}
                        </p>
                    </Col>
                    <Col md={8}>
                        <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>
                            <input name={'spam_protector'} type='hidden'/>

                            <Col sm={6}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className={formState?.errors?.firstname?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("firstname", {
                                                      required: 'First Name is required',
                                                  })}
                                                  type="text" placeholder="First Name*"/>
                                </Form.Group>


                            </Col>

                            <Col sm={6}>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className={formState?.errors?.lastname?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("lastname", {
                                                      required: 'Last Name is required',
                                                  })}
                                                  type="text" placeholder="Last Name*"/>
                                </Form.Group>

                            </Col>

                            <Col sm={6}>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("phone", {
                                                      required: {
                                                          value: true, message: 'please enter your phone first'
                                                      }, pattern: {
                                                          value: /^01[0-9]{9}$/,
                                                          message: 'please enter a valid 11 digit phone number'
                                                      }
                                                  })}
                                                  name='phone' type={'number'} placeholder="Phone*"/>
                                </Form.Group>

                            </Col>

                            <Col sm={6}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("email", {
                                                      required: {
                                                          value: true, message: 'please enter your email'
                                                      }, pattern: {
                                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                          message: 'please enter a valid email address'
                                                      }
                                                  })}
                                                  type="email"
                                                  placeholder="Email*"/>
                                </Form.Group>
                            </Col>


                            <Col sm={12}>
                                <Form.Control  className={formState?.errors?.text?.message ? ' form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
                                               {...register('text', {
                                                   // required: {
                                                   //     // value:true,
                                                   //     message: 'please enter your Message',
                                                   // },
                                               })}
                                               as="textarea"
                                               placeholder={'Message*'}/>
                            </Col>
                            <Col sm={6}>
                                <div className="attachCvName">
                                    <div className={"attach-cv"}>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <path id="avatar" d="M15,5A10,10,0,1,0,25,15,10.011,10.011,0,0,0,15,5Zm4.211,17.891a8.916,8.916,0,0,1-8.421,0V18.684a2.1,2.1,0,0,1,1.1-1.839l2.737,2.737a.526.526,0,0,0,.744,0l2.737-2.737a2.1,2.1,0,0,1,1.1,1.839ZM15,15.526a3.158,3.158,0,1,1,3.158-3.158A3.162,3.162,0,0,1,15,15.526Zm1.887,1.053L15,18.466l-1.887-1.887Zm3.376,5.641V18.684a3.163,3.163,0,0,0-2.553-3.1,4.211,4.211,0,1,0-5.421,0,3.162,3.162,0,0,0-2.553,3.1v3.535a8.947,8.947,0,1,1,10.526,0Z" transform="translate(-5 -5)" fill="#180E81"/>
                                        </svg>

                                        <Form.Control
                                            {...register('photo',{ required: true })}
                                            type="file"
                                            accept=".jpeg, .jpg, .png"
                                            id="photo-upload"
                                            style={{display: "none"}}
                                            // onChange={handlePhotoChange}
                                            onChange={handlePhotoChange}
                                        />
                                        <Form.Label htmlFor="photo-upload" className="photo-upload-label"
                                                    style={{display: selectedPhotoName ? "none" : "block"}}>
                                            Upload Photo
                                        </Form.Label>
                                        {selectedPhotoName && (
                                            <div className="file-name">
                                                {selectedPhotoName}
                                            </div>
                                        )}
                                    </div>
                                    <div className="attach-cv">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                                            <g id="Group_24975" data-name="Group 24975" transform="translate(-133.895 -717.5)">
                                                <path id="Path_1748" data-name="Path 1748" d="M746.26,1223.491l-4.428,4.428a4.133,4.133,0,0,0,0,5.846h0a4.133,4.133,0,0,0,5.846,0l4.428-4.428" transform="translate(-606.226 -496.976)" fill="none" stroke="#180e81" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                <path id="Path_1749" data-name="Path 1749" d="M762.116,1219.434l4.3-4.3a4.133,4.133,0,0,0,0-5.846h0a4.133,4.133,0,0,0-5.846,0l-4.3,4.3" transform="translate(-613.228 -490.081)" fill="none" stroke="#180e81" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                <line id="Line_67" data-name="Line 67" y1="5.091" x2="5.091" transform="translate(141.85 725.454)" fill="none" stroke="#180e81" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                            </g>
                                        </svg>


                                        <Form.Control
                                            ref={cvRef}
                                            {...register('cv',{ required: true })}
                                            type="file"
                                            accept=".pdf"
                                            id="resume-upload"
                                            style={{ display: "none" }}
                                            onChange={handleFileChange}
                                        />
                                        <Form.Label htmlFor="resume-upload" className="resume-upload-label"
                                                    style={{display: selectedFileName ? "none" : "block"}}>
                                            Upload CV
                                        </Form.Label>
                                        {selectedFileName && (
                                            <div className="file-name">
                                                {selectedFileName}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}></Col>
                            <Col sm={6} className={'sub-btn'}>
                                <div onClick={handleSubmit(onSubmit, onError)} className={'btn'}>
                                    <ButtonCareer width={'300'} borderColor={'#180E81'} color={'#180E81'} text={'Submit'}/>
                                </div>
                            </Col>

                        </Form>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #F9F9F9;
 
  .left{
    p{
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
      color: rgb(24 21 58 / 70%);
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    
    .col-sm-6,.col-sm-12{
      padding: unset;
    }

    .form-control {
      border: none;
      border-bottom: 1px solid rgb(38 38 48 / 20%);
      background-color: transparent;
      border-radius: 0px;
      font-family: ${button};
      font-size: 16px;
      line-height: 24px;
      //color: rgb(38 38 48 / 20%);
      padding: 0 0 10px !important;
      margin-bottom: 40px;
    }

    ::placeholder {
      color: rgb(38 38 48 / 20%) !important;
      font-size: 16px;
      font-weight: 300;
    }

    textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgb(38 38 48 / 20%);
      background-color: transparent;
      border-radius: 0px;
      font-size: 16px;
      max-height: 110px;
      min-height: 110px;
      min-width: 100%;
      max-width: 100%;
      outline: none;
      //color: rgb(38 38 48 / 20%);
      margin-top: 10px;
      margin-bottom: 40px;
    }

    .css-yk16xz-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid rgb(38 38 48 / 20%) !important;
    }

    .filter {
      &__placeholder {
        color: rgb(38 38 48 / 20%) !important;
        font-size: 16px;
        font-weight: 300;
      }

      &__control {
        padding-left: 0;
        padding-right: 0;

        &--is-focused {
          background-color: transparent !important;
          border: none;
          border-bottom: 1px solid rgb(38 38 48 / 20%);
        }
      }


      &__indicator {
        margin-right: 0;
        padding: 0;
      }

      &__value-container {
        padding-left: 0;
        padding-right: 0;
        cursor: pointer !important;
      }
    }

    .css-1uccc91-singleValue {
      color: rgb(38 38 48 / 20%) !important;
    }
    
    .sub-btn{
    }
    
    .dc-btn{
      a{
        padding: 12px 176px!important;
        @media(max-width: 767px){
          padding: 12px 155px!important;
        }
      }
    }

    @media (max-width: 991px) {
      padding: 0 !important;
    }

    @media (max-width: 575px) {
      .filter__control {
        margin-bottom: 25px !important;
      }
    }

    .button-group{
      //display: flex;
      //gap: 40px;
      margin-bottom: 10px;

      
      
    }
    .attachCvName{
      display: flex;
      gap: 35px;
      .attach-cv{
        position: relative;
        overflow: hidden;
        //width: 155px;
        padding: 12px 32px;
        cursor: pointer;
        border: 1px solid #180E81;
        border-radius: 19px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background-color: transparent;
        margin-bottom: 30px;
        z-index: 2;
        label{
          margin-bottom: 0;
          cursor: pointer;
          color: #180E81;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          font-family: ${button};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        svg{
          #avatar{
            fill: #180E81 !important;
          }
          
        }
        &:after {
          content: '';
          position: absolute;
          height: 0;
          width: 0;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #180E81;
          transition: all .5s ease;
          //border-radius: 25px;
          border-radius: 25px;
        }

        &:hover {
          border: none;
          svg{
            position: relative;
            z-index: 2;
            #avatar{
              fill: #ffffff !important;
            }
            g{
              path{
                fill: #ffffff;
              }
              line{
                stroke: #ffffff;
              }
            }
            
          }
          label{
            position: relative;
            z-index: 2;
            color: #ffffff;
          }
          &:after {
            height: 100%;
            width: 100%;
          }
          .file-name{
            color: #ffffff;
            position: relative;
            z-index: 3;
          }
        }
        @media(max-width: 767px){
          justify-content: center;
        }
      }
      @media(max-width: 767px){
        flex-direction: row;
        gap: 15px;
      }
    }
    .file-name{
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-family: ${button};
      opacity: 0.8;
      color: #180E81;
      padding-left: 10px;
    }

    @media(max-width: 767px){
      padding-top: 40px !important;
    }
  }

  .form-divide {
    display: flex;
    width: 100%;
  }
  
  .btn{
    padding: 0 !important;
    @media(max-width: 767px){
      padding: 0 !important;
      position: relative;
      .dc-btn{
        a{
          position: unset;
        }
      }
    }
  }
  
`;

export default MyComponent;
