import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {ListGroup, Container, Row, Table, DropdownButton, Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";

// Internal Component
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import {button} from "../../styles/globalStyleVars";


const ListsV1 = ({ data, router }) => {
    const [activeTab, setActiveTab] = useState({ title: '', description: '', lists: [] });

    const handleTabClick = (event) => {
        const selectedTab = event.target.innerText;
        const selectedData = data.find((item) => item.title === selectedTab);
        setActiveTab({
            title: selectedTab,
            description: selectedData?.description || '',
            lists: selectedData?.list ? selectedData.list.split(',') : [],
        });
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const initialTab = data[0].title;
            const initialData = data.find((item) => item.title === initialTab);
            setActiveTab({
                title: initialTab,
                description: initialData?.description || '',
                lists: initialData?.list ? initialData.list.split(',') : [],
            });
        }

        setTimeout(() => {
            const firstListItem = document.querySelector('.product-specification-ul li');
            if (firstListItem) {
                firstListItem?.classList.add('active');
            }
        }, 200);

        const listItems = document.querySelectorAll('.product-specification-ul li');
        listItems.forEach((item) => {
            item.addEventListener('click', () => {
                document.querySelector('.product-specification-ul li.active')?.classList.remove('active');
            });
        });

        document.querySelector('.dropdown').addEventListener('click', () => {
            setTimeout(() => {
                const dropdownItem = document.querySelector('.dropdown-item');
                if (!document.querySelector('.dropdown .active')) {
                    dropdownItem?.classList.add('active');
                } else {
                    dropdownItem?.classList.remove('active');
                }
            }, 200);
        });
    }, [data]);

    return (
        <StyledListsV1>
            <Container>
                <div className={'product-specification-head'}>
                    <ListGroup horizontal className={'product-specification-ul'}>
                        {data &&
                            data?.length > 0 &&
                            data?.map((item, index) => (
                                <ListGroup.Item
                                    as={'li'}
                                    key={index}
                                    className={activeTab.title === item.title ? 'active' : ''}
                                    onClick={handleTabClick}
                                >
                                    <span>{reactHtmlParser(item?.title)}</span>
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                    <DropdownButton
                        as={'ul'}
                        className={'product-specification-ul__mobile'}
                        title={activeTab.title || (data && data[0]?.title) || 'Select'}
                        onClick={handleTabClick}
                    >
                        {data &&
                            data?.map((item, index) => (
                                <Dropdown.Item
                                    key={index}
                                    as={'li'}
                                    className={activeTab.title === item.title ? 'active' : ''}
                                >
                                    <span>{reactHtmlParser(item?.title)}</span>
                                </Dropdown.Item>
                            ))}
                    </DropdownButton>
                </div>
                <div className={'product-specification-body'}>
                    <p>
                        {reactHtmlParser(activeTab.description)}

                    </p>
                    <ul>
                        {activeTab?.lists?.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            </Container>
        </StyledListsV1>
    );
};

const StyledListsV1 = styled.div`
  padding-top: 160px;
  //padding-bottom: 160px;

  .product-specification-head {
    .product-specification-ul {
      .list-group-item {
        padding: 0;
        border: 0;
        background-color: transparent;
        border-bottom: 1px solid #180E81;
        border-radius: 0;

        &:not(&:last-child) {
          border-right: 1px solid #180E81;
        }

        &.active {
          background-color: #180E81;

          span {
            color: #ffffff;
          }
        }
        
        &:hover{
          span{
            color: white !important; 
          }
        }

        span {
          font-family: ${button};
          font-size: 20px;
          line-height: 26px;
          font-weight: 500;
          padding: 30px 40px;
          display: flex;
          color: #221F1F;
          height: 100%;
          position: relative;
          cursor: pointer;

          &:before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            height: 0;
            background-color: #180E81;
            width: 100%;
            transition: height .2s ease-in;
          }

          &:hover {
            color: #ffffff !important;

            span {
              z-index: 1;
              color: #ffffff
            }

            &:before {
              height: 100%;
              z-index: -1;
            }
          }
        }
      }

      &__mobile {
        display: none;
      }
    }
  }

  .product-specification-body {
    padding-top: 60px;
    width: 100%;

    p {
      font-family: ${button};
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: rgb(38 38 48 / 70%);
      width: 75%;
    }
    
    ul{
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin-top: 60px;
      li{
        flex: 0 0 30%;
        color: rgb(38 38 48 / 70%);
        box-sizing: border-box;
        font-family: ${button};
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        padding-bottom: 15px;
        border-bottom: 1px solid rgb(121 121 151 / 30%);
        padding-left: 25px;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          height: 10px;
          width: 10px;
          padding: 0px;
          background: rgb(24, 14, 129);
          border-radius: 50%;
          color: white;
          top: 7px;
          left: 0px;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
        }
        }
      }
    @media(max-width: 767px){
      p{
       width: 100%; 
      }
      ul{
        li{
          flex: 100%;
        }
      }
    }

    //&__desc {
    //  padding-bottom: 85px;
    //
    //  p {
    //    font-size: 16px;
    //    line-height: 22px;
    //    font-weight: 600;
    //    color: #221F1F;
    //  }
    //
    //  ul {
    //    margin-top: 40px;
    //    margin-bottom: 60px;
    //
    //    li {
    //      font-size: 16px;
    //      line-height: 22px;
    //      font-weight: 600;
    //      position: relative;
    //      padding: 20px 0 20px 30px;
    //      display: flex;
    //      align-items: center;
    //
    //      &:not(:last-child) {
    //        border-bottom: 1px solid #7F7F7F;
    //      }
    //
    //      &::before {
    //        content: '';
    //        position: absolute;
    //        left: 0;
    //        width: 8px;
    //        height: 8px;
    //        border-radius: 50%;
    //        background-color: #ED1B34;
    //      }
    //    }
    //  }
    //}
    //
    //table {
    //  thead {
    //    th {
    //      border-width: 1px;
    //      border-top: 0;
    //      border-color: #7F7F7F;
    //      font-size: 12px;
    //      line-height: 18px;
    //      color: rgba(34, 31, 31, 0.3);
    //      font-weight: 600;
    //      padding-bottom: 20px;
    //    }
    //  }
    //
    //  tbody {
    //    td, p {
    //      padding-top: 20px;
    //      padding-bottom: 20px;
    //      border-color: #7F7F7F;
    //      font-size: 16px;
    //      line-height: 22px;
    //      font-weight: 600;
    //      color: rgba(34, 31, 31, 1);
    //    }
    //  }
    //}

  }

  @media (max-width: 1280px) {

  }
  @media (max-width: 1170px) {
    .product-specification-head {
      .product-specification-ul {
        .list-group-item {
          a {
            font-size: 16px;
            padding: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .product-specification-head {
      .product-specification-ul {
        .list-group-item {
          a {
            font-size: 14px;
            padding: 20px 15px;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .product-specification-head {
      .product-specification-ul {
        .list-group-item {
          a {
            font-size: 13px;
            padding: 20px 10px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding-top: 80px;
    //padding-bottom: 80px;
    .product-specification-head {
      .product-specification-ul {
        display: none;

        &__mobile {
          display: block;

          button {
            width: 100%;
            font-family: ${button};
            font-size: 20px;
            line-height: 26px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 30px;
            border-radius: 23px;
            background-color: #180E81;
            border: 0;
            position: relative;

            &:active {
              background-color: #180E81;
              border: 0;
            }


            //&:before {
            //  position: absolute;
            //  right: 30px;
            //  top: 18px;
            //  content: '';
            //  border: solid #ffffff;
            //  border-width: 0 2px 2px 0;
            //  display: inline-block;
            //  padding: 3px;
            //  transform: rotate(-135deg);
            //  margin-top: -5px;
            //}
            //
            //&:after {
            //  position: absolute;
            //  right: 30px;
            //  top: 25px;
            //  content: '';
            //  border: solid #ffffff;
            //  border-width: 0 2px 2px 0;
            //  padding: 3px;
            //  transform: rotate(45deg);
            //  margin-top: -5px;
            //  display: none;
            //}
            &:after {
              display: none;
            }

            &:before {
              content: '';
              position: absolute;
              top: 40%;
              right: 25px;
              height: 21px;
              width: 17px;
              background-repeat: no-repeat;
              background-image: url('/images/static/dropdown.svg');
            }
          }

          &-menu {
            width: 100%;
          }

          .dropdown-item {
            font-family: ${button};
            &.active {
              background-color: #180E81;

              a {
                color: #ffffff;
              }
            }

            &:active {
              background-color: transparent;
            }

            a {
              width: 100%;
              display: block;
            }
          }

          .dropdown-menu {
            width: 100%;
          }
        }
      }
    }

    .product-specification-body {
      padding-top: 50px;
      width: 100%;
      min-height: 250px;

      &__desc {
        padding-bottom: 0;
      }

      table {
        min-width: 800px;
        margin-bottom: 0;
      }
    }
  }

  .dropdown-item {
    padding: 10px 1.5rem !important;

  }

  .dropdown-menu {
    padding: 0.2rem 0 !important;
  }

`;

export default ListsV1;
