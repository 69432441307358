import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = true
        }
    },
    extraReducers: {
        ['fetchData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAboutpackaging/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAboutReplica/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCareer/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCareerDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchContact/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchEndMarketPackaging/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchEndMarketReplica/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHome/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchInvestorData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchInvestorDetails/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchKeyPersonnelPackaging/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchKeyPersonnelReplica/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchLayerList/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchLayerDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchManagementPackaging/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchManagementReplica/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaList/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProductsPackaging/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProductsReplica/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchTechnologyPackaging/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchTechnologyReplica/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchWorkingProcessPackaging/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchWorkingProcessReplica/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchSustainabilityDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
    }
})


export default globalSlice.reducer
export const {loaded, loading} = globalSlice.actions
