import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from 'react-bootstrap';
import reactHtmlParser from "react-html-parser";
import {button} from "../styles/globalStyleVars";

const DirectorMessage = ({title, list, marginTop, marginBottom,data}) => {

    return (
        <StyledTextWithImage className={`directors_message director_message_section`} marginTop={marginTop} marginBottom={marginBottom}>
            <div className='text_list'>
                <div className="plain plain_text">
                    <h4>{reactHtmlParser(title ? title : 'We are complaint with following audit:')}</h4>
                    <ul>
                        {
                            list ?
                                reactHtmlParser(list) :
                                <>
                                {data?.length > 0 && data?.map((i, index) => (
                                    <>
                                    <li key={index}>{reactHtmlParser(i?.text)}</li>
                                        </>
                                    ))}
                                </>
                        }

                    </ul>
                </div>
            </div>
        </StyledTextWithImage>
    );
};


const StyledTextWithImage = styled.div`
  .text_list {
    margin-top: ${props => props.marginTop ? props.marginTop : '60px'};
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }

  .plain_text {
    h4{
      color: #262630;
      margin-bottom: 40px;
    }

    ul {
      padding-left: 0;
      counter-reset: count;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: ${props => props.marginBottom ? props.marginBottom : ''};

      li {

        position: relative;
        counter-increment: count;
        padding: 0 0px 20px 40px;
        border-bottom: 1px solid rgba(121, 121, 151, 0.3);
        margin: 0 0 10px;
        flex: 0 0 calc(50% - 35px);
        color: rgb(38 38 48);
        font-family: ${button};
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        &:before {
          content: counter(count, upper-alpha);
          position: absolute;
          height: 25px;
          width: 25px;
          padding: 0;
          background: #180E81;
          border-radius: 50%;
          color: white;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
        }


      }

      @media (max-width: 767px) {
        margin: 0 0 40px;

        li {
          flex: 0 0 100%;

          &:last-child {
            margin-bottom: 0;
          }
        }

      }
    }
  }
`;


export default DirectorMessage;
