import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import NavigationBox from "../../components/end-market/NavigationBox";
import Overview from "../../components/end-market/Overview";
import Packaging from "../../components/end-market/Packaging";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import endmarketReplicaReducer, {fetchEndMarketReplica} from "../../api/redux/EndMarketReplica";
import endmarketPackagingReducer, {fetchEndMarketPackaging} from "../../api/redux/EndMarketPackaging";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {gsap} from "gsap";

const MyComponent = () => {

    //scroll to section
    const location = useLocation()


    const url = location.pathname.split('/')[1];


    const dispatch = useDispatch()

    let getPost;

    useEffect(() => {
        let api_url;

        if (url === 'kalyar-replica') {
            api_url = apiEndPoints.END_MARKET_REPLICA;
            dispatch(fetchEndMarketReplica([api_url]));
        } else {
            api_url = apiEndPoints.END_MARKET_PACKAGING;
            dispatch(fetchEndMarketPackaging([api_url]));
        }
    }, [dispatch, url]);

    getPost = useSelector(state => {
        if (url === 'kalyar-replica') {
            return state.endmarketReplicaReducer;
        } else {
            return state.endmarketPackagingReducer;
        }
    });

    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let banner = data?.innerBanner;
    let packaging = data?.expertise_packaging?.expertise_packaging_all;
    let overview = data?.overview;





    // useEffect(() => {
    //     window.scroll(0, 0)
    // }, [])
    // useEffect(() => {
    //     if (location.hash) {
    //         const targetElement = document.querySelector(location.hash);
    //
    //         if (targetElement) {
    //             const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY;
    //
    //             window.scrollTo({
    //                 top: offsetTop,
    //                 behavior: "smooth",
    //             });
    //         }
    //     }
    // }, [location, packaging]);



    //scroll nav behavior

    // Update the scrollTrigger according to the current scroll direction
    useEffect(() => {
        const tl = gsap.timeline();

        if(window.innerWidth > 767){
            let ctx = gsap.context(() => {
                // Pinning at top: 0 when scrolling down
                tl.to('.scroll-wrapper', {
                    scrollTrigger: {
                        id: '#scroll-nav',
                        trigger: '.scroll_to',
                        start: 'top top+=0',
                        endTrigger: '.scroll-wrapper',
                        end: 'bottom-=250px top',
                        pin: true,
                        pinSpacing: false,
                        onUpdate: self => {
                            // Check if scrolling up (direction -1)
                            if (self.direction === -1) {
                                // Update the top value to 120 when scrolling up
                                gsap.to('.scroll_to', { marginTop: 0, duration: 1 });

                            }

                            if  (self.direction === 1 || window.scrollY < 500){

                                // Reset the top value to 0 when scrolling down or not scrolling
                                gsap.to('.scroll_to', { marginTop: -2 , duration: 1});
                            }
                        }
                    },
                });
            });
            return () => ctx.kill(); // <-- cleanup!

        }

    }, [getPost]); // No need to include scrollUp and scrollDown in the dependency array


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${banner?.title ? banner?.title + ' | ' + banner?.subtitle : ''}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={banner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>

                    <InnerBanner
                        title={banner?.title}
                        img={banner?.image}
                        subtitle={banner?.subtitle}
                    />
                    <NavigationBox data={packaging}/>
                    <Overview data={overview}/>
                    <Packaging data={packaging}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
