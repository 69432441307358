import styled from "styled-components";

export const VideoBanner = ({
                                src,
                                position,
                                objectFit,
                                height,
                                width,
                                banner,
                                alt,
                                left,
                                margin,
                                right,
                                top,
                                bottom,
                                parallax,
                                className,
                            }) => {
    // Check if the source is a YouTube URL
    const isYouTube = src && src.includes("youtube.com");

    // Convert to YouTube embed URL if it's a YouTube link
    const getYouTubeEmbedUrl = (url) => {
        const videoId = url.split("v=")[1];
        const ampersandPosition = videoId ? videoId.indexOf("&") : -1;
        return `https://www.youtube.com/embed/${ampersandPosition !== -1 ? videoId.substring(0, ampersandPosition) : videoId}?autoplay=1&mute=1&loop=1&playlist=${videoId}`;
    };

    return (
        <StyledContainer
            className={`global-image ${parallax ? 'parallax-bg' : ''}`}
            position={position}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            height={height}
            width={width}
            margin={margin}
            objectFit={objectFit}
            style={parallax ? {
                backgroundImage: `url(${src})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            } : {}}
        >
            {!parallax && isYouTube && (
                <iframe
                    data-speed={0.2}
                    className={className || ''}
                    src={getYouTubeEmbedUrl(src)}
                    title={alt || 'YouTube video'}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        objectFit: objectFit || 'contain',
                    }}
                />
            )}
            {!parallax && !isYouTube && (
                <video
                    data-speed={0.2}
                    className={className || ''}
                    src={src || 'videos/dynamic/video.mp4'}
                    alt={alt || ''}
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        objectFit: objectFit || 'cover',
                    }}
                />
            )}
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
  position: ${props => props.position || 'absolute'};
  height: ${props => props.height || '100%'};
  width: ${props => props.width || '100%'};
  top: ${props => props.top || 0};
  left: ${props => props.left || 0};
  bottom: ${props => props.bottom || 0};
  right: ${props => props.right || 0};
  margin: ${props => props.margin || 0};
  overflow: hidden;
  background-repeat: no-repeat !important;

  video {
    width: 100%;
    height: 100%;
    object-fit: ${props => props.objectFit || 'cover'};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none !important;
  }
  iframe{
    transform: none !important;
  }
`;

export default VideoBanner;
