import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'mission-vision pt-120 pb-120'}>
            <Img src={data?.background_image}/>
            <Container>
                <Row className={'mission-vision__text'}>
                    <Col md={6} className={''}>
                        <div className={'box__content'}>
                            <h3 className={'split-up'}>{reactHtmlParser(data?.first_company_title)}</h3>
                            <p>{reactHtmlParser(data?.first_company_subtitle)}</p>
                            <Button src={'/kalyar-replica/about'} text={'Explore more'} color={'#F9F9F9'} borderColor={'#F9F9F9'}  hoverBackground={'#FFFFFF'} hoverColor={'#000000'}/>
                        </div>
                    </Col>
                    <Col md={6} className={''}>
                        <div className={'box__content'}>
                            <h3 className={'split-up'}>{reactHtmlParser(data?.second_company_title)}</h3>
                            <p>{reactHtmlParser(data?.second_company_subtitle)}</p>
                            <Button src={'/kalyar-packaging/about'} text={'Explore more'} color={'#F9F9F9'} borderColor={'#F9F9F9'}  hoverBackground={'#FFFFFF'} hoverColor={'#000000'}/>
                        </div>
                    </Col>
                </Row>
            </Container>



        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;

  img{
    height: 100%;
    width: 100%;
    &:before{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgb(0 0 0 / 31%);
    }
  }
  .container{
    overflow: hidden;
  }
  .mission-vision__text {
    .top{
      margin-bottom: 40px;
      h2{
        color: #ffffff;
      }
    }
    .box{
      &__content{
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(30px);
        filter: brightness(1.15);
        padding: 60px 70px;
        height: 100%;
        //border: 1px solid #636466;
        h3{
          color: #FFFFFF;
        }
        p{
          color: #F9F9F9;
          margin-top: 30px;
        }
        .dc-btn{
          margin-top: 30px;
        }
      }
    }
  }




  @media (max-width: 769px) {
    padding-top: 120px;
    .mission-vision__text{
      .col-md-6{
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
        .box__content{
          padding: 60px 15px;
        }
      }
    }

  }

  @media (max-width: 650px) {
    .title {
      margin-bottom: 30px;
    }

    .text-center {
      text-align: left !important;
    }

  }
`;



export default MyComponent;
