import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Personels from "../../components/personels/Personels";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchManagementReplica} from "../../api/redux/ManagementReplica";
import {fetchManagementPackaging} from "../../api/redux/ManagementPackaging";
import keypersonalPackagingReducer, {fetchKeyPersonnelPackaging} from "../../api/redux/KeyPersonnelPackaging";
import keypersonalReplicaReducer, {fetchKeyPersonnelReplica} from "../../api/redux/KeyPersonnelReplica";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";


const MyComponent = () => {

    const location = useLocation()


    const dispatch = useDispatch()
    const url = location.pathname.split('/')[1];
    let getPost;

    useEffect(() => {
        let api_url;

        if (url === 'kalyar-replica') {
            api_url = apiEndPoints.KEY_PERSONNEL_REPLICA;
            dispatch(fetchKeyPersonnelReplica([api_url]));
        } else {
            api_url = apiEndPoints.KEY_PERSONNEL_PACKAGING;
            dispatch(fetchKeyPersonnelPackaging([api_url]));
        }
    }, [dispatch, url]);

    getPost = useSelector(state => {
        if (url === 'kalyar-replica') {
            return state.keypersonalReplicaReducer;
        } else {
            return state.keypersonalPackagingReducer;
        }
    });

    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let innerBanner = data?.innerBanner;
    let management_boards_key_personnel = data?.management_boards_key_personnel;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title + ' | ' + data?.innerBanner?.subtitle : ''}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={innerBanner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>

                    <InnerBanner
                        img={innerBanner?.image}
                        title={innerBanner?.title}
                        subtitle={innerBanner?.subtitle}
                    />
                    <Personels data={management_boards_key_personnel}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
