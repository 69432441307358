import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
}

export const fetchKeyPersonnelReplica = createAsyncThunk("fetchKeyPersonnelReplica", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'keypersonalReplica',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchKeyPersonnelReplica.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchKeyPersonnelReplica.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchKeyPersonnelReplica.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
    }
})


export default postSlice.reducer
