import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import SubTitle from "../SubTitle";
import List from "../List";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <SubTitle text={data?.subtitle} color={'rgb(24 21 58 / 80%)'} borderColor={'rgb(24 21 58 / 80%)'}/>
                <List
                    title={reactHtmlParser(data?.title)}
                    data={data?.quality_assurance_all}
                />
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #F9F9F9;
`;

export default MyComponent;
