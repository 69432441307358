import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
}

export const fetchEndMarketReplica = createAsyncThunk("fetchEndMarketReplica", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'endmarketReplica',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchEndMarketReplica.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchEndMarketReplica.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchEndMarketReplica.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
    }
})


export default postSlice.reducer
