import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "./Img";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Title from "./Title";
import ReactHtmlParser from "react-html-parser";
import {BsX} from "react-icons/bs";
import reactHtmlParser from "react-html-parser";
import {button} from "../styles/globalStyleVars";

const PopupV3 = ({
                   show,
                   handleClose,
                   no_img,
                   item,
                   title,
                   description,
                   data,
                   subtitle,
                     designation,
                   img
               }) => {

    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])

    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="custom_modal "
            >
                <div onClick={handleClose} className="modal-close hover">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="11.414" viewBox="0 0 11.414 11.414">
                        <g id="Group_15651" data-name="Group 15651" transform="translate(-13907.793 -19.793)">
                            <line id="Line_3833" data-name="Line 3833" x2="10" y2="10" transform="translate(13908.5 20.5)" fill="none" stroke="#4f616b" stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_3834" data-name="Line 3834" x1="10" y2="10" transform="translate(13908.5 20.5)" fill="none" stroke="#4f616b" stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </svg>
                </div>
                <Container>
                    <Modal.Body>
                        <Row>
                            <Col md={7}>
                                <div className="modal-data d-flex">
                                    <h3>{ReactHtmlParser(title)}</h3>
                                    <p className="deg">{ReactHtmlParser(designation)}</p>
                                    <Col className='modal-data__content'>
                                        <p>{reactHtmlParser(description)}</p>
                                    </Col>
                                </div>
                            </Col>
                            <Col md={5}>
                                <div className="bod_img">
                                    <Img src={img}/>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Container>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }
  h3{
    color: #180E81;
  }
  

`;


export default PopupV3;
