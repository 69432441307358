import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";


// page imports
import About from './pages/about';
import Components from './pages/components';
import Home from './pages/home';
import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import PageTransition from "./components/animations/PageTransition";
import Menu from "./components/Menu";
import ManagementBoard from "./pages/management-board";
import Personels from "./pages/key-personel";
import WorkingProcess from "./pages/working-process";
import EndMarkets from "./pages/end-markets";
import Products from "./pages/products";
import ProductsDetails from "./pages/products/single";
import LayerDetails from "./pages/layer/single";
import Footer from "./components/Footer";
import Technology from "./pages/technology";
import Investor from "./pages/Investor";
import InvestorDetails from "./pages/Investor/single";

import MediaCenterListing from "./pages/media";
import MediaDetailsIndex from "./pages/media/single";
import ContactIndex from "./pages/contact-us";
import SustainabilityDetails from "./pages/sustainability/single";
import Career from "./pages/career";
import CareerDetails from "./pages/career/single";
import {useSelector} from "react-redux";
import {AnimatePresence} from "framer-motion";
import {SectionReveal} from "./components/animations/SectionReveal";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Loading} from "./components/Loading";



const WithDelay = ({ component: Component, delay, ...rest }) => {
    const history = useHistory();
    const store = useSelector(store=>store)
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            // Your delayed action here


            // Continue with the route transition
            history.push(rest.path);
        }, delay);

        // Clear the timeout when the component unmounts or the route changes
        return () => clearTimeout(timeoutId);
    }, [delay, history, rest.path]);

    return <Component {...rest} />;
};

function App() {
    const store = useSelector(store=>store)
    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    const getPost = useSelector((state) => state)
    const delay = 2000;
    const [updatedmenu, setUpdatedmenu] = useState('');

    useEffect(() => {
        // Check if the pathname is equal to 'media-center'
        if (location.pathname.startsWith(`/media-center/`) || location.pathname.startsWith(`/career/`)) {
            // If true, set prop to 'active'
            setUpdatedmenu('updated-menu');
        } else{
            setUpdatedmenu('')
        }
    }, [location.pathname]);



    // smooth scroll init
    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: window.innerWidth > 767 ? 2 : '', // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: window.innerWidth > 767 ? true : false, // looks for data-speed and data-lag attributes on elements
            // speed: 2
        });
        return () => {
            smoother.kill();
        };
    }, [location.pathname, getPost]);

    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page')
        } else {
            document.body.classList.remove('in-home-page')
        }

        window.scroll(0, 0)
    }, [location.pathname])


    //canonical link generate
    useEffect(() => {
        // Get the current URL from the router's full path
        const currentUrl = window.location.origin + location.pathname + location.search;

        // Set the canonical link dynamically
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = currentUrl;
        document.head.appendChild(link);

        // Clean up the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, [location.pathname, location.search]);

    // animation functions
    ImageReveal()
    Parallax()
    ParallaxImg()
    SplitUp()
    SectionReveal()
    // page transition
    PageTransition()

    return (
        <>
            {store?.careerReducer?.loading && <Loading/>}
            <GlobalStyle/>
            <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
            <Menu updatedMenu={updatedmenu}/>
            {/*page transition*/}
            <div className="page-transition">
                <img className={'logo'} src="/images/static/logo.svg" alt=""/>
            </div>
            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">

                        <Switch location={location} key={'location.pathname'}>
                            <Route
                                exact
                                path="/"
                                render={(props) => <WithDelay {...props} component={Home} delay={delay} />}
                            />
                            <Route exact path="/components" component={Components}/>
                            <Route exact path="/kalyar-replica/about" component={About}/>
                            <Route exact path="/kalyar-packaging/about" component={About}/>
                            <Route exact path="/kalyar-replica/management-board" component={ManagementBoard}/>
                            <Route exact path="/kalyar-packaging/management-board" component={ManagementBoard}/>
                            <Route exact path="/kalyar-replica/key-personels" component={Personels}/>
                            <Route exact path="/kalyar-packaging/key-personels" component={Personels}/>
                            <Route exact path="/kalyar-packaging/working-process" component={WorkingProcess}/>
                            <Route exact path="/kalyar-replica/working-process" component={WorkingProcess}/>
                            <Route exact path="/kalyar-packaging/end-market" component={EndMarkets}/>
                            <Route exact path="/kalyar-replica/end-market" component={EndMarkets}/>
                            <Route exact path="/kalyar-replica/technology" component={Technology}/>
                            <Route exact path="/kalyar-packaging/technology" component={Technology}/>
                            <Route exact path={`/kalyar-packaging/product`} component={Products}/>
                            <Route exact path={`/kalyar-replica/product`} component={Products}/>
                            <Route exact path="/investor" component={Investor}/>
                            <Route exact path={`/investor/:slug`} component={InvestorDetails}/>
                            <Route exact path={`/media-center`} component={MediaCenterListing}/>
                            <Route exact path={`/media-center/:slug`} component={MediaDetailsIndex}/>
                            <Route exact path={`/contact-us`} component={ContactIndex}/>
                            <Route exact path={`/product/:slug`} component={ProductsDetails}/>
                            <Route exact path={`/kalyar-replica/layer/:slug`} component={LayerDetails}/>
                            <Route exact path={`/kalyar-packaging/layer/:slug`} component={LayerDetails}/>
                            <Route exact path={`/layer/:slug`} component={LayerDetails}/>
                            <Route exact path={`/sustainability/:slug`} component={SustainabilityDetails}/>
                            <Route exact path={`/career`} component={Career}/>
                            <Route exact path={`/career/:slug`} component={CareerDetails}/>
                            <Route component={Error}/>
                        </Switch>
                    <Footer/>
                </div>
            </div>
        </>


    );
}

export default App;
