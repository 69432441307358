import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const SplitUp = () => {
    const location = useLocation();
    const getPost = useSelector((state) => state);
    gsap.registerPlugin(SplitText, ScrollTrigger);

    useEffect(() => {
        gsap.utils.toArray(".split-up").forEach((item, i) => {
            // Store original HTML content and letter spacing
            const originalHTML = item.innerHTML;
            const originalLetterSpacing = window.getComputedStyle(item).letterSpacing;

            const parentSplit = new SplitText(item, {
                linesClass: "split-parent",
            });

          new SplitText(item, {
                type: "lines, chars",
                linesClass: "split-child",
            });

             gsap.from(parentSplit.lines, {
                duration: 1,
                yPercent: 100,
                // stagger: 0.1,
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    once: true
                },
            });
        });
    }, [location.pathname, getPost]);

    return null; // Assuming you are not rendering anything in this component
};
