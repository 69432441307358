import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {button} from "../../styles/globalStyleVars";

const MyComponent = ({title, desc, summary, requirements, schedule, formTitle, formDesc, responsibilities}) => {

    return (
        <StyledComponent className={'pt-120 reveal-up pb-150'}>
            <Container>
                <Row>
                    <Col md={10}>
                        <h3>
                            {title}
                        </h3>
                        <p>
                            {desc}
                        </p>
                        <div className={'desc'}>
                            <h6>{summary?.title}</h6>
                            <ul>
                                {
                                    summary?.list?.map((item, index) => {
                                        return (
                                            <li key={index} >{item?.item}</li>
                                        )
                                    })
                                }

                            </ul>
                        </div>
                        <div className={'desc'}>
                            <h6>{requirements?.title}</h6>
                            <ul>
                                {
                                    requirements?.list?.map((item, index) => {
                                        return (
                                            <li key={index} >{item?.item}</li>
                                        )
                                    })
                                }

                            </ul>
                        </div>
                        <div className={'desc'}>
                            <h6>{responsibilities?.title}</h6>
                            <ul>
                                {
                                    responsibilities?.list?.map((item, index) => {
                                        return (
                                            <li key={index} >{item?.item}</li>
                                        )
                                    })
                                }

                            </ul>
                        </div>
                        <div className={'desc'}>
                            <h6>{schedule?.title}</h6>
                            <ul>
                                {
                                    schedule?.list?.map((item, index) => {
                                        return (
                                            <li key={index} >{item?.item}</li>
                                        )
                                    })
                                }

                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #e9e9e9;
  h3{
    color: #180E81;
    border-bottom: 1px solid #180E81;
    padding-bottom: 20px;
    margin-bottom: 40px;
  }
  p{
    font-family: ${button};
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgb(38 38 48 / 70%);
  }
  
  .desc{
    margin-top: 40px;
    h6{
      font-family: ${button};
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: #262630;
    }
    ul{
      li{
        font-family: ${button};
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: rgb(38 38 48 / 70%);
        margin-bottom: 10px;
        padding-left: 20px;
        position: relative;
        &:after{
          position: absolute;
          content: '';
          left: 0;
          top: 6px;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: #180E81;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  @media(max-width: 767px){
    padding-top: 120px!important;
  }
`;

export default MyComponent;
