import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import SubTitle from "../SubTitle";
import List from "../List";
import {button} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data, issues}) => {

    const Listdata = data?.why_choose_us;

    return (
        <StyledComponent className={'pb-150 pt-150'}>
            <Container>
                <SubTitle text={data?.subtitle} color={'rgb(24 21 58 / 80%)'} borderColor={'rgb(24 21 58 / 80%)'}/>
                <List data={Listdata}
                      title={reactHtmlParser(data?.title)}
                      marginBottom={'0 0 80px'} marginTop={'60px'}
                />
                <h4>{reactHtmlParser(issues?.title)}</h4>
                <Row>
                    {issues?.all_issues?.length > 0 && issues?.all_issues?.map((i, index) => (
                        <Col md={3} sm={6} className={'issues'}>
                            <div className={'issues__single'}>
                                <div className={'issues__single__box'}>
                                    <img src={i?.svg}/>
                                    <p>{reactHtmlParser(i?.text)}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  h4 {
    color: #262630;
    margin-bottom: 40px;
  }

  .issues {
    &__single {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__box {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 27px;

        p {
          margin-top: 20px;
          color: #262630;
          font-family: ${button};
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
        }
      }
    }

    @media (max-width: 767px) {
      width: 50%;
    }
  }
`;

export default MyComponent;
