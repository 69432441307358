import {createGlobalStyle, css} from 'styled-components';
import {hover, text, title, book, button} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }

  html {
    scrollbar-color: ${hover} #FEF8F4;
    scrollbar-width: thin;
  }


  @font-face {
    font-family: 'Blacker Pro Display';
    src: url('/fonts/blacker_pro_display-regular-webfont.woff') format('woff2'),
    url('/fonts/blacker_pro_display-regular-webfont.woff2') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Light.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Medium.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Suisse Intl';
    src: url('/fonts/SuisseIntl-Regular.woff2') format('woff2'),
    url('/fonts/SuisseIntl-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }


  body {
    font-family: 'Blacker Pro Display', 'Suisse Intl', Arial, Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;


    /* Hide scrollbar for Chrome, Safari and Opera */
    //
    //&::-webkit-scrollbar {
    //  display: none;
    //}
    ///* Hide scrollbar for IE, Edge and Firefox */
    //-ms-overflow-style: none; /* IE and Edge */
    //scrollbar-width: none; /* Firefox */
  }

  a {
    transition: color .3s ease;
    text-decoration: none;
    font-family: ${button};

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p {
    font-family: ${button};
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    margin: 0;
    font-kerning: none;
  }

  h1 {
    font-family: ${title};
    font-size: 64px;
    line-height: 80px;
    font-weight: 400;
    margin: 0;
    // Mobile
    @media (max-width: 767px) {
      font-size: 48px;
      line-height: 60px;
    }
  }

  h2 {
    font-family: ${title};
    font-size: 40px;
    line-height: 52px;
    font-weight: 400;
    margin: 0;
    font-kerning: none;
    // Mobile
    @media (max-width: 767px) {
      font-size: 34px;
      line-height: 44px;
    }
  }

  h3 {
    font-family: ${title};
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    margin: 0;
    font-kerning: none;
    // Mobile
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  h4 {
    font-family: ${title};
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    margin: 0;
    font-kerning: none;
  }

  h5 {
    font-family: ${button};
    font-size: 16px;
    line-height: 24px;
    font-weight: ${book};
    margin: 0;
    font-kerning: none;
  }

  .title {
    font-family: ${title};
    font-size: 16px;
    line-height: 24px;
    font-weight: ${book};
    font-kerning: none;
  }

  .paragraph {
    font-family: ${title};
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    font-kerning: none;
  }

  .button {
    font-family: ${title};
    font-size: 14px;
    line-height: 20px;
    font-kerning: none;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-150 {
    padding-top: 150px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-150 {
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }

  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-120 {
    padding-bottom: 120px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      //border-radius: 25px;
      border-radius: 50%;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .main-menu, .scroll-down .main-menu-mobile__bar  {
    transform: translate3d(0, -200%, 0)!important;
    .menu-container{
      background: transparent;
      transition: background 0.5s ease;
    }
  }

  .scroll-up .main-menu, .scroll-up .main-menu-mobile {

    .menu-container{
      background: #0000007d;
      transition: background 0.5s ease;
    }

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  // .page-loader {
  //   position: fixed!important;
  //   background-color: ${hover};
  //   width: 100vw;
  //   height: 0;
  //   z-index: 999999999;
  //   overflow: hidden;
  //   //opacity: 0;
  //   //height: 100vh;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  //.page-loader {
  //  position: fixed;
  //  background-color: rgb(36, 24, 67);
  //  width: 100vw;
  //  z-index: 999999999;
  //  overflow: hidden;
  //  //opacity: 0;
  //  //height: 100vh;
  //  top: 0;
  //  bottom: 0;
  //  left: 0;
  //  right: 0;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  //left: 0; //width: 0;
  //  //transition: all 2.6s ease;
  //  //.anim-logo {
  //  //  width: 130px;
  //  //  overflow: hidden;
  //  //  height: fit-content;
  //  //  position: absolute;
  //  //  left: 0;
  //  //  right: 0;
  //  //  top: 0;
  //  //  bottom: 0;
  //  //  opacity: 0;
  //  //  margin: auto;
  //  //  z-index: 2;
  //  //
  //  //  img {
  //  //    height: 55px;
  //  //  }
  //  //}
  //
  //  //.hide-logo {
  //  //  background-color: #010A1A;
  //  //  width: 50%;
  //  //  height: 100vh;
  //  //  top: 0;
  //  //  left: 0;
  //  //  right: 0;
  //  //  margin: auto;
  //  //  position: absolute;
  //  //  z-index: 999;
  //  //}
  //
  //  .pre-loader__img {
  //    //position: fixed;
  //    height: 100px;
  //    width: 100px;
  //    top: 0;
  //    bottom: 0;
  //    left: 0;
  //    right: 0;
  //    background-color: rgb(36, 24, 67);
  //    z-index: 99999999;
  //
  //    //flex: 1;
  //    margin: auto;
  //    opacity: 0;
  //
  //    img {
  //      display: block;
  //    }
  //
  //    .top {
  //      height: 8px;
  //    }
  //
  //    .bottom {
  //      height: 30px;
  //      margin-top: 5px;
  //      animation: flip 1s linear infinite;
  //    }
  //
  //    @keyframes flip {
  //      0% {
  //        transform: rotateY(0deg)
  //      }
  //      100% {
  //        transform: rotateY(180deg)
  //      }
  //    }
  //  }
  //
  //}

  //------------------------animation
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  //page transition
  .page-transition {
    position: fixed;
    height: 0;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #18153A;
    z-index: 999999999;
    display: none;
    opacity: 1;
    //display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      height: 50px;
      opacity: 0;
    }
  }


  //modal for bod version 3
  .custom_modal {
    z-index: 99999!important;
    overflow-y: hidden!important;
    @media (max-width: 767px) {
      .modal-data{
        margin-top: 80px !important;
        padding-right: 0 !important;
        margin-bottom: 60px;
      }
      .modal-data__content {
        padding: 0;
        height: 100% !important;
        overflow-x: inherit;
        overflow-y: hidden;

        h3 {
          margin-bottom: 40px;
        }
      }

    }

    .modal-content {
      border-radius: 0 !important;
    }

    .modal-close {
      position: absolute;
      top: 20px;
      right: 30px;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      border: 1px solid rgb(196, 196, 196);
      border-radius: 50%;
      cursor: pointer;
      z-index: 9;

      &:hover{
        svg{
          position: relative;
          z-index: 1;
          g{
            line{
              stroke: #ffffff !important;
            }
          }
        }
      }
    }

    .modal-dialog {
      max-width: 100%;
      margin: 0px 0px 0px auto;
      height: 100%;
      padding: 0px;
      @media (max-width: 992px) {
        max-width: 100%;

      }
    }

    .modal-data {
      margin-top: 0 !important;
      padding-right: 100px;
      @media(max-width: 767px){
        margin-top: 80px;
      }
    }

    .bod_img {
      position: relative;
      padding-top: calc(550 / 470 * 100%);
    }

    .modal-data__content {
      padding: 0;
      height: 70vh;
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &::-moz-scrollbar {
        display: none;

      }

      -ms-overflow-style: none

    ; /* IE 11 */
      scrollbar-width: none

    ;

      h3 {
        color: #180E81;
        margin-bottom: 40px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgb(38 38 48 / 70%);
        margin-bottom: 24px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }


    .modal-data.d-flex {
      flex-direction: column;
      display: block !important;
      @media (max-width: 992px) {
        padding-left: 0px;
        margin-top: 40px;


      }

      h3 {
        font-family: ${title};
        color: #180E81;
        margin-bottom: 10px !important;

      }

      .deg {
        margin-bottom: 40px !important;

        color: rgba(79, 97, 107, 0.5) !important;
      }
    }

    .modal-body {
      position: relative;
      //padding: 0px;
      padding: 100px 0;
      height: 100vh;
      overflow: hidden;
      @media (max-width: 992px) {
        overflow: inherit;
        height: 100%;

        background: white;
      }
      @media (max-width: 992px) {
        padding: 80px 30px;
      }
      @media (max-width: 767px) {
        padding: 30px 15px;
      }
      //
      //&::-webkit-scrollbar, &::-webkit-scrollbar-track {
      //  display: none;
      //}

      .modal-data__content {
        padding: 0;
        height: 70vh;
        overflow-x: hidden;
        overflow-y: scroll;

        h4 {
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          color: #4F616B;
          margin-bottom: 10px !important;
        }

        .deg {
          margin-bottom: 40px !important;

          color: rgba(79, 97, 107, 0.5) !important;
        }

        p {
          font-size: 16px;
          font-family: Roboto;
          font-weight: 400;
          line-height: 24px;
          color: #4F616B
        }
      }


    }
  }

  //scroll-smooth shaking issue
  #smooth-content{
    will-change: transform;
  }

  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 500;
      line-height: 20px;
      color: #25292C;
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
      padding: 0;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;

    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  .css-1u9des2-indicatorSeparator{
    display: none;
  }
  .stop-scroll{
    overflow: hidden;
  }

  .video-modal {
    .modal-content, .modal-video, .modal-dialog {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

    }

    .modal-dialog {
      border: 0 !important;
      margin: 0 !important;
      max-width: unset !important;
      width: 100% !important;
      height: 100% !important;
    }

    .modal-body {
      border: none !important;
      box-shadow: none !important;
      .modal-video-movie-wrap{
        button{
          &::before,&::after{
            background: #fff;
          }
        }
      }
    }

    .modal-close {
      opacity: 0;
    }
  }

  //for shaking 
  #smooth-content {
    will-change: transform;
  }

  //video modal
  .modal-video {
    background-color: transparent;
    height: 100vh;
    z-index: 99999;

    .modal-dialog {
      height: 100vh;
      background-color: transparent;
      min-width: 100%;
      margin: 0;
    }

    .modal-body {
      height: 100vh;
    }

    .modal-content {
      background-color: transparent;

    }

    iframe {
      height: 60vh;
      width: 60vw;
      margin: auto;
      position: absolute;
      inset: 0;
    }

    .close-modal {
      position: absolute;
      top: 40px;
      right: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 99;
    }

    @media (max-width: 768px) {
      .modal-content {
        //padding: 0 20px;

        iframe {
          width: 90vw;
          height: 60vh;
        }

        .close-modal {
          top: 80px;
          right: 20px;
        }
      }
    }
    @media (max-width: 550px) {
      .modal-content iframe {
        width: 90vw;
        height: 40vh;
      }
    }
  }


`;



